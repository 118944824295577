import React, { useEffect } from 'react'
import { Card, Tag } from 'antd';
import { Typography } from 'antd';

const { Title, Paragraph, Text, Link } = Typography;

//const blockContent = `| extend EventStampName = tolower(strcat("WAWS-PROD-",extract("WAWSPROD([0-9A-Z]{3,7})([0-9]{3})",1,SourceMoniker) , "-",extract("WAWSPROD([0-9A-Z]{3,7})([0-9]{3})",2,SourceMoniker)))`;
const primaryQuerySample = `AntaresIISLogFrontEndTable 
| where Cs_host startswith "mawscanary" and TIMESTAMP > ago(1h) and Sc_status == 503 and Sc_substatus == 65 and ServerRouted == "-"
| extend CorrelationId = EventStampName`;
const secondaryQuerySample = `AntaresIISLogFrontEndTable | extend CorrelationId = EventStampName | where CorrelationId == "{CorrelationId}" and TIMESTAMP > ago(6h)`;
const IcmAutoCorrelationId_1 = `Title:{parentAlert.title}`;
const IcmAutoCorrelationId_2 = `Title:{parentAlert.title};CorrelationId:{parentAlert.CorrelationId}`
const IcmAutoCorrelationId_3 = `Title:{parentAlert.title};CorrelationIdOverride:{parentAlert.CorrelationIdOverride}`

const Faq: React.FC = () => {
  // in case anchor provided, it needs to jump to corresponding section
  useEffect(() => {
    var hash = window.location.hash;
    if (hash) {
      var element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView()
      }
    }
  }, [])

  if(!document.title.startsWith("FAQ - Azure Alerting"))
  {
      document.title = "FAQ - Azure Alerting";
  }

  return (
    <div style={{ padding: "50px" }}>
      <div style={{ display: "flex", width: "100%", border: "red, 1px solid" }} >
        <Card style={{ width: "100%" }}>
          <Typography>
            <Title level={2}>Introduction</Title>
            <Paragraph>Do you have a Kusto query, you want to run on a schedule and email you the results?
              Do you wish to add monitoring to your new feature and trigger an alert if it fails?
              Do you wish your alert action including information in graphs and tables relevant to the fired alert?
              Do you wish to add monitoring to your new feature and trigger an auto mitigation if it fails?
              Do you want a hassle free way to create new alerts that doesn’t involve depending on someone else?
            </Paragraph>
            <Paragraph>
              <Link href="./"><Text strong>AzureAlerting is your answer!</Text></Link>.
            </Paragraph>

            <Title id="overview" level={5}>Overview</Title>
            <Paragraph>
              The <Link href="./"><Text strong>AzureAlerting</Text></Link> site  allows you to view all alerts under different Microsoft services, alerts that you created, alerts you receive and alerts you subscribe.
              You can create a new alert, edit, suspend/resume an existing alert, track alert run history and alert modifications.
              The alerts are color coded based on their states, history of alert shows how often the alert ran
              and what alert actions were triggered (Is it too noisy? Why is the issue not fixed yet?).
              Actions (email, ICM incident, ADO task, etc.) and mitigations (reboot, reimage, etc.) are defined in an alert rule,
              creation of alerts will involve manual review and approval when the alerts include actions to create ICM tickets with severity 2 or lower, or mitigations to operate product deployments,
              that is to ensure it is safe to run the alerts for products online.

              Backend of AzureAlerting runs alerts per their schedule and rules, and triggers actions if their thresholds are hit. that is fully managed and no user action is needed.
            </Paragraph>

            <Title level={5}>Onboard a Microsoft service</Title>
            <Paragraph>
              AzureAlerting supports Microsoft services as ICM, before creating a first alert, it is required to onboard the service in AzureAlerting by submitting a request <Link href="./serviceonboard"><Text strong>here</Text></Link>, the onboarding
              process is one time work and can be done by any owner or representatives of the service. Users can create alerts under any onboarded service no matter who onboarded it.
            </Paragraph>
            <Paragraph>
              Main steps to onboard a service are:
              <ol>
                <li>Request to onboard a service from <Link href="./serviceonboard"><Text strong>here</Text></Link>, you will get email update when the request is processed.</li>
                <li>Click into the service under <Link href="./service"><Text strong>my onboarded service</Text></Link>, manage the service owners and alert approvers to define who will review and process alerts later created under this service.</li>
                <li>Also from the service onboard page above, Configure kusto clusters for the service, which lets later alerts to run against the kusto clusters. For this step, you need grant azure alerting viewer Permission
                  to your Kusto database by: </li>
                <pre>Public Azure:  .add database [dbname] viewers ('aadapp=c96c8405-70e9-417a-9ca2-3d8058a162e3;33e01921-4d64-4f8c-a055-5bdaffd5e33d') 'Azure Alerting Access' <br></br>
                  Mooncake: .add database [dbname] viewers ('aadapp=57082e6a-7cf4-4534-9909-8efbcfc59fb9;a55a4d5b-9241-49b1-b4ff-befa8db00269') 'Azure Alerting Access'<br></br>
                  Fairfax: .add database [dbname] viewers ('aadapp=8f66e562-88b6-4119-bc4b-633a7eea553e;cab8a31a-1906-4287-a0d8-4eef66b95f6e') 'Azure Alerting Access'</pre>
                <li>Configure ICM connector for the service, which lets later alerts to create ICM tickets. You need update your ICM connector with SAN below per
                  <a href="https://icmdocs.azurewebsites.net/developers/Connectors/ConnectorOnboarding.html" target="_blank" rel="noopener noreferrer">ICM connector setup guide</a> before updating connector Id in service onboard page.</li>
                <pre>update your ICM connector with SAN AntMonFun-icmcon.waws.monitoring.appservice.compute.ce.azure.net</pre>
                <li>Add monitors permission to perform .show command query. Please see example below</li>
                <pre>.add database wawskustotest monitors ('aadapp=c96c8405-70e9-417a-9ca2-3d8058a162e3;33e01921-4d64-4f8c-a055-5bdaffd5e33d')</pre>
              </ol>
            </Paragraph>

            <Title id="alert_state" level={5}>Alert State</Title>
            <Paragraph>
              <li><Tag color="green">Active</Tag>is the only <b>running</b> alert state. Our system is monitoring the alert result based on provided schedule.</li>
              <li><Tag color="gold" title="Pending Creation" style={{ color: 'gold' }}>Pending PR Approval</Tag>indicates the alert is pending creation in Azure Devops. Once approved, it will transit to Active state.</li>
              <li><Tag color="orange" title="Pending Update" style={{ color: 'orange' }}>Pending PR Approval</Tag>indicates the alert is pending approval for update. Once approved, it will transit to Active state.</li>
              <li><Tag color="magenta">Disabled</Tag>indicates the alert is <b>stopped</b> and will not be run on schedule.</li>
            </Paragraph>

            <Title id="alert_rule" level={5}>Alert Rule</Title>
            <Paragraph>
              <li><Tag color="cyan">Email</Tag>indicates this alert will send email once triggered.</li>
              <li><Tag color="blue">ICM</Tag>indicates this alert will create ICM once triggered.</li>
              <li><Tag color="geekblue">Work Item</Tag>indicates this alert will create a Azure Devops work item once triggered.</li>
              <li><Tag color="purple">Machine Action</Tag>indicates this alert will do corresponding machine mitigation once triggered. <b>Only applies to App Service(Web Apps) service alerts</b></li>
            </Paragraph>

            <Title id="alert_action" level={5}>Alert Action</Title>
            <Paragraph>
              <li><b>Enable/Disable:</b> Switch alert state between Active and Disabled correspondingly. This function only applies to alerts with Active or Disabled state.</li>
              <li><b>Edit:</b> Redirect to edit page of existing alert.</li>
              <li><b>Delete:</b> Delete corresponding alert.</li>
              <li><b>History:</b> Redirect to alert history page. Alert history will be displayed with graphs and rows of run history.</li>
              <li><b>ChangeLog:</b> View alert model change history in Azure Devops.</li>
              <li><b>AME Approval Link (Requires SAW):</b> View alert AME approval page in release pipelines. Needs SAW to complete approval</li>
              <li><b>PRLink:</b> View alert Pull Request in Azure Devops.</li>
              <li><b>Test Run:</b> Re-run alert in current timestamp and owner who triggers that will receive an email report.</li>
              <li><b>Test past Alert:</b> Re-run alert in an arbitrary UTC timestamp within 30 days and owner who triggers that will receive an email report.</li>
            </Paragraph>
            <Title level={5}>Create a new alert</Title>
            <Paragraph>
              To create a new Alert, simple click on “Create New Alert”, fill in the details of your alert and click submit.
              You can add relevant graphs and tables in additional queries section.
              For graphs, the non-timestamp columns are each displayed as a series on the graph.
            </Paragraph>

            <Paragraph>
              Both primary and secondary queries will be executed in a run of alert, the result of a query will be sent as a graph in email when the property "isGraph" is checked as "Yes", otherwise it is be
              displayed as table in email. If you want the alert to sent email in brief mode which only include table or graph of result but not query commands and details, you just need to set the "Is Reporting Mode"
              option to be checked in the email action of the alert.
            </Paragraph>

            <Paragraph>
              Secondary queries can be initialized with the output from prmiary query, which depend on the mapping of variable defined in secondary query and column output in primary query, for example, the
              secondary query below will get the value of CorrelationId from the result of primary query and then execute as many times as the count of rows in primary result.
            </Paragraph>
            <pre>{primaryQuerySample}</pre>
            <pre>{secondaryQuerySample}</pre>

            <Paragraph>
              Table rows in eamil report can be displayed with customized background colour, for that, just output an extra column named "rowColor" from your Kusto query,
              rows with values (0, 1, 2 or empty) for the column rowColor will be displayed with mapping background color (
              0:	Red
              1:	Yellow
              2:	Green
              Others or empty:	White )

            </Paragraph>

            <Title id="kustoCluster" level={5}>Kusto Cluster guidence</Title>
            <Paragraph>
              <p>Kusto cluster is used to establish a connection to kusto service, where alert runs given kusto query to collect information and do corresponding actions.</p>
              <li>In v1, alert has a list of string format kusto clusters whose label is a combination of cloud and cluster.</li>
              <li>In v2, alert cluster label will be presented as cloud-cluster-database and multiple database under same cloud-cluster is supported.</li>
              <li>In v2, a feature for <b>All</b> selections is introduced. If a label is All, it means alert will use all current onboarded clusters in the service section. If a lable is like Public-All-database, it means alert will use all clusters for the database in that cloud.</li>
              <li>Please use "All" with caution as there could be more databases added that won't fit for the query</li>
            </Paragraph>

            <Title level={5}>Edit an existing alert</Title>
            <Paragraph>
              To edit an existing alert, click into the target alert, and make changes in any part of the alert rule except the parent service as you will, then click submit to update.
              Please note: At this point, once the alert is created the schedule of the alert cannot be updated.
            </Paragraph>

            <Title level={5}>View alert history</Title>
            <Paragraph>
              To track the alert executions, go to the <Link href='./history'>history page</Link> and select the alert to load the execution history. Details of alert execution can be checked in the "Check Kusto" link of
              each alert execution record.
              <pre>
                Note:
                New user should join security group <Link href='https://idweb.microsoft.com/IdentityManagement/aspx/common/GlobalSearchResult.aspx?searchtype=e0c132db-08d8-4258-8bce-561687a8a51e&content=azure%20alerting%20users' target="_blank">Azure Alerting Users SG</Link> for
                having the access to view the Kusto log.
              </pre>
            </Paragraph>

            <Title level={4}>Notification</Title>
            <Paragraph>
              The alerts will create email/icm/ADO notification based of what action you have configured.
              In cases when the alert query fails due to various issues, the person who created the alert can check the alert history in the <Link href="./"><Text strong>AzureAlerting</Text></Link> site, so they can fix the alert.
            </Paragraph>

            <Title level={5} id="emailaction">Email Notification</Title>
            <Paragraph>
              Email will be sent to the specified mail accounts when the primary query in an alert runs and returns no less records than the hit count set in the alert, the email content is html based, will include the queries and kusto results for the alert, including
              both primary query and secondary queries, the result will be presented in table or graph per the setting of each query in the alert and sorted per Azure clouds.
              Reply to the alert email will directly go to the alert creator.
            </Paragraph>

            <Title level={5} id="icm_notification">ICM Notification</Title>
            <Paragraph>
              Icm incidents will be created against clouds when primary kusto results meet the Minimum Hit Count.
              To make sure Icm incident could be created successfully, two things need to be done.
            </Paragraph>
            <Paragraph>
              <ol>
                <li>You need to fill in Icm ConnectorId when your service on board.</li>
                <li>You need to fill in Severity and Icm RoutingId when you create alert.</li>
              </ol>
            </Paragraph>
            <Paragraph>
              If you want to provide more information to Icm incident, you can add below fields in you kusto query results, then these field values will be passed to Icm.
            </Paragraph>
            <Paragraph>
              <ol>
                <li><b>CorrelationId:</b> CorrelationId is required if you want the incident to correlate to another incident. Icm incidents will be created against CorrelationIds.</li>
                <li><b>MonitorId:</b> to set the monitor identifier</li>
                <li><b>SliceOverride:</b> to indicate occurring location service instance</li>
                <li><b>CorrelationIdOverride:</b> to override the CorrelationId</li>
                <li><b>TsgId:</b> to set the id of the troubleshooting guide used to solve the incident</li>
                <li><b>TitlePrefix:</b> add prefix to your alert title when fire incident</li>
                <li><b>Severity:</b> add Icm_Severity in your query so that the default severity can be overriden.</li>
                <li><b>Other Icm fields:</b> Firstly, get field name from Icm official doc, <a href='https://icmdocs.azurewebsites.net/developers/Connectors/InjectingIncidentsUsingConnectorAPI.html' target='_blank' rel="noopener noreferrer">Icm incident definition</a>. 
                You can find 'AlertSourceIncident' class definition in this doc, and set your customized column name based on the property name and its class hierarchy, connect property names with underline.
                For example: set your column name as 'Icm_RoutingId'/'Icm_OccurringLocation_DataCenter', then Alerting will overwrite incident.RoutingId/incident.OccurringLocation.DataCenter.
                Please note that the column name must start with 'Icm_', and it's case-sensitive.</li>
              </ol>
              All Icm related fields become effective only when CorrelationId is set.
            </Paragraph>
            <Title level={5} id="work_item">Ado Notification (Work Item)</Title>
            <Paragraph>
              Creating Ado notification is similar to the creation of work items in Azure Devops. You will need to fill in Assigned To, Title, Area and Iteration parameters. Ado project name will be chosen once you have selected service name
              that you onboarded. Area and Iteration paths under that project will be same as you see in Azure Devops. Once the alert running on your defined schedule gets triggered, a work item will be created on behalf of you and assigned to the
              corresponding Assigned To.
            </Paragraph>

            <Title id='machineaction' level={5}>Machine Action</Title>
            <Paragraph>
              <ol>
                <li>Requested machine action will be performed against VM(s) based on stamp and instanceName(s).<br />
                  Hence <b>Stamp</b>, <b>InstanceName</b> and <b>RequestedAction</b> are required fields of Kusto query result for machine actions. If <b>InstanceName</b> is Ip address(for worker role instances), then <b>RoleName</b> is also required. <b>RequestReason</b> will be set to alert name by default when it's not specified in query output.
                  Below is a sample valid Kusto query for machine action:
                  <pre>
                    RoleInstanceHeartbeat<br/>
                    | where Tenant == &#39;waws-prod-xxxxxxxx-xxx&#39;<br/>
                    | where PreciseTimeStamp &gt; ago(1d)<br/>                                        
                    | project InstanceName = RoleInstance, Stamp = EventPrimaryStampName, RequestedAction = &#39;reboot&#39;, TimeStamp = now(), RequestReason = &#39;AlertingV2 Auto-Mitigation&#39;<br/>                                        
                    | distinct *<br/>
                    | take 3<br/>
                  </pre>   
                </li>
                <li>For worker roles, maximum 10 VMs will be reboot/reimaged every time an alert with machine action is triggered.</li>
                <li>For ASE, the above limitation is set to 1 by default. Only 1 VM of ASE will be reboot/reimaged every time.</li>
                <li>For non-worker roles, the above limitation is also set to 1. Only 1 VM of non-worker roles will be reboot/reimaged every time.</li>
                <li>A single action(e.g: reboot/reimage/redeploy) will not be applied to same VM again within 1 hour.</li>
                <li>However for a new action with higher level/priority (i.e: reboot &lt; reimage &lt; redeploy &lt; deleteinstance ), it will be performed immediately.</li>
                <li>No action will be taken for VM(s) added into <b>Protected Machines</b> list.</li>
                <li>The supported list of actions for Cloud Service stamps is Reboot, Reimage, and Rebuild. The supported list of actions for VMSS stamps is Reboot, Reimage, ReimageAll, Redeploy, and DeleteInstances.</li>
                <li>For Hybrid stamp, OperationOnVmss and ServiceName are also required. For example:</li>
                <pre>
                    | project InstanceName = RoleInstance, Stamp = EventPrimaryStampName, ServiceName = iif(Tenant =~ EventPrimaryStampName, strcat(EventStampName, &#39;-rg&#39;), EventStampName), RequestedAction = iif(Tenant =~ EventPrimaryStampName, &#39;redeploy&#39;, &#39;rebuild&#39;), TimeStamp = now(), OperationOnVmss = iif(Tenant =~ EventPrimaryStampName, &#39;true&#39;, &#39;false&#39;)<br />
                </pre>
                <li>Machine actions should always run against EventPrimaryStampName (not EventStampName). To specify CloudService worker role instances, use the IP address of the VM, RoleName, and EventPrimaryStampName. (This method works with VMSS as well.)</li>
                <pre>                                     
                    | project InstanceName = EventIpAddress, RoleName = Role, Stamp = EventPrimaryStampName, RequestedAction = &#39;reboot&#39;, TimeStamp = now(), RequestReason = &#39;AlertingV2 Auto-Mitigation&#39;<br/>
                </pre>
              </ol>
            </Paragraph>
            <Title id="nationalclouds" level={5}>Sync to National Clouds</Title>
            <Paragraph>
              <p>When <b>Machine Action</b> is selected, user can select to sync this machine action into Mooncake and Fairfax. Be aware of following rules:</p>
              <ol>
                <li>Alert will be synced to national clouds twice a week. For urgent synchronization, please contact antmonchamp@microsoft.com</li>
                <li>Kusto query CANNOT contain any public cluster/database settings. Instead, try using a 'All' function to help resolve cluster.</li>
              </ol>
            </Paragraph>
            <Title id='genevaactions' level={5}>Geneva Actions</Title>
            <Paragraph>
              <ol>
                <li>Service owner needs to go to Service Onboard page to configure allowed Geneva extensions for a service.</li>
                <li>Use <b>'RBAC Claim'</b> form items to request granting AlertingV2 AAD app with required roles/permission.
                  Then an email with title <b>'Azure AlertingV2 - Geneva Action Runner AddRoleBasedClaims Request'</b> will be sent out with the RBAC claim approval link.
                  Please contact service tree owner approves the RBAC request.</li>
                <li>After that, users will be able to view and configure allowed Geneva operations of the approved role in Alert Create/Edit page. </li>
                <li>The Kusto query result set must covers all required parameters for the Actions you want to execute, for example,
                  <pre>
                    KustoTable<br />
                    | Kusto Filter<br />
                    | project wellknownsubscriptionid = SubscriptionId, resourceprovidernamespace = 'Microsoft.Compute', endpoint = strcat('CommonPrefix', column1, colum2)<br />
                  </pre>
                </li>
                <li>For any Antares operation requires RBAC claim higher than Antares-PlatformServiceOperator, Approver and Approver Link are automatically added. <b>Wellknownapprover</b> should be set to two user aliases, e.g: alias1;alias2. <b>Wellknownapproverlink</b> needs to be a valid icm number.</li>
              </ol>

              <Title level={5}><blockquote><Text strong>Is there some throttling built-in like with machine actions?</Text></blockquote></Title>
              <div style={{ paddingLeft: "30px" }}>
                <Paragraph>
                  We don't have throttling from our side implemented yet. Users need control this by the Kusto Query.<br />
                  From Geneva side, there is a throttling limit to 30 simultaneous operation executions.<br />
                  <a href='https://eng.ms/docs/products/geneva/actions/limitsandquotas'>Limits and Quotas | Geneva Monitoring Docs (eng. ms)</a><br />
                </Paragraph>
              </div>

              <Title level={5}><blockquote><Text strong>Can we restrict/block some high risky Geneva operations from being used?</Text></blockquote></Title>
              <div style={{ paddingLeft: "30px" }}>
                <Paragraph>
                  Yes, this can be controlled by Role/Claim first. Geneva form will not list operations without required Role/Claim assigned.<br />
                  Also, Service owners can explicitly block any specific Geneva operation(s) for auto-mitigation: <br />
                  <ul>
                    <li>In Service onboard page, add an operation to <b>Restricted Operations</b> section and press Update. </li>
                    <li>After that, the operation will not be listed in auto mitigation of Create/Edit alert form.</li>
                  </ul>
                </Paragraph>
              </div>
            </Paragraph>

            <Title level={2}>FAQ</Title>
            <Title level={5}><blockquote><Text strong>Can I create alerts against other kusto clusters?</Text></blockquote></Title>
            <div style={{ paddingLeft: "30px" }}>
              <Paragraph>
                Yes. Please run the below command on the cluster to grant azure alerting app access to your kusto cluster:
                <br></br>For global Azure kusto cluster:
                <pre>.add database [dbname] viewers ('aadapp=c96c8405-70e9-417a-9ca2-3d8058a162e3;33e01921-4d64-4f8c-a055-5bdaffd5e33d') 'Azure Alerting Access'</pre>
                For China Azure kusto cluster:
                <pre>.add database [dbname] viewers ('aadapp=57082e6a-7cf4-4534-9909-8efbcfc59fb9;a55a4d5b-9241-49b1-b4ff-befa8db00269') 'Azure Alerting Access'</pre>

                Use cluster-database format in your kusto query for system to identify your kusto table.
                <pre>Ex. cluster("clusterName").database("databaseName").[KustoTableName]</pre>
                Also add the kusto cluster in the target service when onboard that at <Link href="./serviceonboard" target="_blank" rel="noopener noreferrer"><Text strong>Service Onboard</Text></Link>.
              </Paragraph>
            </div>

            <Title level={5}><blockquote><Text strong>I don't see my ICM team in the dropdown?</Text></blockquote></Title>
            <div style={{ paddingLeft: "30px" }}>
              <Paragraph>
                We list ICM teams on the UI from ICM. If you would like to add your
                team to the list, please create that team in ICM, AzureAlerting will sync that for alerts creation with some delay.
                Then, create a <a href='https://portal.microsofticm.com/imp/v3/rules/routing?id=10060'>routing rule</a> to route alerts to your ICM team at.
                Reach out to Antares Monitoring Champs (antmonchamp@microsoft.com) if you're having difficulties with this.
              </Paragraph>
            </div>

            <Title id="kusto" level={5}><blockquote><Text strong>Kusto query guidence for my alert?</Text></blockquote></Title>
            <div style={{ paddingLeft: "30px" }}>
              <Paragraph>
                Efficient Query – run locally and make sure it doesn’t take more than a few seconds.
                Use |limit, |summarize etc.
                Make sure ICM alerts are not too noisy. Get approval before assigning Sev2 to Servicing loop.
                Use the right Cluster – User queries with union across Kusto clusters Vs generic query.
                If you need summarize across all public azure clusters, use union in  your query and run on single cluster.
                Add additional queries to ICM to make alert informative and useful.
                Add good description in alerts so recipient understands the actions to perform. Link to TSG is a good idea.
                <br></br><a href='https://kusto.azurewebsites.net/docs/kusto/query/index.html'>Go to Kusto official site for more detail</a>
              </Paragraph>
            </div>

            <Title id="update_failure" level={5}><blockquote><Text strong>Why updating alert could sometimes fail?</Text></blockquote></Title>
            <div style={{ paddingLeft: "30px" }}>
              <Paragraph>
                <p>1. Update alert immediately after previous alert model change action.</p>
                Most scenarios of failing updating alert is when user updates alert immediately after alert creation. This is due to a backend work flow happening in Azure Devops for PR merge.
                As our system relies on Azure Devops for alert approval workflow, alert model change happening during Azure Devops workflow could result in missing git ref files and thus result in failure.
              </Paragraph>
              <p>2. Update alert with bad kusto queries</p>
              When use submit the alert creation/update request, backend will validate the kusto query provided by user. Kusto query validation could fail in many ways such as missing required data columns, invalid kusto clusters, etc.
              Thus We highly recommend using <b>"Validate Query"</b> before user submits request.
            </div>

            <Title id="icm_auto_correlation" level={5}><blockquote><Text strong>How to use IcM auto correlation feature?</Text></blockquote></Title>
            <div style={{ paddingLeft: "30px" }}>
              <Paragraph>
                <p>Extend a column named 'IcmAutoCorrelationId' in your primary kusto query, the format depends on the alert you want to correlate. Replace the placeholder below according to actual value.</p>
                <p>1) If the parent alert has no 'CorrelationId' column, then set current IcmAutoCorrelationId = "{IcmAutoCorrelationId_1}".</p>
                <p>2) If the parent alert has 'CorrelationId' column, then set current IcmAutoCorrelationId = "{IcmAutoCorrelationId_2}", and you also have to set 'CorrelationId' column for current alert.</p>
                <p>3) If the parent alert has both 'CorrelationId' and 'CorrelationIdOverride' column, then set current IcmAutoCorrelationId = "{IcmAutoCorrelationId_3}", and you also have to set 'CorrelationId' column for current alert.</p>
              </Paragraph>
            </div>

            <Title level={5}><blockquote><Text strong>Other Questions?</Text></blockquote></Title>
            <div style={{ paddingLeft: "30px" }}>
              <Paragraph>
                <p>Contact: Antares Monitoring Champs antmonchamp@microsoft.com</p>
              </Paragraph>
            </div>

            <Title level={5}><blockquote><Text strong>Keep Updated</Text></blockquote></Title>
            <div style={{paddingLeft: "30px"}}>
              <Paragraph>
                <p>Please subscribe antr-aa-notification@microsoft.com for feature announcement and updated information</p>
              </Paragraph>
            </div>
          </Typography>
        </Card>
      </div>
    </div>
  )
}

export default Faq;