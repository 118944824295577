import React from 'react';
import {
    Form,
    FormInstance,
    Input,
    Tooltip
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import KustoQueryDisplay from '@components/KustoQueryDisplay';
import { IPublicClientApplication } from '@azure/msal-browser';
type KustoQueryWrapperProps = {
    accounts: any,
    serviceId: string,
    instance: IPublicClientApplication,
    form: FormInstance,
    disabled: boolean,
    command: string,
    setCommand: any,
    clusters: string[]
}
const KustoControlCommandWrapper: React.FC<KustoQueryWrapperProps> = ({ command, accounts, instance, disabled, setCommand, clusters, serviceId }) => {
    //const [kustoQuery, setKustoQuery] = useState<string>(content === undefined ? "" : content);
    return (
        <Form.Item noStyle>
            <h3>Kusto Control Commands</h3>
                <div>
                <p style={{color:"red"}}>Kusto command validation is disabled, be careful and test on Kusto Explorer or Web App before adding any control command alert that can affect live Kusto data. Also, the only control commands currently supported here are .create, .ingest, .append, .set-or-append, and .export </p>
                </div>
            <Form.Item label="Title" name={["kustoControlCommand", 'title']} required>
                <Input disabled={disabled}/>
            </Form.Item>
            {/* <Form.Item label={<div>Render as a graph <Tooltip title="Graph will be in report if choosing Yes"><QuestionCircleOutlined/></Tooltip></div>} name={["primaryKustoQuery", 'isGraphInReport']} required>
                <Radio.Group disabled={disabled}>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item> */}
            <Form.Item label={
              <div>Kusto Control Command <Tooltip title={
                <div>
                  <p><b>Kusto Control Command (Administrative commands starting with a '.' (.create function, .set-or-append, etc))</b></p>
                  <p>Get approval before creating an Administrative action.</p>
                  <p>Only the commands .create, .append, .set-or-append, .ingest, and .export are currently supported</p>
                </div>
                }><QuestionCircleOutlined/></Tooltip></div>} name={["kustoControlCommand", 'kustoCommand']} required>
                <KustoQueryDisplay readOnly={disabled} serviceId={serviceId} content={command} accounts={accounts} instance={instance} onChange={setCommand} clusters={clusters} validate={false} />
            </Form.Item>
        </Form.Item>
    )
}

export default KustoControlCommandWrapper;