import React from 'react';
import { Layout, Menu } from 'antd';
import { AlertOutlined, IssuesCloseOutlined, EditOutlined, AppstoreOutlined, ReconciliationOutlined, ToolOutlined, MenuOutlined, DeploymentUnitOutlined, BuildOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router';
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { fetchEnd2EndTestAuth } from '@utils/helpers';
import { useMsal } from '@azure/msal-react';

const { Sider } = Layout;
const { SubMenu } = Menu;


const SideMenu = withRouter(({history}) => {
  const { accounts, instance } = useMsal();
  const [auth, setAuth] = useState<any>(true);

  const end2endTestAuth = async () => {
    let data = await fetchEnd2EndTestAuth(accounts, instance);
    if (data.authorized === 200){
      setAuth(false);
    }
  }

  useEffect(() => { 
    end2endTestAuth();
  }, [auth])

  return (
    <Sider>
      <div style={{fontSize:"medium", color:"white", margin:"10px", paddingLeft:"15px"}}>AzureAlerting</div>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={['/']} defaultOpenKeys={['/historymenu']} selectedKeys={[history.location.pathname]}>
        <Menu.Item key="/dashboard"  icon={<AppstoreOutlined />}>
          <Link to='/dashboard'>Dashboard</Link>
        </Menu.Item>
        <Menu.Item key="/"  icon={<AlertOutlined />}>
          <Link to='/'>Alerts</Link>
        </Menu.Item>
        <SubMenu key='/historymenu' title='History' icon={<MenuOutlined />}>
          <Menu.Item key="/history"  icon={<ReconciliationOutlined />}>
            <Link to='/history'>Alert Execution</Link>
          </Menu.Item>
          <Menu.Item key="/machineactionhistory"  icon={<ToolOutlined />}>
            <Link to='/machineactionhistory'>Machine Action</Link>
          </Menu.Item>
          <Menu.Item key="/genevaactionhistory"  icon={<DeploymentUnitOutlined />}>
            <Link to='/genevaactionhistory'>Geneva Actions</Link>
          </Menu.Item>
          <Menu.Item key="/end2endtesthistory" hidden={auth} icon={ <BuildOutlined />}>
            <Link to='/end2endtesthistory'>End2End Test</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="/service" icon={<EditOutlined/>}>
          <Link to='/service'>Services</Link>
        </Menu.Item>
        <Menu.Item key="/faq" icon={<IssuesCloseOutlined />}>
          <Link to='/faq'>FAQ</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
})


export default SideMenu;
