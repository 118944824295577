import React, { useState } from 'react';
import { Button, Form, Input, Space, Popover, FormInstance } from 'antd';
import { CustomizedTooltip } from '../common';
type CronTimeProps = {
  active: boolean,
  readonly: boolean,
  form: FormInstance
}
const CronTime : React.FC<CronTimeProps> = ({active, readonly, form}) => {
  const [cronSchedule, setCronSchedule] = useState<any[]>([]);

  const onCheckSchedule = ()=>{
    var Cron = require('cron-converter');
    var cronInstance = new Cron();
    try {
      cronInstance.fromString(form.getFieldValue('cron'));
      var schedule = cronInstance.schedule();
      var description :any[] = [];
      for(let i = 0; i < 5; i++){
        var tmp = schedule.next().format();
        // Save this line if user would like to view sample schedule in UTC/GMT format
        // let newDate = new Date(tmp);
        description.push(tmp);
      }
      description.push("Will execute in UTC.");
      let des = description.map((de, key) => {
        return (<p key={key}>{de}</p>)
      })
      setCronSchedule(des);
    } catch (error) {
      setCronSchedule(['Incorrect cron format'])
    }
  }
  const renderCronTime = ()=> {
    if (active) {
      return (
        <Form.Item label={CustomizedTooltip("Cron(UTC)", 
        <div>
          <p>Please follow the standard cron format instead of Microsoft NCron format</p>
          <p><a href="https://crontab.guru/" target="_blank" rel="noopener noreferrer">See example</a></p>
        </div>)} required>
          <Space>
            <Form.Item name="cron" required>
            <Input disabled={readonly} placeholder='e.g. */5 * * * *'/>
            </Form.Item>
            <Form.Item>
            <Popover trigger="click" popupVisible={!readonly} content={cronSchedule} >
              <Button disabled={readonly} type="primary" onClick={onCheckSchedule}>Evaluate Schedule</Button>
            </Popover>
            </Form.Item>    
          </Space>
                   
        </Form.Item>
      )
    } else {
      return (<></>)
    }
  }
  return renderCronTime();
}

export default CronTime;