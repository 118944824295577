import React, { useState, useEffect, useCallback } from 'react';
import { Button, DatePicker, Dropdown, Input, Menu, Modal, Select, Tabs, Tag, Popconfirm, message, Tooltip } from 'antd';
import { deleteAlert, fetchAlerts, getServiceFilters, getTags, runPastAlert, switchAlert } from '@utils/helpers';
import { useMsal } from '@azure/msal-react';
import { DownOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import './customized.css';
import { AlertState, ProdState } from "@constants/Enums";
import { Link } from 'react-router-dom';
import { StandardTimeMap } from '@constants/const';
//import { GetCustomTime } from '@utils/converters';
import moment from 'moment-timezone'
import { WAWS_BASE_CONFIG } from '@constants/general';
import { QuestionCircleOutlined } from '@ant-design/icons';
import TableResize from '@components/ResizeableTable';

moment.tz.setDefault("GMT");

const { TabPane } = Tabs;

const AlertTable: React.FC = () => {
  const [pastTime, setPastTime] = useState<any>();
  const [testAlertId, setAlertId] = useState<string>("");
  const [showModal, setShowModal] = useState(false);

  const showEnableDisable = (alertState) => {
    if (alertState === AlertState.Active) {
      return "Disable"
    }
    return "Enable"
  }
  const getActionLabels = (actions) => {
    var tmpLabels: any[] = [];
    if (actions != null && actions.length > 0) {
      actions.forEach(action => {
        if(action != null)
        {
          if ('teamsReceipients' in action) {
            tmpLabels.push(<Tag key="teams" color="#7b82ea" style={{'borderColor':'#575ec8', 'fontStyle':'inherit'}}>Teams</Tag>)
          }
          if ('emailTo' in action) {
            tmpLabels.push(<Tag key="email" color="cyan">Email</Tag>);
          }
          if ('routingID' in action) {
            tmpLabels.push(<Tag key="icm" color="blue">ICM</Tag>)
          }
          if ('orgUri' in action) {
            tmpLabels.push(<Tag key="workitem" color="geekblue">Work Item</Tag>)
          }
          if ('actionType' in action) {
            tmpLabels.push(<Tag key="machineaction" color="purple">Machine Action</Tag>)
          }
          if ('genevaOperation' in action) {
            tmpLabels.push(<Tag key="genevaaction" color="orange">Geneva Action</Tag>)
          }
          if ('kustoCommand' in action) {
            tmpLabels.push(<Tag key="controlcommandaction" color="green">Control Command</Tag>)
          }
        }
      });
    }
    return tmpLabels;
  }

  const setVisible = (alertId) => {
    setAlertId(alertId);
    if (showModal) {
      return;
    }
    setShowModal(true);
  }

  const handleModalCancel = () => {
    setShowModal(false);
  }

  const handleModalOk = () => {
    sendTestPast(pastTime, testAlertId);
    setShowModal(false);
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return !(current < moment().endOf('day').subtract(0, 'day') && current > moment().subtract(30, 'day'));
  }

  const onTimeSet = (value, dateString) => {
    setPastTime(dateString);
  }

  const sendTestPast = (dateString, alertId) => {
    let ts = moment(dateString);
    runPastAlert(accounts, instance, alertId, ts.unix());
    message.success(`Test alert report will be sent to ${accounts[0].username}`);
  }

  const sendTestRun = (alertId) => {
    runPastAlert(accounts, instance, alertId);
    message.success(`Test run report will be sent to ${accounts[0].username}`);
  }

  const hasMachineAction = (record) => {
    let result = false;
    record?.actions?.forEach(action => {
      if ('actionType' in action) {
        result = true;
      }
    })
    return result;
  }

  const hasGenevaAction = (record) => {
    let result = false;
    record?.actions?.forEach(action => {
      if ('genevaOperation' in action) {
        result = true;
      }
    })
    return result;
  }

  // const renderOpenNewTab = (uri) => {
  //   window.open(uri);
  // }
  const columns = [
    {
      title: <div style={{ userSelect: "none" }}>Title</div>,
      dataIndex: 'title',
      key: 'title',
      render: (title: string, row: IAlert) => {
        let tmpUri = `/alert/${row.id}`
        return <a href={tmpUri} target="_blank" rel="noopener noreferrer">{title}</a>

        // return (
        //   <a onClick={() => { renderOpenNewTab(`/alert/${row.id}`) }}>{title}</a>
        // )
      },
      width: 190,
      onCell: () => {
        return {
          style: {
            maxWidth: 120,
          }
        }
      }
    },
    {
      title: <div style={{ userSelect: "none" }}>ServiceName</div>,
      dataIndex: 'serviceName',
      key: 'serviceName',
      align: 'center' as 'center',
      width: 150,
      onCell: () => {
        return {
          style: {
            maxWidth: 120
          }
        }
      }
    },
    {
      title: <div style={{ userSelect: "none" }}>AlertState</div>,
      key: 'alertState',
      dataIndex: 'alertState',
      align: 'center' as 'center',
      width: 150,
      render: (alertState: AlertState, record: IAlert) => {
        if (record.prodState == ProdState.PendingAMEApproval) {
          return (<Tag title="Pending AME" color={"pink"} ><a style={{ color: 'pink' }} href={record.prodPipelineLink} target="_blank" rel="noopener noreferrer">Pending AME Approval (Requires SAW)</a></Tag>)
        }
        switch (alertState) {
          case AlertState.Active: {
            return (<Tag color={"green"} >Active</Tag>)
          }
          case AlertState.PendingCreation: {
            return (<Tag title="Pending Creation" color={"gold"} ><a style={{ color: 'gold' }} href={record.prLink} target="_blank" rel="noopener noreferrer">Pending PR Approval</a></Tag>)
          }
          case AlertState.PendingDelete: {
            return (<Tag title="Pending Delete" color={"red"} ><a style={{ color: 'red' }} href={record.prLink} target="_blank" rel="noopener noreferrer">Pending PR Approval</a></Tag>)
          }
          case AlertState.PendingUpdate: {
            return (<Tag title="Pending Update" color={"orange"} ><a style={{ color: 'orange' }} href={record.prLink} target="_blank" rel="noopener noreferrer">Pending PR Approval</a></Tag>)
          }
          case AlertState.Disabled: {
            return (<Tag color={"magenta"}>Disabled</Tag>)
          }
        }
      },
      onCell: () => {
        return {
          style: {
            maxWidth: 150
          }
        }
      }
    },
    {
      title: <div style={{ userSelect: "none" }}>CreatedBy</div>,
      dataIndex: 'createdBy',
      key: 'createdBy',
      align: 'center' as 'center',
      render: (user: string) => user,
      width: 120,
      onCell: () => {
        return {
          style: {
            maxWidth: 120
          }
        }
      }
    },
    {
      title: <div style={{ userSelect: "none" }}>Schedule</div>,
      //dataIndex: 'standardTime',
      render: (record) => {
        if (record.cron !== null) {
          return record.cron
        } else {
          return StandardTimeMap[record.standardTime];
        }
      },
      key: 'schedule',
      width: 120,
      align: 'center' as 'center',
      onCell: () => {
        return {
          style: {
            maxWidth: 80
          }
        }
      }
      //key: 'standardTime',
      // align: 'center' as 'center',
      // render: (record) => {
      //   if (record.customTimes) {
      //     var customTime = record.customTimes[0];
      //     return GetCustomTime(customTime.dayOfWeek, customTime.hour, customTime.minute);
      //   } else {
      //     return StandardTimeMap[record.standardTime];
      //   }        
      // }
    },
    {
      title: <div style={{ userSelect: "none" }}>LastUpdated</div>,
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      align: 'center' as 'center',
      width: 120,
      render: (lastUpdated: string) => {
        return lastUpdated.split(".", 1)[0];
      },
      onCell: () => {
        return {
          style: {
            maxWidth: 120
          }
        }
      }
    },
    {
      title: <div style={{ userSelect: "none" }}>Rules</div>,
      dataIndex: 'actions',
      key: 'actions',
      align: 'center' as 'center',
      width: 90,
      render: (actions: any[]) => {
        return getActionLabels(actions);
      },
      onCell: () => {
        return {
          style: {
            maxWidth: 120
          },
        }
      }
    },
    {
      title: (<div style={{ userSelect: "none" }}>Action <Tooltip title={<Link style={{ color: "cyan" }} to="/faq#alert_action">Learn more</Link>}><QuestionCircleOutlined /></Tooltip></div>),
      dataIndex: 'action',
      key: 'action',
      align: 'center' as 'center',
      width: 120,
      render: (_, record) => (
        <Dropdown overlay={() => {
          let baseUri = record.prLink;
          if (baseUri === undefined || baseUri === null) {
            baseUri = WAWS_BASE_CONFIG['BaseDevOpUri']
          }
          var re = /\/pullrequest\/[0-9]+/gi;
          baseUri = baseUri.replace(re, '');
          let svcName = record.serviceName.replace(/ /g, '_');
          return (
            <Menu>
              <Menu.Item key="switch"
                onClick={() => { switchAlertState(record.id, record.alertState) }}
                disabled={!(record.alertState === AlertState.Active || record.alertState === AlertState.Disabled)}>
                {showEnableDisable(record.alertState)}</Menu.Item>
              <Menu.Item key="edit">
                {/* <a onClick={() => { renderOpenNewTab(`/alert/${record.id}`) }}>Edit</a> */}
                <a href={`/alert/${record.id}`} target="_blank" rel="noopener noreferrer">Edit</a>
              </Menu.Item>
              <Menu.Item key="delete">
                <Popconfirm
                  placement="left"
                  title="Are you sure to delete?"
                  onConfirm={() => { onDelete(record.id) }}
                >
                  Delete
                </Popconfirm>
              </Menu.Item>
              <Menu.Item key="history">
                {/* <a onClick={() => { renderOpenNewTab(`/history/?serviceId=${record.serviceId}&alertId=${record.id}`) }}>History</a> */}
                <a href={`/history/?serviceId=${record.serviceId}&alertId=${record.id}`} target="_blank" rel="noopener noreferrer">History</a>
              </Menu.Item>
              {hasMachineAction(record) === true ?
                <Menu.Item key="MachineAction">
                  {/* <a onClick={() => { renderOpenNewTab(`/machineactionhistory/${record.id}`) }}>Machine Action</a> */}
                  <a href={`/machineactionhistory/${record.id}`} target="_blank" rel="noopener noreferrer">Machine Action</a>
                </Menu.Item> : ""}
              {hasGenevaAction(record) === true ?
                <Menu.Item key="GenevaAction">
                  {/* <a onClick={() => { renderOpenNewTab(`/genevaactionhistory/${record.id}`) }}>Geneva Action</a> */}
                  <a href={`/genevaactionhistory/${record.id}`} target="_blank" rel="noopener noreferrer">Geneva Action</a>
                </Menu.Item> : ""}
              <Menu.Item key="ChangeLog">
                <a href={`${baseUri}?path=/alerts/${svcName}/${record.id}.json`} target="_blank" rel="noopener noreferrer">ChangeLog</a>
              </Menu.Item>
              <Menu.Item key="AMELink">
                <a href={record.prodPipelineLink} target="_blank" rel="noopener noreferrer">AME Link</a>
              </Menu.Item>
              <Menu.Item key="PrLink">
                <a href={record.prLink} target="_blank" rel="noopener noreferrer">PRLink</a>
              </Menu.Item>
              <Menu.Item key="run" onClick={() => sendTestRun(record.id)}>
                Test Run
              </Menu.Item>
              <Menu.Item key="test" onClick={() => setVisible(record.id)} disabled={record.alertState !== AlertState.Active}>
                Test Past Alert
              </Menu.Item>
            </Menu>
          )
        }}>
          <Button icon={<DownOutlined />}>
            action
          </Button>
        </Dropdown>
      )
    }

  ];
    const limit = 10;
    const { accounts, instance } = useMsal();
    let [page, setPage] = useState(1);
    const defaultPage = 1;
    let [total, setTotal] = useState(0);
    const [load, setLoad] = useState(false);
    let [tableData, setTableData] = useState<IAlert[]>();
    let [tabValue, setTab] = useState<string>("my");
    let [query, setQuery] = useState<string>("");
    const [tags, setTags] = useState<any>([]);
    const [tagOpts, setTagOpts] = useState<any>([]);
    const [serviceId, setServiceId] = useState<any>();
    //let [serviceName, setServiceName] = useState<any>();
    const [serviceOpts, setServiceOpts] = useState<any>();
    const [serviceLoading, setServiceLoading] = useState(false);
    const [myAlert, setMyAlert] = useState<boolean>(true);
    const [myRelated, setMyRelated] = useState<boolean>(false);
    const [alertState, setAlertState] = useState<any>([]);
    const [actionType, setActionType] = useState<string>('');
    const stateOptions = [
      {label: <Tag color={"gold"} >PendingCreation</Tag>, value: 0},
      {label: <Tag color={"green"} >Active</Tag>, value: 1},
      {label: <Tag color={"orange"} >PendingUpdate</Tag>, value: 2},
      {label: <Tag color={"red"} >PendingDelete</Tag>, value: 3},
      {label: <Tag color={"magenta"} >Disabled</Tag>, value: 5},
      {label: <Tag color={"pink"} >PendingAME</Tag>, value: 6}

    ]
    const actionTypeOptions = [
      {label: 'All', value: ''},
      {label: <Tag color="#7b82ea" style={{'borderColor':'#575ec8', 'fontStyle':'inherit'}}>Teams</Tag>, value: 'teams'},
      {label: <Tag color='cyan'>Email</Tag>, value: 'email'},
      {label: <Tag color='blue'>ICM</Tag>, value: 'icm'},
      {label: <Tag color='geekblue'>WorkItem</Tag>, value: 'ado'},
      {label: <Tag color='purple'>Machine</Tag>, value: 'machine'},
      {label: <Tag color='orange'>Geneva</Tag>, value: 'geneva'},
      {label: <Tag color='green'>Control Command</Tag>, value: 'command'}
    ]
    const myAlias = accounts[0].username.split("@",1)[0].toLowerCase();
    
    const fetchData = async()=> {
      setLoad(true);
      let tmpTags:string[] = [];
      if (myRelated) {
        tmpTags = [myAlias, ...tags];
      } else {
        tmpTags = tags
      }
      let result = await fetchAlerts(accounts, instance, page, limit, query, myAlert, serviceId, tmpTags, alertState, actionType);
      setPage(page);
      setTotal(total=10);
      setTableData(tableData=result);
      setLoad(false);
    }

  useEffect(
    () => {
      if (serviceId === undefined) {
        return
      }
      fetchData();
    }, [tags, serviceId, myAlert, myRelated, alertState, actionType]
  )

  const fetchTags = async () => {
    let tmpTagOpts = await getTags(accounts, instance);
    let tmp: any = tmpTagOpts.map(item => ({ value: item, label: item }))
    setTagOpts(tmp);
  }

  const fetchServices = async () => {
    setServiceLoading(true);
    let tmpServices = await getServiceFilters(accounts, instance);
    let tmp: any = tmpServices.map(service => ({ value: service.key, label: service.value }))
    setServiceId(tmpServices[0].key)
    //setServiceName(serviceName=tmpServices[0].value)
    setServiceOpts(tmp);
    setServiceLoading(false);
  }

  const pageChange = useCallback(
    async (currentPage) => {
      fetchServices();
      setPage(page = currentPage === undefined ? page : currentPage);
      fetchTags();
      //fetchData();
    },
    [],
  )

  const serviceChange = useCallback(
    async (service) => {
      setServiceId(service)
      //setServiceName(service)
      //fetchData()
    }, [serviceId]
  )

  const tabChange = useCallback(
    (currentTab) => {
      setTab(tabValue = currentTab);
      if (currentTab === "act") {
        setMyRelated(true);
        setMyAlert(false);
      } else {
        setMyRelated(false);
        setMyAlert(currentTab === "my")
      }
      //fetchData();
    },
    [serviceId, tags],
  )

  useEffect(
    () => {
      pageChange(page)
    }, []
  ) // eslint-disable-line react-hooks/exhaustive-deps

  // const onServiceSelect = useCallback(
  //   (value) => {
  //     console.log(value, ' service call back')
  //     setServiceId(value)
  //     //setServiceName(serviceName=value)
  //   }, [serviceId]
  // )
  const onTagSelect = useCallback(
    (values) => {
      let tmpValues = values.map(val => { return val.toLowerCase() });
      console.log('tag change callback')
      setTags(tmpValues)
      // fetchData();
    }, []
  )

  const onKeywordChange = useCallback(
    (value) => {
      console.log('keyword change callback')
      setQuery(query = value);
    }, []
  )

  const onStateChange = useCallback(
    (value) => {
      console.log(value, '-----state selected')
      setAlertState(value);
    }, []
  )

  const onActionTypeSelected = useCallback(
    (value) => {
      setActionType(value);
    }, []
  )

  const searchData = () => {
    setPage(defaultPage);
    fetchData();
  }

  const onDelete = async (alertId) => {
    setLoad(true);
    let res = await deleteAlert(accounts, instance, alertId);
    if (res.status === 200) {
      message.success(`Alert ${alertId} deleted`);
    } else {
      message.error("Failed to delete alert");
    }
    // force page reload after deletion
    setLoad(false);
    window.location.reload();
  }

  const switchAlertState = async (alertId, alertState) => {
    setLoad(true);
    let option: string;
    if (alertState === AlertState.Active) {
      option = AlertState.Disabled.toString();
    } else {
      option = AlertState.Active.toString();
    }
    let resp = await switchAlert(accounts, instance, alertId, option);
    if (resp.status !== 200) {
      message.error("Failed update alert state", 5)
    } else {
      message.success("Alert state updated", 5)
    }
    setLoad(false);
    window.location.reload();
  }

  if (!document.title.startsWith("Azure Alerting")) {
    document.title = "Azure Alerting";
  }

  return (
    <div>
      <Select placeholder="Service name" style={{ width: "10%" }} loading={serviceLoading} options={serviceOpts} showSearch value={serviceId} className='serviceName' optionFilterProp='label' onChange={(value) => serviceChange(value)} />
      <Select placeholder="Tag filter" style={{ width: "10%", marginLeft: "5px" }} mode="tags" showArrow options={tagOpts} showSearch className='tags' value={tags} onChange={(value) => onTagSelect(value)} />
      <Select placeholder="Alert state" style={{ width: "18%", marginLeft: "5px" }} options={stateOptions} mode="multiple" showArrow onChange={(value) => onStateChange(value)} />
      <Select placeholder="Runner Type" style={{ width: "10%", marginLeft: "5px" }} options={actionTypeOptions} showArrow value={actionType} onChange={(value) => onActionTypeSelected(value)} />
      <Input placeholder="Alert keyword" style={{ width: "10%", marginLeft: "5px" }} onChange={(e) => onKeywordChange(e.target.value)} onPressEnter={fetchData} />
      <Button onClick={searchData} type="primary" style={{ marginLeft: "5px" }}>Search</Button>
      <div>
        <Tabs tabBarGutter={80} size="large" activeKey={tabValue} onChange={tabChange}>
          <TabPane tab="My Alerts" key="my"></TabPane>
          <TabPane tab="My Activities" key="act"></TabPane>
          <TabPane tab="All Alerts" key="all"></TabPane>
        </Tabs>
      </div>

      {/* <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record: IAlert) => renderExtraTable(record),
        }}
        dataSource={tableData}
        rowKey={record => record.id}
        // pagination={{onChange: pageChange, current:page, pageSize:limit, total:total, showTotal:(total)=>`totally ${total} records`}}
        loading={load||serviceLoading}
        //scroll={{x:'max-content'}}
      /> */}
      <TableResize
        dataSource={tableData}
        columns={columns}
        rowKey={record => record.id}
        loading={load || serviceLoading}
      />
      {/* Do not put Modal into any complex component, which causes invalid rendering */}
      <Modal visible={showModal} onOk={() => handleModalOk()} onCancel={handleModalCancel}>
        <p>Select a UTC time within 30 days to test alert in the past</p>
        <DatePicker
          showNow={false}
          format="YYYY-MM-DD HH:mm:ss"
          disabledDate={disabledDate}
          showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
          onChange={onTimeSet}
        />
      </Modal>
    </div>
  );
}

export default AlertTable;