import React from 'react';
import KustoControlCommandWrapper from './KustoControlCommandWrapper';
import { 
    FormInstance,
} from 'antd';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
type ControlCommandProps = {
    active: boolean;
    serviceId: string;
    accounts: AccountInfo[];
    instance: IPublicClientApplication;
    form: FormInstance<any>;
    disabled: boolean;
    command: string;
    setCommand: any;
    clusters: string[];
}

const ControlCommandFormItem: React.FC<ControlCommandProps> = ({ active, serviceId, accounts, instance, form, disabled, command, setCommand, clusters }) => {
    if (active) {
        return (
            /* https://ant.design/components/form/#components-form-demo-without-form-create -> use this to verify the kusto query */
            <KustoControlCommandWrapper serviceId={serviceId} accounts={accounts} instance={instance} form={form} disabled={disabled} command={command} setCommand={setCommand} clusters={clusters}/>
        );
    } else {
        return (<div></div>);
    }
};

export default ControlCommandFormItem