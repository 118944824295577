import { WeekdayMap } from "@constants/const";

export function GetCustomTime(day:number, hour:number, minute:number) {
    var weekday = WeekdayMap[day];
    var hourStr = hour.toString();
    var minuteStr = minute.toString();
    if (hour < 10) {
        hourStr = "0" + hourStr;
    }
    if (minute < 10) {
        minuteStr = "0" + minuteStr;
    }
    return `${weekday} ${hourStr}:${minuteStr}`;
}

export function ceilNumber(num:number){
    var bite = 0;
    if(num < 10){
        return 10;
    }
    while( num >= 10 ){
       num /= 10;
       bite += 1;
    }
    return Math.ceil(num) * Math.pow(10,bite);
}