import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import '../customized.css';

type OperationProps = {
  readOnly : boolean;
  content : string;
  details : string;
  accounts: any;
  instance: any;
}

const GenevaTable: React.FC<OperationProps> = ( { readOnly, content, details, accounts, instance }) => {
  const [rows, setRows] = useState<any[]>([]);

  const columns = [
    {
      title: <div style={{userSelect:"none"}}>Parameter</div>,
      dataIndex: 'parameter',
      key: 'parameter',
      width:180,
      onCell: ()=> {
        return{
          style:{
              maxWidth: 120,
          }
        }
      }
    },
    {
      title: <div style={{userSelect:"none"}}>Description</div>,
      dataIndex: 'description',
      key: 'description',
      align: 'left' as 'left',
      width: 250,
      onCell: ()=> {
        return{
          style:{
              maxWidth: 120
          }
        }
      }
    },
    {
      title: <div style={{userSelect:"none"}}>Allowed value</div>,
      dataIndex: 'acceptedvalues',
      key: 'acceptedvalues',
      align: 'left' as 'left',
      width: 250,
      ellipsis: true,
      onCell: ()=> {
        return{
          style:{
              maxWidth: 120
          }
        }
      }
    }      
  ];
    
    const fetchData = async()=> {
      var tableData:any[] = [];

      if (content !== undefined && content.length > 3 && details !== undefined && details.length > 3) {
        var requestJson = JSON.parse(content);
        var operationDetails = JSON.parse(details);
        var keys = Object.keys(requestJson["parameters"]);
  
        keys.forEach(element => {

          if (operationDetails["parameterDetails"][element] !== undefined){
            var item =
            {
              parameter: element,
              description: operationDetails["parameterDetails"][element]["description"],
              acceptedvalues: operationDetails["parameterDetails"][element]["items"]
            };         
            tableData.push(item);
          }          
        });

        var endpointItem =
            {
              parameter: "endpoint",
              description: operationDetails["parameterDetails"]["endpoint"]["description"],
              acceptedvalues: operationDetails["parameterDetails"]["endpoint"]["items"]
            };  

        tableData.push(endpointItem);
        setRows(tableData);
      }
    }

    useEffect(() => {     
      fetchData();           
    }, [content,details]);

  return (
    <div>    
      <Table locale={{ emptyText: 'Please select operation from dropdown list.' }}
        dataSource={rows}
        columns={columns}
        rowKey={record=>record.parameter}
        loading={false}
        pagination={false}
      />
    </div>
  );
}

export default GenevaTable;