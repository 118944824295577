export enum StandardTime
{
    Never = 0,
    Minutely = 1,
    FiveMinutely = 2,
    QuaterHourly = 3,
    HalfHourly = 4,
    Hourly = 5,
    FourHourly = 6,
    Daily = 7,
    Weekly = 8
}
export enum AlertState
{
    PendingCreation = 0,
    Active = 1,
    PendingUpdate = 2,
    PendingDelete = 3,
    Deleted = 4, // Frontend should never use it
    Disabled = 5,
    PendingAME = 6
}

export enum OperationState {
    Pending = 0,
    Running = 1,
    Suspended = 2
}

export enum ProdState {
    Active = 0,
    PendingAMEApproval = 1
}

export enum DayOfWeek
{
    Monday = 0,
    Tuesday = 1,
    Wednesday = 2,
    Thursday = 3,
    Friday = 4,
    Saturday = 5,
    Sunday = 6,
    Any = 7
}

export enum Cloud
{
    Test = 0,
    Public = 1,
    Blackforest = 2,
    Fairfax = 3,
    Mooncake = 4,
    USNat = 5,
    USSec = 6
}

export enum MachineActionType
{
    SkippedWhiteListed = 0,
    Failed = 1,
    None = 2,
    Reboot = 4,
    Reimage = 8,
    Rebuild = 16,
    Redeploy = 32,
    DeleteInstance = 64,
    PreviousAction = 0x80000000
}

export enum NationalClouds
{
    Mooncake = 1,
    Fairfax  = 2
}

export enum AirgapClouds
{
    USSec = 1,
    USNat = 2
}

export enum ApprovalDocumentState
{
    Approved = 0,
    Rejected = 1,
    ApprovalPending = 2
}

export enum AlertPRStatus 
{
    NotSet = 0,
    Active = 1,
    Abandoned = 2,
    Completed = 3,
    All = 4
}