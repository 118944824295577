import React from 'react';
import { Card, Space, Button  } from 'antd';
import './Home.less';
import AlertTable from '@components/AlertTable';
import { useHistory } from 'react-router';


type Props = {};

const Home: React.FC<Props> = () => {
  let history = useHistory();
  const handleAddAlert = () => {
    history.push('/newalert');
  };

  return (
    <>
      <div className="home" style={{margin:"2%"}}>
        <Card style={{minWidth:'1000px'}}>
          <Space style={{ marginBottom: 16 }}>
            <Button type="primary" onClick={handleAddAlert} style={{position : "absolute", right : "12px", top: "56px"}}>
              + Create Alert
            </Button>
          </Space>
          <AlertTable/>
        </Card>
      </div>
    </>
  );
};

export default Home;
