import React from 'react';
import {
  Form,
  Select
} from 'antd';
import { StandardTime } from '@constants/Enums';



type StandardTimeFormItemProps = {
    active : boolean;
    disabled : boolean;
    defaultValue : StandardTime;
};

const { Option } = Select;

const StandardTimeFormItem: React.FC<StandardTimeFormItemProps> = ({active, disabled, defaultValue}) => {
    const renderStandardTime = () => {
        if (active === true)
        {
          return(
          <Form.Item name="standardTime" label="Standard Time" rules={[{ required: true, message: 'Standard time is required' }]}>
            <Select placeholder="Select reoccuring time" disabled={disabled}>
              <Option value={StandardTime.Never}>Never</Option>
              {/* <Option value={StandardTime.Minutely}>Every minute</Option> */}
              <Option value={StandardTime.FiveMinutely}>Every 5 minutes</Option>
              <Option value={StandardTime.QuaterHourly}>Every 15 minutes</Option>
              <Option value={StandardTime.HalfHourly}>Every 30 minutes</Option>
              <Option value={StandardTime.Hourly}>Every hour</Option>
              <Option value={StandardTime.FourHourly}>Every 4 hours</Option>
              <Option value={StandardTime.Daily}>Daily</Option>
            </Select>
          </Form.Item>)
        } else{
            return (<></>);
        }
    }

    return renderStandardTime();

}

export default StandardTimeFormItem