import React from 'react';
import { Spin } from 'antd';
import { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react';
import { fetchAlertOverallStatisticsChart, fetchAlertStatisticsChart } from '@utils/helpers';
import { Line } from 'react-chartjs-2';

type AlertChartProps = {
    alertId: string;
    type: string;
    timeRange: string;
    alertName: string;
    onClickChartElement: (status: string, num: string, t_stamp: string) => void;
};

const LineChart: React.FC<AlertChartProps> = ({ alertId, type, timeRange, alertName, onClickChartElement }) => {
    const [chartData, setChartData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    //let days = "7";
    const { accounts, instance } = useMsal();

    const getRGB = (status) => {
        switch (status) {
            case 'NotStarted':
                return '255, 99, 152';
            case 'Skipped':
                return '153, 204, 255';
            case 'Enqueued':
                return '0, 153, 51';
            case 'Executing':
                return '51, 102, 204';
            case 'Succeeded':
                return '135, 219, 32';
            case 'Failed':
                return '255, 51, 0';
            case 'Paused':
                return '155, 199, 52';
            case 'PartiallyCompleted':
                return '51, 204, 204';
            case 'CompletedWithError':
                return '204, 153, 0';
            case 'Completed':
                return '135, 219, 32';    
            default:
                return '255, 255, 255';
        }
    }

    const FetchData = async () => {
        
        if(timeRange === "30d" || timeRange === "14d" || timeRange === "7d"){
            setLoading(true);
        }
        
        var chartResults;

        if (alertId === "") {
            chartResults = await fetchAlertOverallStatisticsChart(accounts, instance, timeRange);
        }
        else if (alertId!==undefined) {  
            chartResults = await fetchAlertStatisticsChart(accounts, instance, alertId, timeRange, type);
        } else {
            return setChartData([]);
        }
        
        setLoading(false);   
        
        var tmpData = chartResults.map(item => ({
            label: item.Status,
            data: item.num,
            fill: false,
            backgroundColor: 'rgb(' + getRGB(item.Status) + ')',
            borderColor: 'rgba(' + getRGB(item.Status) + ', 0.3)',
            date: item.list_TimeStamp,
        }));
        setChartData(tmpData);
    }

    useEffect(() => {
        FetchData();
    }, [alertId, timeRange]);

    function OnClick(evt, element) {
        if(element.length > 0)
        {
          var countIndex = element[0].index;
          var timeStampIndex = element[0].element.$context.parsed.x;
          var status = data.datasets[element[0].datasetIndex].label;

          if (countIndex !== undefined && timeStampIndex !== undefined && status !== undefined) {
            var count = data.datasets[element[0].datasetIndex].data[countIndex];
            var timeStamp = data.datasets[element[0].datasetIndex].date[timeStampIndex];

            // console.log(status);
            // console.log(count);
            // console.log(timeStamp);

            onClickChartElement(status, count, timeStamp);
          }
        }
      };

    var labels = ['1', '2'];
    labels = [];

    chartData.forEach(element => {
        labels = element.date;
    });

    var data = {
        labels: labels,
        datasets: chartData,
    };

    var isTitleDisplay = true;
    if (alertName === ""){
        isTitleDisplay = false;
    } 

    const options = {
        onClick: OnClick,
        onHover: (event, chartElement) => {
            const target = event.native ? event.native.target : event.target;
            target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        scales: {
            y: {
                beginAtZero: true
            }
        },
        animation: {
            duration: 3000
        },
        plugins:{
            title: {
               display: isTitleDisplay,
               text: alertName
               }
        },
    };

    return (
        <Spin tip="Loading data..." size="default" spinning={loading} delay={200}>
            <Line data={data} options={options}/>
        </Spin>        
    );
}

export default LineChart;