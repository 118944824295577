import React, { CSSProperties, useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  BackTop,
  Button,
  Radio,
  Select,
  InputNumber,
  Checkbox,
  Space,
  Modal,
  message,
  Tooltip,
  Switch,
  Row,
  Col,
  Typography
} from 'antd';
import DynamicKustos from "@components/SecondaryKustoQuery";
import EditableTagGroup from "@components/EditableTag";
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { createAlert, editAlert, fetchService, fetchServiceIcm, fetchServiceTeams, machineActionKusto, runKustoQuery } from '@utils/helpers';
import { useMsal } from '@azure/msal-react';
import StandardTimeFormItem from './StandardTimeFormItem';
import ICMFormItem from './ICMFormItem';
import EmailFormItem from './EmailFormItem';
import MachineAction from './MachineAction';
import GenevaActionsFormItem from '@components/GenevaActionsForm/GenevaActionsFormItem';
import { Redirect } from 'react-router';
import WorkItemComponent from './WorkItem';
import ControlCommandFormItem from './ControlCommandFormItem';
import moment from 'moment-timezone';
import KustoQueryWrapper from './KustoQueryWrapper';
import CronTime from './CronTimeFormItem';
import RichTextEditor from './RichTextEditor';
import '../../../styles/base.less';
import { UpCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';
import { CustomizedTooltip, acknowledgment, filterKustoCluster, convertAllTagToKustoCluster } from '@components/common';
import KustoQueryCommon from './KustoCommon';
import { AirgapClouds, NationalClouds } from '@constants/Enums';
import { DefaultFairfaxCluster, DefaultMooncakeCluster, DefaultUSSecCluster, DefaultUSNatCluster, DefaultMooncakeDbname, DefaultFairfaxDbname } from '@constants/const';

const { Text } = Typography;


type AlertFormProps = {
  isNewAlert : boolean;
  isDisabled : boolean;
  existingAlert?: IAlert;
  setEdit?: any;
};

const AlertForm: React.FC<AlertFormProps> = ({isNewAlert, isDisabled, existingAlert, setEdit}) => {
  const { accounts, instance } = useMsal();
  const [editingDisabled, setEditingDisabled] = useState<boolean>(false);
  const [isStandard, setIsStandard] = useState(false);
  const [isCron, setIsCron] = useState(false);
  const [tags, setTags] = useState<string[]>((existingAlert?.tags !== undefined && existingAlert?.tags !== null) ? existingAlert.tags : []);
  const [checkedValues, setCheckedValues] = useState<CheckboxValueType[]>([]);
  const [checkedMitigations, setCheckedMitigations] = useState<CheckboxValueType[]>([]);
  const [checkedNationalClouds, setCheckedNationalClouds] = useState<CheckboxValueType[]>([]);
  const [checkedAirgapClouds, setCheckedAirgapClouds] = useState<CheckboxValueType[]>([]);
  const [services, setServices] = useState<any>([]);
  const [serviceLoading, setServiceLoading] = useState<boolean>(false);
  const [icms, setIcms] = useState<any>([]);
  const [icmLoading, setIcmLoading] = useState(false);
  const [serviceName, setServiceName] = useState<string>("");
  const [serviceId, setServiceId] = useState<string>("");
  const [devopsProj, setDevopsProj] = useState<string>("");
  const [fireRedirect, setRedirect] = useState<boolean>(false);
  const [creatLoading, setCreateLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [cloudOpts, setCloudOpts] = useState<any[]>([]);
  const [clusters, setClusters] = useState<string[]>([]); // for run_now clusters
  const [query, setQuery] = useState<string>("");
  const [command, setCommand] = useState<string>("");
  const [cnQuery, setCnQuery] = useState<string>("");
  const [usQuery, setUsQuery] = useState<string>("");
  const [ussecQuery, setUssecQuery] = useState<string>("");
  const [usnatQuery, setUsnatQuery] = useState<string>("");
  const [queries, setQueries] = useState<any[]>([]);
  const [ussecSecondaryQueries, setUssecSecondaryQueries] = useState<any[]>([]);
  const [usnatSecondaryQueries, setUsnatSecondaryQueries] = useState<any[]>([]);
  const [actionValidLoading, setActionValidLoading] = useState(false);
  //const [serviceMap, setServiceMap] = useState<Map<string, any>>();

  const [genevaActionData, setGenevaActionData] = useState<any>([]);
  const [existingGenevaRequest, setExistingGenevaRequest] = useState<any>([]);
  const [genevaSaveLoading, setGenevaSaveLoading] = useState(false);
  const [genevaValidationSucces, setGenevaValidationSucces] = useState(false);
  const [genevaExtensionOptions, setGenevaExtensionOptions] = useState<string[]>([]);
  const [genevaRestrictedOperations, setGenevaRestrictedOperations] = useState<string[]>([]);
  const [genevadSTSAuthEnabled, setGenevadSTSAuthEnabled] = useState(false);
  const [validateGenevaNCloudQuery, setValidateGenevaNCloudQuery] = useState(0);

  const [mooncakeCloudOpts, setMooncakeCloudOpts] = useState<any[]>([]);
  const [fairfaxCloudOpts, setFairfaxCloudOpts] = useState<any[]>([]);
  const [ussecCloudOpts, setUssecCloudOpts] = useState<any[]>([]);
  const [usnatCloudOpts, setUsnatCloudOpts] = useState<any[]>([]);
  const [mooncakedbclusteroverride, setMooncakedbclusteroverride] = useState<any[]>([]);
  const [fairfaxdbclusteroverride, setFairfaxdbclusteroverride] = useState<any[]>([]);
  const [ussecdbclusteroverride, setUssecdbclusteroverride] = useState<any[]>([]);
  const [usnatdbclusteroverride, setUsnatdbclusteroverride] = useState<any[]>([]);

  const [alertApprovers, setAlertApprovers] = useState<string>("");
  const [richDes, setRichDes] = useState('');
  let [submitMode, setSubmitMode] = useState('submit');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [queryType, setQueryType] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();

  const [compactMode, setCompactMode] = useState(false);

  const history = useHistory();

  const checkboxOptions = ['Email', 'ICM', 'Work Item', 'Kusto Control Command', 'Teams'];
  const nationalCloudsOptions = [
    {
      label: 'Mooncake',
      value: NationalClouds.Mooncake
    },
    {
      label: 'Fairfax',
      value: NationalClouds.Fairfax
    }
  ]
  const airgapCloudsOptions = [
    {
      label: 'USSec',
      value: AirgapClouds.USSec
    },
    {
      label: 'USNat',
      value: AirgapClouds.USNat
    }   
  ]
  const alias = accounts[0].username.split("@",1)[0];

  //const mitigationOptions = ['Machine Action','Geneva Actions']  
  const [mitigationOptions, setMitigationOptions] = useState<string[]>(['Geneva Actions']);


    
  // render helper for existing alert
  const renderExisting = () => {
    form.setFieldsValue(existingAlert);
    setEditingDisabled(true);
    if (existingAlert !== undefined)
    {
      // change document title based on existing alert title
      document.title = existingAlert.title;
      let tmpCheckedActions:string[] = [];
      let tmpcheckedMitigations:string[] = [];
      let tmpCheckedNClouds: number[] = [];
      let tmpCheckedAirgapClouds: number[] = [];
      setServiceName(existingAlert.serviceName);
      setServiceId(existingAlert.serviceId);
      onServiceChange(existingAlert.serviceId);
      setRichDes(form.getFieldValue('description'));
      if(existingAlert.mooncakeKustoQuery) { tmpCheckedNClouds.push(NationalClouds.Mooncake) }
      if(existingAlert.fairfaxKustoQuery) { tmpCheckedNClouds.push(NationalClouds.Fairfax) }
      if(existingAlert.ussecKustoQuery) { tmpCheckedAirgapClouds.push(AirgapClouds.USSec) }
      if(existingAlert.usnatKustoQuery) { tmpCheckedAirgapClouds.push(AirgapClouds.USNat) }

      if(existingAlert.mooncakeKustoClusters) {
        let tmpMooncakeCloudsV2:string[] = [];
        for(let kustoCluster of existingAlert.mooncakeKustoClusters){
          tmpMooncakeCloudsV2.push(JSON.stringify(kustoCluster))
        }
        form.setFieldsValue({'mooncakeclusters':tmpMooncakeCloudsV2});
        setMooncakedbclusteroverride(tmpMooncakeCloudsV2); 
        }
      if(existingAlert.fairfaxKustoClusters) { 
        let tmpFairfaxCloudsV2:string[] = [];
        for(let kustoCluster of existingAlert.fairfaxKustoClusters){
          tmpFairfaxCloudsV2.push(JSON.stringify(kustoCluster))
        }
        form.setFieldsValue({'fairfaxclusters': tmpFairfaxCloudsV2});
        setFairfaxdbclusteroverride(tmpFairfaxCloudsV2); 
      }
      if(existingAlert.ussecKustoClusters) {
        let tmpUssecCloudsV2:string[] = [];
        for(let kustoCluster of existingAlert.ussecKustoClusters){
          tmpUssecCloudsV2.push(JSON.stringify(kustoCluster))
        }
        form.setFieldsValue({'ussecclusters':tmpUssecCloudsV2});
        setUssecdbclusteroverride(tmpUssecCloudsV2); 
      }
      if(existingAlert.usnatKustoClusters) {
        let tmpUsnatCloudsV2:string[] = [];
        for(let kustoCluster of existingAlert.usnatKustoClusters){
          tmpUsnatCloudsV2.push(JSON.stringify(kustoCluster))
        }
        form.setFieldsValue({'usnatclusters':tmpUsnatCloudsV2});
        setUssecdbclusteroverride(tmpUsnatCloudsV2); 
      }

      setCheckedNationalClouds(tmpCheckedNClouds);
      form.setFieldsValue({'nClouds': tmpCheckedNClouds})
      setCheckedAirgapClouds(tmpCheckedAirgapClouds);
      form.setFieldsValue({'airgapClouds': tmpCheckedAirgapClouds})
      if(form.getFieldValue('mooncakeKustoQuery')){
        setCnQuery(form.getFieldValue(['mooncakeKustoQuery', 'kustoQuery']))
      }
      if(form.getFieldValue('fairfaxKustoQuery')){
        setUsQuery(form.getFieldValue(['fairfaxKustoQuery', 'kustoQuery']))
      }
      if(form.getFieldValue('ussecKustoQuery')){
        setUssecQuery(form.getFieldValue(['ussecKustoQuery', 'kustoQuery']))
      }
      if(form.getFieldValue('usnatKustoQuery')){
        setUsnatQuery(form.getFieldValue(['usnatKustoQuery', 'kustoQuery']))
      }
      existingAlert.actions?.forEach(action=>{
        if ('emailTo' in action){
          form.setFieldsValue({'aliases': action['emailTo'].join(';'), 'isReportingMode': action['isReportingMode']});
          tmpCheckedActions.push('Email')
        } else if ('severity' in action){
          form.setFieldsValue({'icm': {'severity': action['severity'], 'team': {'label': action['teamAssignedTo'], 'value': action['routingID']}}})
          tmpCheckedActions.push('ICM')
        } else if ('orgUri' in action) {
          let tmpWorkItem = {}
          action['jsonPatchOperations']?.forEach(operation=>{
            if (operation['path'] === '/fields/System.Title') {
              tmpWorkItem['Title'] = operation['value']
            } else if (operation['path'] === '/fields/System.AssignedTo') {
              tmpWorkItem['AssignedTo'] = operation['value']
            } else if (operation['path'] === '/fields/System.AreaPath') {
              tmpWorkItem['AreaPath'] = operation['value']
            } else if (operation['path'] === '/fields/System.IterationPath') {
              tmpWorkItem['IterationPath'] = operation['value']
            } else {
              console.log('invalid data returned')
            }
          })
          form.setFieldsValue({'workItem': tmpWorkItem})
          tmpCheckedActions.push('Work Item')
        } else if ('actionType' in action){
          form.setFieldsValue({'machineAction': action['actionType']})
          tmpcheckedMitigations.push('Machine Action');
        }
        else if ('genevaOperation' in action){
          setExistingGenevaRequest(action['genevaOperation']);
          tmpcheckedMitigations.push('Geneva Actions');
        }
        else if ('kustoCommand' in action){
          form.setFieldsValue({'kustoControlCommand': {'title': action['title'], 'kustoCommand': action['kustoCommand'] }});
          setCommand(action['kustoCommand']);
          tmpCheckedActions.push('Kusto Control Command');
        }
        else if('teamsReceipients' in action){
          tmpCheckedActions.push('Teams');
        }
      })
      setQueryType(existingAlert.queryType?existingAlert.queryType:0);
      form.setFieldsValue({'actions': tmpCheckedActions})
      form.setFieldsValue({'mitigations': tmpcheckedMitigations})
      setCheckedValues(tmpCheckedActions);
      setCheckedMitigations(tmpcheckedMitigations);
      if (existingAlert.cron !== undefined && existingAlert.cron !== null) {
        form.setFieldsValue({'timeType': 'cron'});
        setIsCron(true);
      } else {
        form.setFieldsValue({'timeType': 'standard'});
        setIsStandard(true);
      }
    }
    if (form.getFieldValue('primaryKustoQuery') !== undefined) {
      setQuery(form.getFieldValue(['primaryKustoQuery', 'kustoQuery']));
    }
    if (form.getFieldValue('secondaryKustoQueries')?.length > 0) {
      setQueries(form.getFieldValue('secondaryKustoQueries'));
    }
    if (form.getFieldValue('ussecSecondaryKustoQueries')?.length > 0) {
      setUssecSecondaryQueries(form.getFieldValue('ussecSecondaryKustoQueries'));
    }
    if (form.getFieldValue('usnatSecondaryKustoQueries')?.length > 0) {
      setUsnatSecondaryQueries(form.getFieldValue('usnatSecondaryKustoQueries'));
    }
    if(existingAlert?.kustoClusters){
      // stringfy
      let tmpCloudsV2:string[] = [];
      for(let kustoCluster of existingAlert.kustoClusters){
        tmpCloudsV2.push(JSON.stringify(kustoCluster))
      }
      form.setFieldsValue({'kustoClusters':tmpCloudsV2});
      setClusters(tmpCloudsV2);
    } else {
      setClusters(form.getFieldValue('clouds')); 
    }
  }

  // Apply only to creating new alert
  const renderNew = () => {
    form.setFieldsValue({'primaryKustoQuery': {'isGraphInReport': false}})
    form.setFieldsValue({'minimumHitCount': 1})
  }

/*---------------------------------------- Fetch cold data -----------------------------------------*/
  // const fetchClouds = async (serviceId) => {
  //   let kustoData = await fetchServiceKustos(accounts, instance, serviceId);
  //   let tmpOpts:any = [];
  //   Object.keys(kustoData).map(function(key, index){
  //     let entry = {label: key, value: kustoData[key]}
  //     tmpOpts.push(entry);
  //   })
  //   let kustoOpts = tmpOpts.sort((n1, n2)=> {
  //     if(n1.label > n2.label) {
  //       return 1
  //     } else {
  //       return -1
  //     }
  //   })
  //   setCloudOpts(kustoOpts);
  // }

  const fetchAllKustoClustersV2 = (service) => {
    let kustoData:KustoCluster[] = service.kustoClusters;
    let kustoAllTags:string[] = service.kustoAllTags;
    //let kustoMap = new Map<string, any>();
    let kustoClusterOpts:any = [];
    // set airgap clusters to not show up in all for public
    kustoData = kustoData.filter((kusto) => {
      return !((kusto.clusterSuffix).toLowerCase().includes("microsoft.scloud") || (kusto.clusterSuffix).toLowerCase().includes(".eaglex.ic.gov"))
    })
    kustoData.forEach(kusto=>{
      kustoClusterOpts.push({label: kusto['cloud']+'-'+kusto['cluster']+'-'+kusto['database'], value: JSON.stringify(kusto)})
    })
    if(kustoAllTags){
      kustoAllTags.forEach(tag=>{
        kustoClusterOpts = [{label:tag, value:JSON.stringify(convertAllTagToKustoCluster(tag))}, ...kustoClusterOpts]
      })
    }
    kustoClusterOpts = [{label:'All', value:JSON.stringify(convertAllTagToKustoCluster("All-All-All"))}, ...kustoClusterOpts]
    setCloudOpts(kustoClusterOpts)
  }

  const fetchNCloudKustoClustersV2 = (service, ncloudType) => {
    let kustoData: KustoCluster[] = service.kustoClusters;
    //let kustoMap = new Map<string, any>();
    let kustoClusterOpts: any = [];
    if (ncloudType === NationalClouds.Mooncake) {
      kustoData.forEach(kusto => {
        if (kusto['cloud'] === 'Mooncake'){
          kustoClusterOpts.push({ label: kusto['cloud'] + '-' + kusto['cluster'] + '-' + kusto['database'], value: JSON.stringify(kusto) })
        }        
      })
      setMooncakeCloudOpts(kustoClusterOpts)
    }
    else if (ncloudType === NationalClouds.Fairfax) {
      kustoData.forEach(kusto => {
        if (kusto['cloud'] === 'Fairfax'){
          kustoClusterOpts.push({ label: kusto['cloud'] + '-' + kusto['cluster'] + '-' + kusto['database'], value: JSON.stringify(kusto) })
        }
      })
      setFairfaxCloudOpts(kustoClusterOpts)
    }
  }

  const fetchAirgapCloudKustoClustersV2 = (service, airgapCloudType) => {
    let kustoData: KustoCluster[] = service.kustoClusters;
    let kustoClusterOpts: any = [];
    if (airgapCloudType === AirgapClouds.USSec) {
      kustoData.forEach(kusto => {
        if (kusto['cloud'] === 'USSec'){
          kustoClusterOpts.push({ label: kusto['cloud'] + '-' + kusto['cluster'] + '-' + kusto['database'], value: JSON.stringify(kusto) })
        }
      })
      setUssecCloudOpts(kustoClusterOpts)
    }
    else if (airgapCloudType === AirgapClouds.USNat) {
      kustoData.forEach(kusto => {
        if (kusto['cloud'] === 'USNat'){
          kustoClusterOpts.push({ label: kusto['cloud'] + '-' + kusto['cluster'] + '-' + kusto['database'], value: JSON.stringify(kusto) })
        }
      })
      setUsnatCloudOpts(kustoClusterOpts)
    }
  }

  const fetchServiceOptions = async() => {
    setServiceLoading(true);
    let serviceTeams = await fetchServiceTeams(accounts, instance, true);
    let tmpMap = new Map<string, any>();
    for(let service of serviceTeams) {
      tmpMap[service.id] = service;
    }
    // render all clusters opts in a very beginning step to optimize experience
    if(form.getFieldValue('serviceId')){
      fetchAllKustoClustersV2(tmpMap[form.getFieldValue('serviceId')]);
      fetchNCloudKustoClustersV2(tmpMap[form.getFieldValue('serviceId')], NationalClouds.Mooncake);
      fetchNCloudKustoClustersV2(tmpMap[form.getFieldValue('serviceId')], NationalClouds.Fairfax);
      fetchAirgapCloudKustoClustersV2(tmpMap[form.getFieldValue('serviceId')], AirgapClouds.USSec);
      fetchAirgapCloudKustoClustersV2(tmpMap[form.getFieldValue('serviceId')], AirgapClouds.USNat);
    }
    let tmpServices = serviceTeams.map(item=>({value: item.id, label: item.serviceName}));
    if (tmpServices.length === 0) {
      return;
    }
    /* Disable the auto-selection of service list. */
    ///setServiceId(tmpServices[0].value);
    setServices(tmpServices);
    setServiceLoading(false);
    // if (!form.getFieldValue('serviceName')) {
    //   form.setFieldsValue({'serviceName': tmpServices[0].value})
    // }
    //let tmpServiceId = form.getFieldValue('serviceId')? form.getFieldValue('serviceId'):tmpServices[0].value;
    //onServiceChange(tmpServiceId);
  }


  useEffect(() => {
    fetchServiceOptions();
  
    if (existingAlert !== undefined) {
      renderExisting();
    } else {
      renderNew();
    }

    // Handle redirection or page refresh based on alert status
    if (fireRedirect) {
      if (isNewAlert) {
        // Use history.push for navigation without a page refresh
        history.push('/');
      } else {
        // For an existing alert, force a refresh
        const alertUrl = `/alert/${existingAlert?.id}`;
        window.location.href = alertUrl;
      }
    }
  }, [fireRedirect]);
/*----------------------------------------- Event Hook Functions -------------------------------------*/
  const onServiceChange = async (value) => {
    setIcmLoading(true);
    if (value !== form.getFieldValue('serviceId')) {
      form.setFieldsValue({'clouds': []})
    }
    let servicedata = await fetchService(accounts, instance, value);
    fetchAllKustoClustersV2(servicedata);
    fetchNCloudKustoClustersV2(servicedata, NationalClouds.Mooncake);
    fetchNCloudKustoClustersV2(servicedata, NationalClouds.Fairfax);
    fetchAirgapCloudKustoClustersV2(servicedata, AirgapClouds.USSec);
    fetchAirgapCloudKustoClustersV2(servicedata, AirgapClouds.USNat);

    if(servicedata['genevaExtensions'] && servicedata['genevaExtensions'].length > 0) {      
      
      let extensionOpts: any = [];
      servicedata['genevaExtensions'].forEach(element => {
        if(element !== undefined && element.length > 0) {
          let entry = { label: element, value: element }
          extensionOpts.push(entry);
        }
      });
      setGenevaExtensionOptions(extensionOpts);

      let genevaRestrictedOpts: any = [];
      if(servicedata['genevaRestrictedOperations'] !== undefined && servicedata['genevaRestrictedOperations'] !== null && servicedata['genevaRestrictedOperations'].length > 0){
        servicedata['genevaRestrictedOperations'].forEach(element => {
          genevaRestrictedOpts.push(element);
        });
      }
      setGenevaRestrictedOperations(genevaRestrictedOpts);
      
      let genevadSTSenabled: boolean = false;
      if(servicedata['genevaActionsdSTSCertSAN'] !== undefined && servicedata['genevaActionsdSTSCertSAN'] !== null && servicedata['genevaActionsdSTSCertSAN'].length > 0){
        genevadSTSenabled = true;
      }

      setGenevadSTSAuthEnabled(genevadSTSenabled);
    }
    else {
      let extensionOpts: any = [];
      setGenevaExtensionOptions(extensionOpts);

      let genevaRestrictedOpts: any = [];
      setGenevaRestrictedOperations(genevaRestrictedOpts);   
    }

    var mitigationOpts = mitigationOptions;
    if (!mitigationOpts.includes('Geneva Actions')){
      mitigationOpts.push('Geneva Actions');
    }

    if (value==="10060" || value === "1197" || value === "58730"){
      if(!mitigationOpts.includes('Machine Action')){
        mitigationOpts.push('Machine Action');
      }      
    }
    else if (mitigationOptions.includes('Machine Action')) {
      mitigationOpts = ['Geneva Actions'];
    }
    setMitigationOptions(mitigationOpts);
    
    setDevopsProj(servicedata['devOpsProjectName'])
    if (isNewAlert) {
      setRichDes(servicedata['alertDefaultDescription']);
    }
    let serviceIcmCfg = await fetchServiceIcm(accounts, instance, value);
    setServiceName(serviceIcmCfg.serviceName)
    setServiceId(serviceIcmCfg.id)
    let tmpIcms = serviceIcmCfg.teamRules.map(item=>({value:item.routingId, label:item.teamName}))
    //fetchClouds(value);
    setIcms(tmpIcms);
    setIcmLoading(false);
  }

  const onActionChange = (checkedVals) => {
    setCheckedValues(checkedVals);
  }

  const onMitigationChange = (checkedVals) => {
    setCheckedMitigations(checkedVals);
  }

  const onCloudsChange = (vals) => {
    warnCloudChange(vals)
    setClusters(vals);
  }

  const warnCloudChange = (clouds) => {
    try {
      for(let cloud of clouds){
        let kustoCloud:KustoCluster = JSON.parse(cloud)
        if(kustoCloud.cloud==='All' && kustoCloud.cluster === 'All' && kustoCloud.database === 'All'){
          message.warn('Please be cautious using All tag as it could cause bad kusto query performance', 10)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onMooncakeCloudsChange = (vals) => {
    setMooncakedbclusteroverride(vals);
  }

  const onFairfaxCloudsChange = (vals) => {
    setFairfaxdbclusteroverride(vals);
  }

  const onUssecCloudsChange = (vals) => {
    setUssecdbclusteroverride(vals);
  }

  const onUsnatCloudsChange = (vals) => {
    setUsnatdbclusteroverride(vals);
  }

  const onSelectNationalClouds = (vals) => {
    setCheckedNationalClouds(vals);
    if(vals.includes(NationalClouds.Mooncake)&&!cnQuery){
      // set initial value for kusto title
      if(!form.getFieldValue(['mooncakeKustoQuery','title'])){
        form.setFieldsValue({'mooncakeKustoQuery':{'title': form.getFieldValue(['primaryKustoQuery','title'])}})
      }
      // set initial value for kusto query
      setCnQuery(query);
    }
    if (vals.includes(NationalClouds.Fairfax)&&!usQuery){
      if(!form.getFieldValue(['fairfaxKustoQuery', 'title'])){
        form.setFieldsValue({'fairfaxKustoQuery': {'title': form.getFieldValue(['primaryKustoQuery', 'title'])}})
      }
      setUsQuery(query);
    }
  }
 
  const onSelectAirgapClouds = (vals) => {
    setCheckedAirgapClouds(vals);
    if(vals.includes(AirgapClouds.USSec)&&!ussecQuery){
      if(!form.getFieldValue(['ussecKustoQuery','title'])){
        form.setFieldsValue({'ussecKustoQuery':{'title': form.getFieldValue(['primaryKustoQuery','title'])}})
      }
      // prefill secondary queries
      if(!form.getFieldValue('ussecSecondaryKustoQueries')){
        form.setFieldsValue({'ussecSecondaryKustoQueries': form.getFieldValue('secondaryKustoQueries')});
      }
      setUssecQuery(query);
      setUssecSecondaryQueries(queries);
    }
    if (vals.includes(AirgapClouds.USNat)&&!usnatQuery){
      if(!form.getFieldValue(['usnatKustoQuery', 'title'])){
        form.setFieldsValue({'usnatKustoQuery': {'title': form.getFieldValue(['primaryKustoQuery', 'title'])}})
      }
      // prefill secondary queries
      if(!form.getFieldValue('usnatSecondaryKustoQueries')){
        form.setFieldsValue({'usnatSecondaryKustoQueries': form.getFieldValue('secondaryKustoQueries')});
      }
      setUsnatQuery(query);
      setUsnatSecondaryQueries(queries);
    }
  }

  const onFinish = async (alert : IAlert) => {
    // stop here in case Geneva action is selected but kusto query isn't validated.
    if(checkedMitigations.includes('Geneva Actions') && (genevaValidationSucces === undefined || !genevaValidationSucces) ) {
      return;
    }

    // modify the alert object to include the kusto query as that is not part of the object by default
    if (form.getFieldValue('customtime') !== undefined)
    {
      let offset = moment().utcOffset();
      let cusTime = moment(form.getFieldValue('customtime').time).utcOffset(offset).format('HH:mm').split(":");
      let customTime : ICustomTime = {
        hour: parseInt(cusTime[0]),
        minute: parseInt(cusTime[1]),
        dayOfWeek: Number(form.getFieldValue('customtime').day)
      }
      alert.CustomTimes = [customTime]
      alert['customtime'] = undefined
    }
    
    alert.serviceName = serviceName;
    alert.tags = tags;
    if (!alert.tags.includes(alias.toLowerCase())) {
      alert.tags.push(alias.toLowerCase());
    }
    if (!alert.tags.includes(serviceName.toLowerCase())) {
      alert.tags.push(serviceName.toLowerCase());
    }
    
    alert.actions = [];
    if(checkedValues.includes('Teams')){
      let teamsAction: ITeamsAction = {
        TeamsReceipients: [accounts[0].username]
      }
      alert.actions.push(teamsAction)
    }

    // fill in email related data
    let aliases = form.getFieldValue('aliases');
    if (checkedValues.includes('Email') && aliases !== undefined && aliases !== '') {
      let email : IEmailAction = {
        EmailTo: aliases.split(";").filter(item => item), // filter empty items
        IsReportingMode: form.getFieldValue("isReportingMode")
      }
      alert.actions.push(email);
      email.EmailTo.forEach(emailAlias=>{
        let tmpAlias = emailAlias.split("@",1)[0];
        if (!alert.tags.includes(tmpAlias.toLowerCase())) {
          alert.tags.push(tmpAlias.toLowerCase())
        }
      })
    }
    // fill in ICM related data
    let rawIcmData = form.getFieldValue('icm');
    if (checkedValues.includes('ICM') && rawIcmData !== undefined) {
      let icm : IcmAction = {
        Severity: rawIcmData.severity,
        RoutingID: rawIcmData.team.value,
        TeamAssignedTo: rawIcmData.team.label
      }
      alert.actions.push(icm);
      let sevTag = `sev${rawIcmData.severity}`;
      if(!alert.tags.includes(sevTag))
      {
        alert.tags.push(sevTag);
      }
    }
    // fill in work item related data
    let workItemData = form.getFieldValue('workItem');
    if (checkedValues.includes('Work Item') && workItemData !== undefined) {
      let TitleOperation : JsonPatchOperation = {
        op: 0,
        Path: "/fields/System.Title",
        Value: workItemData.Title
      }
      let AreaPathOperation : JsonPatchOperation = {
        op: 0,
        Path: "/fields/System.AreaPath",
        Value: workItemData.AreaPath
      }
      let IterationPathOperation : JsonPatchOperation = {
        op: 0,
        Path: "/fields/System.IterationPath",
        Value: workItemData.IterationPath
      }
      let AssignedToOperation : JsonPatchOperation = {
        op: 0,
        Path: "/fields/System.AssignedTo",
        Value: workItemData.AssignedTo
      }
      let operations : JsonPatchOperation[] = [TitleOperation, AreaPathOperation, IterationPathOperation, AssignedToOperation];
      let workItem : WorkItem = {
        OrgUri: "https://dev.azure.com/msazure",
        ProjectName: "Antares",
        JsonPatchOperations: operations
      }
      alert.actions.push(workItem);
      alert['workItem'] = undefined;
    }
    // fill in kusto control command data
    let kustoControlCommandData = form.getFieldValue('kustoControlCommand');
    if (checkedValues.includes('Kusto Control Command') && kustoControlCommandData !== undefined) {
      let controlCommand: ControlCommandAction = {
        Title: kustoControlCommandData.title ?? "",
        KustoCommand: kustoControlCommandData.kustoCommand ?? ""
      }
      alert.actions.push(controlCommand);
      alert['kustoControlCommand'] = undefined
    }
    // fill in machine action related data
    //let machineActionData = form.getFieldValue('machineAction');
    let machineActionData = 1;
    if (checkedMitigations.includes('Machine Action') && machineActionData !== undefined) {
      let machineAction : IMachineActionType = {
        ActionType: machineActionData
      }
      alert.actions.push(machineAction);
      alert['machineAction'] = undefined;
    }
    if (checkedMitigations.includes('Geneva Actions')) {
      if(genevaActionData !== undefined || genevaActionData !== ""){
        let genevaJson = JSON.parse(genevaActionData);
        let geneva : IGenevaActionType = {
          GenevaOperation: genevaJson
        }

        alert.actions.push(geneva);        
        if(!alert.tags.includes('geneva')){
          alert.tags.push('geneva');
        }        
        alert['genevaAction'] = undefined;
      }
    }
    alert.description = richDes;
    alert.serviceId = serviceId;
    alert.queryType = queryType;
    alert.primaryKustoQuery.kustoQuery = query;
    if(alert.primaryKustoQuery.kustoQuery.includes('\r\n')){
      alert.primaryKustoQuery.kustoQuery = alert.primaryKustoQuery.kustoQuery.replace(/(\r\n)+/g, '\r\n');
    }
    if(form.getFieldValue('secondaryKustoQueries')){
      let tmpSecondaryQueries = form.getFieldValue('secondaryKustoQueries');
      for(let i=0; i<tmpSecondaryQueries.length; i++){
        if(tmpSecondaryQueries[i].kustoQuery.includes('\r\n')){
          tmpSecondaryQueries[i].kustoQuery = tmpSecondaryQueries[i].kustoQuery.replace(/(\r\n)+/g, '\r\n');
        }
      }
      // modify the order of secondary queries
      tmpSecondaryQueries = tmpSecondaryQueries.map((query, index) => {
        return {
          ...query,
          position: index + 1
        }
      });
      alert['secondaryKustoQueries'] = tmpSecondaryQueries;
    }
    if(form.getFieldValue('ussecSecondaryKustoQueries')){
      let tmpSecondaryQueries = form.getFieldValue('ussecSecondaryKustoQueries');
      for(let i=0; i<tmpSecondaryQueries.length; i++){
        if(tmpSecondaryQueries[i].kustoQuery.includes('\r\n')){
          tmpSecondaryQueries[i].kustoQuery = tmpSecondaryQueries[i].kustoQuery.replace(/(\r\n)+/g, '\r\n');
        }
      }
      alert['ussecSecondaryKustoQueries'] = tmpSecondaryQueries;
    }
    if(form.getFieldValue('usnatSecondaryKustoQueries')){
      let tmpSecondaryQueries = form.getFieldValue('usnatSecondaryKustoQueries');
      for(let i=0; i<tmpSecondaryQueries.length; i++){
        if(tmpSecondaryQueries[i].kustoQuery.includes('\r\n')){
          tmpSecondaryQueries[i].kustoQuery = tmpSecondaryQueries[i].kustoQuery.replace(/(\r\n)+/g, '\r\n');
        }
      }
      alert['usnatSecondaryKustoQueries'] = tmpSecondaryQueries;
    }
    alert.primaryKustoQuery.position = existingAlert?.primaryKustoQuery.position?existingAlert.primaryKustoQuery.position:0;
    // when submit, wrap national cloud kusto data object when selected
    if (cnQuery&&checkedNationalClouds.includes(NationalClouds.Mooncake)) {
      let mooncakeKustoQuery : KustoQueryEntry = {
        kustoQuery: cnQuery,
        position: 0,
        title: form.getFieldValue(['mooncakeKustoQuery', 'title']),
        isGraphInReport: false      
      }
      alert.mooncakeKustoQuery = mooncakeKustoQuery;
      let mooncakeClustersV2:KustoCluster[] = filterKustoCluster(form.getFieldValue('mooncakeclusters'));
      alert.mooncakeKustoClusters = mooncakeClustersV2;
    } else {
      delete alert.mooncakeKustoQuery
    }
    if(usQuery&&checkedNationalClouds.includes(NationalClouds.Fairfax)) {
      let fairfaxKustoQuery : KustoQueryEntry = {
        kustoQuery: usQuery,
        position: 0,
        title: form.getFieldValue(['fairfaxKustoQuery', 'title']),
        isGraphInReport: false
      }
      alert.fairfaxKustoQuery = fairfaxKustoQuery;
      let fairfaxClustersV2:KustoCluster[] = filterKustoCluster(form.getFieldValue('fairfaxclusters'));
      alert.fairfaxKustoClusters = fairfaxClustersV2;      
    } else {
      delete alert.fairfaxKustoQuery
    }
    if(ussecQuery&&checkedAirgapClouds.includes(AirgapClouds.USSec)) {
      let ussecKustoQuery : KustoQueryEntry = {
        kustoQuery: ussecQuery,
        position: 0,
        title: form.getFieldValue(['ussecKustoQuery', 'title']),
        isGraphInReport: false
      }
      alert.ussecKustoQuery = ussecKustoQuery;
      let ussecClustersV2:KustoCluster[] = filterKustoCluster(form.getFieldValue('ussecclusters'));
      alert.ussecKustoClusters = ussecClustersV2;      
    } else {
      delete alert.ussecKustoQuery
    }
    if(usnatQuery&&checkedAirgapClouds.includes(AirgapClouds.USNat)) {
      let usnatKustoQuery : KustoQueryEntry = {
        kustoQuery: usnatQuery,
        position: 0,
        title: form.getFieldValue(['usnatKustoQuery', 'title']),
        isGraphInReport: false
      }
      alert.usnatKustoQuery = usnatKustoQuery;
      let usnatClustersV2:KustoCluster[] = filterKustoCluster(form.getFieldValue('usnatclusters'));
      alert.usnatKustoClusters = usnatClustersV2;      
    } else {
      delete alert.usnatKustoQuery
    }
    alert['icm'] = undefined;
    alert['aliases'] = undefined;
    alert['machineAction'] = undefined;
    alert['isReportingMode'] = undefined;
    alert['mitigations'] = undefined;
    alert['nClouds'] = undefined;
    alert['airgapClouds'] = undefined;
    // Need to implement kusto viewer + tags as: https://ant.design/components/form/#components-form-demo-customized-form-controls

    // Only filter v2 clusters. If user does not select new dropdown clusters, kustoClusters will not be used.
    if(clusters.length>0 && !clusters[0].includes(';')){
      let clustersV2:KustoCluster[] = filterKustoCluster(clusters);
      alert.kustoClusters = clustersV2;
    }
    alert.clouds = form.getFieldValue('clouds')

    // If national clouds query exists, need to validate for machine action
    if(alert.mooncakeKustoQuery && checkedMitigations.includes('Machine Action')){
      message.info('Validating machine action for Mooncake',5)
      let res = await machineActionKusto(accounts, instance, cnQuery, DefaultMooncakeCluster, "");
      if (res.status===200){
        message.success('Validation successful')
      } else {
        message.error(`Validation error: ${res.data}`)
        return;
      }
    }
    if(alert.fairfaxKustoQuery && checkedMitigations.includes('Machine Action')){
      message.info('Validating machine action for Fairfax',5)
      let res = await machineActionKusto(accounts, instance, usQuery, DefaultFairfaxCluster, "");
      if (res.status===200){
        message.success('Validation successful')
      } else {
        message.error(`Validation error: ${res.data}`);
        return;
      }
    }

    // skip airgap cloud query validate since cannot access those clusters
    //Validate Geneva Action queries for NCloud
    if(alert.mooncakeKustoQuery && checkedMitigations.includes('Geneva Actions')){
      message.info('Validating Geneva action for Mooncake',5)
      validateNationalCloudsGenevaAction(NationalClouds.Mooncake);
    }
    if(alert.fairfaxKustoQuery && checkedMitigations.includes('Geneva Actions')){
      message.info('Validating Geneva action for Fairfax',5)
      validateNationalCloudsGenevaAction(NationalClouds.Fairfax);
    }

    // create or update alert
    if (submitMode==='save') {
      setSaveLoading(true);
    } else {
      setCreateLoading(true);
    }

    let isManuallApprovalAlert = checkedMitigations.includes('Geneva Actions') || checkboxOptions.includes('Machine Action') 
    || checkedValues.includes('Kusto Control Command') || (form.getFieldValue(['icm', 'severity'])<=2&&checkedValues.includes('ICM'))


    if (isNewAlert) {
      let resp = await createAlert(accounts, instance, alert);
      if (resp.status === 200) {
        if (!isManuallApprovalAlert) {
          message.success("New alert created");
        }else
        {
          message.warn("New alert created, but manual approval is required before it becomes active.", 10);
        }
        setRedirect(true);
      } else {
        message.error(`${resp.data}`, 8)
      }
    } else if (existingAlert?.id !== undefined) {
      //setRedirectUri(`/alert/${existingAlert.id}`);
      alert.approvalDocumentId = existingAlert.approvalDocumentId;
      alert.approvalDocumentUri = existingAlert.approvalDocumentUri;
      let resp = await editAlert(accounts, instance, alert, existingAlert.id);
      if (resp.status === 200) {
        if (!isManuallApprovalAlert) {
          message.success("Alert updated");
        }else{
          message.warn("Alert updated, but manual approval is required before your changes are synced.", 10);
        }
        if (submitMode==='submit'){
          setRedirect(true);
        } else {
          setEdit(false);
          window.location.reload();
        }
      } else {
        message.error(`${resp.data}`, 8)
      }
    }
    setSaveLoading(false);
    setCreateLoading(false);
  }

  const renderTimeType = () => (
    <Form.Item label="Schedule" name="timeType" rules={[{ required: true, message: 'Must specify a time' }]} style={{marginBottom:"0px"}}>
      <Radio.Group  optionType="button" buttonStyle="solid" disabled={isDisabled} 
        onChange={(e) => {
          if(e.target.value === "standard")
          {
            setIsStandard(true);
            setIsCron(false);
          }
          if(e.target.value === "cron")
          {
            setIsStandard(false);
            setIsCron(true);
          }
      }}>
        <Radio.Button value="standard">Standard</Radio.Button>
        <Radio.Button value="cron">Cronjob</Radio.Button>
      </Radio.Group>
    </Form.Item>
  )
  const isAutoMitigationHidden = () => {    
    if (serviceId==="10060" || serviceId === "1197" || serviceId === "58730"){
      return false;
    }
    return true;
  }

  const isSyncToAirgapHidden = () => {
    if (!checkedValues.includes('ICM')&&!checkedValues.includes('Kusto Control Command')&&!checkedMitigations.includes('Machine Action')&&!checkedMitigations.includes('Geneva Actions')){
      return true;
    }
    return false;
  }

  const isMooncakeHidden = () => {
    if(checkedNationalClouds.includes(NationalClouds.Mooncake)){
      return false
    }
    return true;
  }

  const isFairfaxHidden = () => {
    if(checkedNationalClouds.includes(NationalClouds.Fairfax)){
      return false
    }
    return true;
  }

  const isUssecHidden = () => {
    if (checkedAirgapClouds.includes(AirgapClouds.USSec)){
      return false
    }
    return true;
  }

  const isUsnatHidden = () => {
    if (checkedAirgapClouds.includes(AirgapClouds.USNat)){
      return false
    }
    return true;
  }

  const onValidateAction = async ()=> {
    setActionValidLoading(true);

    var svcId = (serviceId !== undefined && serviceId.length !== 0)? serviceId : String(existingAlert?.serviceId);

    let res = await machineActionKusto(accounts, instance, query, clusters, svcId);
    if (res.status===200){
      message.success('Validation successful')
    } else {
      message.error(`Validation error: ${res.data}`)
    }
    setActionValidLoading(false);
  }

  const validateNationalCloudsAction = async (cloud)=> {
    var tmpCluster : string[];
    var tmpQuery : string;
    if(cloud===NationalClouds.Mooncake){
     tmpCluster = mooncakedbclusteroverride;
     tmpQuery = cnQuery;
    } else if(cloud===NationalClouds.Fairfax){
      tmpCluster = fairfaxdbclusteroverride;
      tmpQuery = usQuery;
    } else { return; }
    let res = await machineActionKusto(accounts, instance, tmpQuery, tmpCluster, serviceId);
    if (res.status===200){
      message.success('Validation successful')
    } else {
      message.error(`Validation error: ${res.data}`)
    }
  }

  const validateNationalCloudsAdminCommand = async (cloud)=> {
    var tmpCluster : string[];
    var tmpQuery : string;
    if(cloud===NationalClouds.Mooncake){
     tmpCluster = mooncakedbclusteroverride;
     tmpQuery = cnQuery;
    } else if(cloud===NationalClouds.Fairfax){
      tmpCluster = fairfaxdbclusteroverride;
      tmpQuery = usQuery;
    } else { return; }
    let res = await runKustoQuery(accounts, instance, tmpQuery, tmpCluster, serviceId);
    if (res.status===200){
      message.success('Validation successful')
    } else {
      message.error(`Validation error: ${res.data}`)
    }
  }

  const getGenevaFormData = async (data:any)=> {
    setGenevaSaveLoading(true);
    setGenevaActionData(data);
    setGenevaSaveLoading(false);
  }

  const onSubmitClick = (val) => {
    if(checkedMitigations.includes('Geneva Actions') && (genevaValidationSucces === undefined || !genevaValidationSucces) ) {
      message.error(`Please validate Kusto query for Geneva action first.`, 8);
    }
    else{
      //setSubmitMode(val);
      if(form.getFieldValue(['icm', 'severity'])<=2&&checkedValues.includes('ICM')){
        setShowModal(true)
        setSubmitMode(submitMode=val);
      }else{
        setSubmitMode(submitMode=val);
        submitAlert();
      }
    }    
  }

  const hideModal = () => {
    setShowModal(false);
    submitAlert()
  }

  const submitAlert = () => {
    let tmpAlert: IAlert = form.getFieldsValue();
    onFinish(tmpAlert);
  }

  const cancelSubmit = () => {
    setShowModal(false);
    setSubmitDisabled(true);
  }

  const handleAckChecked = (val) => {
    if(val.target.checked)
    {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }

  const getGenevaNCloudValidationResult = (flag) => {
    setValidateGenevaNCloudQuery(0);
  }
  const validateNationalCloudsGenevaAction = async (cloud)=> {
    if(cloud===NationalClouds.Mooncake){
        setValidateGenevaNCloudQuery(NationalClouds.Mooncake);
     } else if(cloud===NationalClouds.Fairfax){
        setValidateGenevaNCloudQuery(NationalClouds.Fairfax);
     }     
  }

  const styling:CSSProperties = {width:"92%"}
  return (
    <>
      <Form
        form={form}
        style={styling}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        //onFinish={onFinish}
        initialValues={{remember:true}}
      >       
        <Space direction="vertical" size="large" style={styling}>
          <h3>Alert Information</h3>
            <Form.Item name="serviceName" label="Service Name" required={true}>
              <Select disabled={editingDisabled} loading={serviceLoading} options={services} optionFilterProp="label" showSearch={true} onSelect={onServiceChange}>
              </Select>
            </Form.Item>
            <Form.Item name="title" label="Alert Title" rules={[{ required: true, message: 'Title is required' }]}>
              <Input disabled={isDisabled}/>
            </Form.Item>
            {/* <Form.Item label="Description"> */}
              {/* <Input.TextArea disabled={isDisabled} rows={10} /> */}
              <RichTextEditor value={richDes} setValue={setRichDes} readonly={isDisabled} placeHolder='Put your TSG or alert description here.'/>
            {/* </Form.Item> */}
            <Form.Item name="alertApprovers" label={CustomizedTooltip("Required PR Approvers ", 
              <div>
                <p>Alias or mail of AAD User or Group, who will be required reviewers to approve PR of this alert. Multi AAD users or groups are separated by (;).</p>
                <p>Note: PR won't get merged until each of the list complete approval.</p>
              </div>)} rules={[{ required: false, message: 'Specified PR Approvers are optional.' }]}>
              <Input disabled={isDisabled} placeholder="Optional. This is to specify [required PR reviewers] to approve changes of this alert."/>
            </Form.Item>
          <h3>Scheduling and Data Sources</h3>
            <Form.Item label={CustomizedTooltip("Kusto Cluster ", 
              <div>
                <p>Option format: [cloud]-[cluster]-[database]</p>
                <p>Please use All option with caution.</p>
                <p><Link to="/faq#kustoCluster" style={{color:"cyan"}}>More details.</Link></p>
              </div>)} required>
              <Select listItemHeight={10} listHeight={500} disabled={isDisabled} mode="multiple" options={cloudOpts} value={clusters} showSearch optionFilterProp="label" onChange={onCloudsChange}/>
            </Form.Item>
            <Form.Item label={<div><Tooltip title="Will convert to lower case">Tags</Tooltip></div>}>
              <EditableTagGroup readonly={isDisabled} tags={tags} setTags={setTags} item="Tag"/>
            </Form.Item>
            <Form.Item label="Minimum Hit Count" name="minimumHitCount" rules={[{ required: true, message: 'Minimum hit count is required' }]}>
                <InputNumber type="number" min={0} max={100} disabled={isDisabled}/>
            </Form.Item>
            {renderTimeType()}
            <StandardTimeFormItem active={isStandard} disabled={isDisabled} defaultValue={existingAlert?.standardTime}/>
            {/* <CustomTimeFormItem active={isCustom} disabled={isDisabled} defaultValue={existingAlert?.CustomTimes} /> */}
            <CronTime active={isCron} readonly={isDisabled} form={form}/>
          <h3>Primary Query</h3>
            {/* https://ant.design/components/form/#components-form-demo-without-form-create -> use this to verify the kusto query */}
            <KustoQueryWrapper serviceId={(serviceId !== undefined && serviceId.length !== 0)?serviceId:String(existingAlert?.serviceId)} accounts={accounts} instance={instance} disabled={isDisabled} query={query} setQuery={setQuery} clusters={clusters}/>
          <Row>
            <Col span={12}><h3>Secondary Query</h3></Col>
            <Col span={12}>
              <Row style={{float: 'right'}}>
                <Switch onChange={(checked, e) => setCompactMode(checked)} disabled={isDisabled}></Switch><Text style={{marginLeft: "8px"}}>
                  {CustomizedTooltip("Compact Mode", 
                  <div>
                    <p>In compact mode, your secondary kusto queries will be displayed in table view. You can easily resort your queries by dragging. </p>
                    <p>It would be helpful if you want to reorder the graphs in the alert email report.</p>
                  </div>
              )}</Text>
              </Row>
            </Col>
          </Row>
          <DynamicKustos serviceId={(serviceId !== undefined && serviceId.length !== 0)?serviceId:String(existingAlert?.serviceId)} accounts={accounts} instance={instance} formField="secondaryKustoQueries" form={form} disabled={isDisabled} queries={queries} setQueries={setQueries} clusters={clusters} compactMode={compactMode}></DynamicKustos>
          
          <h3>Actions</h3>
            <Form.Item label="Actions" name="actions">
              <Checkbox.Group options={checkboxOptions} onChange={onActionChange} disabled={isDisabled} />
            </Form.Item>
            <EmailFormItem active={checkedValues.includes('Email')} readOnly={isDisabled}/>
            <ICMFormItem active={checkedValues.includes('ICM')} icmOpts={icms} readonly={isDisabled} loading={icmLoading}/>
            <WorkItemComponent active={checkedValues.includes('Work Item')} projectName={devopsProj} readonly={isDisabled}/>
            <ControlCommandFormItem active={checkedValues.includes('Kusto Control Command')} accounts={accounts} instance={instance} serviceId={(serviceId !== undefined && serviceId.length !== 0)?serviceId:String(existingAlert?.serviceId)} form={form} disabled={isDisabled} command={command} setCommand={setCommand} clusters={clusters}/>
            <Form.Item label="Auto Mitigation" hidden={false}>
              <Space >
                <Form.Item noStyle name="mitigations">                  
                  <Checkbox.Group options={mitigationOptions} value={checkedMitigations} disabled={isDisabled} onChange={onMitigationChange} />                                    
                </Form.Item>                              
              </Space>            
            </Form.Item>
            <Form.Item noStyle hidden={isAutoMitigationHidden() || !checkedMitigations.includes('Machine Action')}>
                  <Button style={{top:"4px"}} key="validationButton" type="primary" size="small" disabled={isDisabled||!checkedMitigations.includes('Machine Action')} loading={actionValidLoading} onClick={onValidateAction}>Validate Machine Action Query</Button>
            </Form.Item>  
            <GenevaActionsFormItem  validateNCloudQuery={validateGenevaNCloudQuery} onValidateNCloudQuery={getGenevaNCloudValidationResult} MooncakeQuery={!isMooncakeHidden()?cnQuery:""} FairfaxQuery={!isFairfaxHidden()?usQuery:""} MooncakeClusters={!isMooncakeHidden()?mooncakedbclusteroverride:[""]} FairfaxClusters={!isFairfaxHidden()?fairfaxdbclusteroverride:[""]} dSTSEnabled={genevadSTSAuthEnabled} serviceId={(serviceId !== undefined && serviceId.length !== 0)?serviceId:String(existingAlert?.serviceId)} extensionOptions={genevaExtensionOptions} restrictedOperations={genevaRestrictedOperations} onValidationSuccess={setGenevaValidationSucces} kustoQuery={query} kustoClusters={clusters} existingRequest={existingGenevaRequest} genevaSaveLoading={genevaSaveLoading} getGenevaData={getGenevaFormData} active={checkedMitigations.includes('Geneva Actions')} isDisabled={isDisabled}/>
            <MachineAction active={!isAutoMitigationHidden() && checkedMitigations.includes('Machine Action')} readonly={isDisabled}/>

            <Form.Item name="nClouds" label={CustomizedTooltip("Sync to national clouds", 
                <div>
                  <p>WawsAlertingV2 will help transfer alert to national clouds in fixed schedule.</p>
                  <p>Make sure not contain any keyword in query that cannot work in national cloud, such as cluster('wawscus')</p>
                  <p><Link to="/faq#nationalclouds" style={{color:"cyan"}}>For more detail, go to FAQ.</Link></p>
                  </div>)} hidden={!checkedMitigations.includes('Machine Action')&&!checkedMitigations.includes('Geneva Actions')&&!checkedValues.includes('Kusto Control Command')}>
              <Checkbox.Group options={nationalCloudsOptions} onChange={onSelectNationalClouds} disabled={isDisabled} />
            </Form.Item>
            <Form.Item noStyle hidden={isMooncakeHidden()}>
              <h3>Mooncake</h3>

              <Form.Item name="mooncakeclusters" label={CustomizedTooltip("Mooncake Kusto Cluster",
                <div>
                  <p>Option format: [cloud]-[cluster]-[database]</p>
                </div>)} required>
                <Select listItemHeight={10} listHeight={500} disabled={isDisabled} mode="multiple" options={mooncakeCloudOpts} value={mooncakedbclusteroverride} showSearch optionFilterProp="label" onChange={onMooncakeCloudsChange} />
              </Form.Item>

              <KustoQueryCommon serviceId={(serviceId !== undefined && serviceId.length !== 0)?serviceId:String(existingAlert?.serviceId)} accounts={accounts} instance={instance} disabled={isDisabled} clusters={mooncakedbclusteroverride} query={cnQuery} setQuery={setCnQuery} formName={['mooncakeKustoQuery', 'title']} />
              <Form.Item label="Validate Mooncake Query" hidden={isMooncakeHidden()}>
                <Button hidden={!checkedMitigations.includes('Machine Action')} style={{top:"4px", right:"4px"}} key="validationButtonMooncake" type="primary" size="small" disabled={cnQuery===''} loading={actionValidLoading} onClick={()=>validateNationalCloudsAction(NationalClouds.Mooncake)}>Validate Mooncake Machine Action Query</Button>
                { checkedMitigations.includes('Machine Action')? <span>&nbsp;</span> : "" }
                <Button hidden={!checkedMitigations.includes('Geneva Actions')} style={{top:"4px", right:"4px"}} key="validationGenevaMooncake" type="primary" size="small" disabled={cnQuery===''} loading={actionValidLoading} onClick={()=>validateNationalCloudsGenevaAction(NationalClouds.Mooncake)}>Validate Mooncake Geneva Actions Query</Button>
                { checkedMitigations.includes('Geneva Actions')? <span>&nbsp;</span> : "" }
                <Button hidden={!checkedValues.includes('Kusto Control Command')} style={{top:"4px", right:"4px"}} key="validationControlCommandMooncake" type="primary" size="small" disabled={cnQuery===''} loading={actionValidLoading} onClick={()=>validateNationalCloudsAdminCommand(NationalClouds.Mooncake)}>Validate Mooncake Control Command</Button>
              </Form.Item>
            </Form.Item>
            <Form.Item noStyle hidden={isFairfaxHidden()}>
              <h3>Fairfax</h3>
              <Form.Item name="fairfaxclusters" label={CustomizedTooltip("Fairfax Kusto Cluster",
                <div>
                  <p>Option format: [cloud]-[cluster]-[database]</p>
                </div>)} required>
                <Select listItemHeight={10} listHeight={500} disabled={isDisabled} mode="multiple" options={fairfaxCloudOpts} value={fairfaxdbclusteroverride} showSearch optionFilterProp="label" onChange={onFairfaxCloudsChange} />
              </Form.Item>
              <KustoQueryCommon serviceId={(serviceId !== undefined && serviceId.length !== 0)?serviceId:String(existingAlert?.serviceId)} accounts={accounts} instance={instance} disabled={isDisabled} clusters={fairfaxdbclusteroverride} query={usQuery} setQuery={setUsQuery} formName={['fairfaxKustoQuery', 'title']} />  
              <Form.Item label="Validate Fairfax Query" hidden={isFairfaxHidden()}>
                <Button hidden={!checkedMitigations.includes('Machine Action')} style={{top:"4px", right:"4px"}} key="validationButtonFairfax" type="primary" size="small" loading={actionValidLoading} onClick={()=>validateNationalCloudsAction(NationalClouds.Fairfax)}>Validate Fairfax Machine Action Query</Button>
                { checkedMitigations.includes('Machine Action')? <span>&nbsp;</span> : "" }
                <Button hidden={!checkedMitigations.includes('Geneva Actions')} style={{top:"4px", right:"4px"}} key="validationGenevaFairfax" type="primary" size="small" loading={actionValidLoading} onClick={()=>validateNationalCloudsGenevaAction(NationalClouds.Fairfax)}>Validate Fairfax Geneva Actions Query</Button>
                { checkedMitigations.includes('Geneva Actions')? <span>&nbsp;</span> : "" }
                <Button hidden={!checkedValues.includes('Kusto Control Command')} style={{top:"4px", right:"4px"}} key="validationControlCommandFairfax" type="primary" size="small" loading={actionValidLoading} onClick={()=>validateNationalCloudsAdminCommand(NationalClouds.Fairfax)}>Validate Fairfax Control Command</Button>
              </Form.Item>
            </Form.Item>
            <Form.Item name="airgapClouds" label={CustomizedTooltip("Sync to airgap clouds", 
                <div>
                  <p>WawsAlertingV2 will help transfer alert to airgap clouds in fixed schedule.</p>
                  <p>Make sure not contain any keyword in query that cannot work in airgap cloud, such as cluster('wawscus')</p>
                  <p><Link to="/faq#nationalclouds" style={{color:"cyan"}}>For more detail, go to FAQ.</Link></p>
                  </div>)} hidden={isSyncToAirgapHidden()} style={{marginTop:!checkedMitigations.includes('Machine Action')&&!checkedMitigations.includes('Geneva Actions')&&!checkedValues.includes('Kusto Control Command') ? "-10rem" : "0rem"}}>
              <Checkbox.Group options={airgapCloudsOptions} onChange={onSelectAirgapClouds} disabled={isDisabled} />
            </Form.Item>
            <Form.Item noStyle hidden={isUssecHidden()}>
              <h3>USSec</h3>
              <Form.Item name="ussecclusters" label={CustomizedTooltip("USSec Kusto Cluster",
                <div>
                  <p>Option format: [cloud]-[cluster]-[database]</p>
                </div>)} required>
                <Select listItemHeight={10} listHeight={500} disabled={isDisabled} mode="multiple" options={ussecCloudOpts} value={ussecdbclusteroverride} showSearch optionFilterProp="label" onChange={onUssecCloudsChange} />
              </Form.Item>
              <KustoQueryCommon serviceId={(serviceId !== undefined && serviceId.length !== 0)?serviceId:String(existingAlert?.serviceId)} accounts={accounts} instance={instance} disabled={isDisabled} clusters={ussecdbclusteroverride} query={ussecQuery} setQuery={setUssecQuery} formName={['ussecKustoQuery', 'title']} />  
              <h3>Secondary USSec Query</h3>
              <DynamicKustos serviceId={(serviceId !== undefined && serviceId.length !== 0)?serviceId:String(existingAlert?.serviceId)} accounts={accounts} instance={instance} formField="ussecSecondaryKustoQueries" form={form} disabled={isDisabled} queries={ussecSecondaryQueries} setQueries={setUssecSecondaryQueries} clusters={ussecdbclusteroverride}></DynamicKustos>
            </Form.Item>
            <Form.Item noStyle hidden={isUsnatHidden()}>
              <h3>USNat</h3>
              <Form.Item name="usnatclusters" label={CustomizedTooltip("USNat Kusto Cluster",
                <div>
                  <p>Option format: [cloud]-[cluster]-[database]</p>
                </div>)} required>
                <Select listItemHeight={10} listHeight={500} disabled={isDisabled} mode="multiple" options={usnatCloudOpts} value={usnatdbclusteroverride} showSearch optionFilterProp="label" onChange={onUsnatCloudsChange} />
              </Form.Item>
              <KustoQueryCommon serviceId={(serviceId !== undefined && serviceId.length !== 0)?serviceId:String(existingAlert?.serviceId)} accounts={accounts} instance={instance} disabled={isDisabled} clusters={usnatdbclusteroverride} query={usnatQuery} setQuery={setUsnatQuery} formName={['usnatKustoQuery', 'title']} />  
              <h3>Secondary USNat Query</h3>
              <DynamicKustos serviceId={(serviceId !== undefined && serviceId.length !== 0)?serviceId:String(existingAlert?.serviceId)} accounts={accounts} instance={instance} formField="usnatSecondaryKustoQueries" form={form} disabled={isDisabled} queries={usnatSecondaryQueries} setQueries={setUsnatSecondaryQueries} clusters={usnatdbclusteroverride}></DynamicKustos>
            </Form.Item>
        </Space>
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button type="primary" htmlType="button" title='submit and go back alert list' style={{float: 'inline-start'}} loading={creatLoading} disabled={isDisabled||saveLoading} onClick={()=>{onSubmitClick('submit')}}>
            Submit and go back
          </Button>
          <Modal visible={showModal} destroyOnClose closable={false}
            footer={[
            <Button key="confirm" type="primary" htmlType="submit" onClick={hideModal} disabled={submitDisabled}>Submit</Button>,
            <Button key="cancel" onClick={cancelSubmit}>Cancel</Button>]}>
            {acknowledgment}
            <Checkbox onChange={(val)=>handleAckChecked(val)}>{<div>I acknowledge I am aware of above guidelines.</div>}</Checkbox>
          </Modal>
          <Button hidden={isNewAlert} type="primary" title='save and stay current page' htmlType='button' style={{marginLeft:'5px'}} loading={saveLoading} disabled={isDisabled||creatLoading} onClick={()=>{onSubmitClick('save')}}>Save</Button>
        </Form.Item>
        <BackTop style={{marginBottom:'5%', marginRight:'20px'}}><UpCircleOutlined style={{fontSize: '400%', color:'#3F51B5'}}/></BackTop>
      </Form>
    </>
  );
};
export default AlertForm