import React, { useState, useEffect } from 'react';
import { 
    Form,
    Input,
    Select
} from 'antd';
import { useMsal } from '@azure/msal-react';
import { fetchAreasIterations } from '@utils/helpers';
type WorkItemProp = {
    active: boolean;
    projectName: string;
    readonly: boolean;
}

const WorkItemComponent : React.FC<WorkItemProp> = ({active, projectName, readonly}) => {
    const [areaPaths, setAreaPaths] = useState<any>([]);
    const [iterationPaths, setIterationPaths] = useState<any>([]);
    const {accounts, instance} = useMsal();
    const fetchAreas = async() => {
        let data = await fetchAreasIterations(projectName, accounts, instance);
        let tmpAreas = data.AreaPaths.map(item=>({label: item, value: item}));
        let tmpIterations = data.IterationPaths.map(item=>({label: item, value: item}));
        setAreaPaths(tmpAreas);
        setIterationPaths(tmpIterations);
        return data;
    }

    useEffect(() => {   
        fetchAreas();
    }, [])
    const renderWorkItem = () => {
        if (active) {
            return (
                <Form.Item noStyle>
                    
                    <Input.Group compact />

                    <Form.Item label="Assigned to" name={['workItem', 'AssignedTo']}>
                        <Input placeholder='Input an email address' disabled={readonly}/>
                    </Form.Item>
                    <Form.Item label="Title" name={['workItem', 'Title']}>
                        <Input placeholder='work item title' disabled={readonly}/>
                    </Form.Item>
                    <Form.Item label="Area" name={['workItem', 'AreaPath']}>
                        <Select options={areaPaths} showSearch={true} disabled={readonly}/>
                    </Form.Item>
                    <Form.Item label="Iteration" name={['workItem', 'IterationPath']}>
                        <Select options={iterationPaths} showSearch={true} disabled={readonly}/>
                    </Form.Item>
                </Form.Item>                
            )
        } else {
            return (<></>)
        }
    }
    return renderWorkItem();
}

export default WorkItemComponent;