import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Card, Select, Input, Button, Table, Modal, message, Popconfirm, Tooltip, Spin, Popover, Switch } from 'antd';
import { fetchServiceById, fetchServices, updateService, fetchKustosByServiceId, fetchServiceIcm, fetchServiceIcmCertUrl, OnboardProductService, getGenevaActionExtensions } from '@utils/helpers';
import { useMsal } from '@azure/msal-react';
import KustoForm from "./KustoForm"
import { useHistory, useParams } from 'react-router';
import EditableTagGroup from "@components/EditableTag";
import RichTextEditor from '@components/AlertForm/RichTextEditor';
import GenevaActionsRBAC from '../GenevaActionsForm/GenevaActionsRBAC';

type AdminsProps = {
    active: boolean;
    data: any;
};

type DescriptionProps = {
    active: boolean;
    onboarded: boolean;
    data: any;
};

const DescriptionFormItem: React.FC<DescriptionProps> = ({ active, onboarded, data }) => {
    //console.log(data.description);
    const { accounts, instance } = useMsal();

    async function confirm(e) {
        console.log(e);
        var res = await OnboardProductService(accounts, instance, data.id)
        if (res.status === 200) {
            message.success('Request was sent successfully and is pending for approval.');
        }
        else {
            message.error('Request failed, please contact the WAWS Alert Team.');
        }
    }

    function cancel(e) {
        console.log('Click on No');
    }

    if (active) {
        var onboardName = "onboardinput" + data.id;
        var descriptionName = "descriptioninput" + data.id;
        if (onboarded) {
            return (
                <><Form.Item label="Service Description" name={descriptionName} >
                    <Input.TextArea defaultValue={data.description} rows={10} />
                </Form.Item><Form.Item name={onboardName} label="Service Onboarded">
                        <Input defaultValue="Onboarded" disabled={true} />
                    </Form.Item></>
            );
        }
        else {
            return (
                <><Form.Item label="Service Description" name={descriptionName}>
                    <Input.TextArea defaultValue={data.description} rows={10} />
                </Form.Item><Form.Item label="Service Onboarded">
                        <Input defaultValue="Not Onboarded" style={{ width: "85%", marginRight: "5px" }} disabled={true} />
                        {/*<Button style={{ marginLeft: "5px" }} onClick={OnClick} >Request</Button>*/}
                        <Popconfirm
                            title="Are you sure to request onboarding the service?"
                            onConfirm={confirm}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#" style={{ color: "red" }} >Click to Request.</a>
                        </Popconfirm>
                    </Form.Item></>
            );
        }
    } else {
        return (<div></div>)
    }
}

type AlertRuleProps = {
    active: boolean;
    id: string;
    richDes: string;
    setRichDes: any;
}
const AlertRuleSetting: React.FC<AlertRuleProps> = ({ active, id, richDes, setRichDes }) => {
    const { accounts, instance } = useMsal();
    const updateAlertDescription = async (description: string) => {
        await updateService(accounts, instance, id, description, "alertDefaultDescription");
        message.success('Alert default description updated');
    }
    if (active) {
        return (
            <Form.Item label={<div><Tooltip title="Template for alert description, TSG, etc.">Alert Default Description <QuestionCircleOutlined /></Tooltip></div>}>
                <RichTextEditor value={richDes} setValue={setRichDes} readonly={false} labelVal='' placeHolder='Put alert default description or TSG here.' />
                <Button disabled={false} onClick={() => { updateAlertDescription(richDes) }}>Update</Button>
            </Form.Item>
        )
    } else {
        return (<></>)
    }
}

const AdminsFormItem: React.FC<AdminsProps> = ({ active, data }) => {
    const [approvers, SetApprovers] = useState('');
    const [owners, SetOwners] = useState('');
    const [allowedAadGroups, SetAllowedAadGroups] = useState('');
    const [isLoading, SetLoading] = useState(false);
    const [isOwnersLoading, SetOwnersLoading] = useState(false);
    const [isAadGroupsLoading, SetAadGroupsLoading] = useState(false);
    const { accounts, instance } = useMsal();
    let email = accounts[0].username;

    const OnClick = async () => {
        //alert(approvers);
        if (data.owners.includes(email)) {
            if (approvers === 'undefined' || !approvers || !/[^\s]/.test(approvers)) {
                //为空
                message.error("Invalid change to update.");
            } else {
                SetLoading(true);
                var result = await updateService(accounts, instance, data.id, approvers, "approvers");
                if (result.status === 200) {
                    message.success("Approver list was updated.");
                    SetLoading(false);
                    setTimeout(function () { window.location.reload(); }, 10);
                }
                else {
                    message.error("Failed " + result.data);
                    SetLoading(false);
                }
            }
        }
        else {
            message.error("Permission insufficient or invalid change.");
        }
    }

    const OnOwnersClick = async () => {
        if (data.owners.includes(email)) {
            if (owners === 'undefined' || !owners || !/[^\s]/.test(owners)) {
                //为空
                message.error("Invalid change to update.");
            } else {
                SetOwnersLoading(true);
                var result = await updateService(accounts, instance, data.id, owners, "owners");
                if (result.status === 200) {
                    message.success("Owners list was updated.");
                    SetOwnersLoading(false);
                    setTimeout(function () { window.location.reload(); }, 10);
                }
                else {
                    message.error("Failed " + result.data);
                    SetOwnersLoading(false);
                }
            }
        }
        else {
            message.error("Permission insufficient or invalid change.");
        }
    }

    const OnAllowedGroupsClick = async () => {
        if (data.owners.includes(email)) {
            if (allowedAadGroups === 'undefined' || !allowedAadGroups || !/[^\s]/.test(allowedAadGroups)) {
                //为空
                message.error("Invalid change to update.");
            } else {
                SetAadGroupsLoading(true);
                var result = await updateService(accounts, instance, data.id, allowedAadGroups, "allowedaadgroups");
                if (result.status === 200) {
                    message.success("Allowed AAD group list was updated.");
                    SetAadGroupsLoading(false);
                    setTimeout(function () { window.location.reload(); }, 10);
                }
                else {
                    message.error("Failed: " + result.data);
                    SetAadGroupsLoading(false);
                }
            }
        }
        else {
            message.error("Permission insufficient or invalid change.");
        }
    }

    const OnOwnersChange = (event) => {
        console.log(event.target.value);
        SetOwners(event.target.value);
    }

    const OnChange = (event) => {
        console.log(event.target.value);
        SetApprovers(event.target.value);
    }

    const OnAllowedAadGroupsChange = (event) => {
        console.log(event.target.value);
        SetAllowedAadGroups(event.target.value);
    }

    if (active) {
        console.log(data.description);
        var descriptionName = "description" + data.id;
        var ownersName = "owners" + data.id;
        var approversName = "approvers" + data.id;
        var allowedAadGroupName = "allowedAadGroupNames" + data.id;

        return (
            <><Form.Item name={ownersName} label={<div><Tooltip title="Mail of AAD User, who will be owner to manage the service profile here. Multi AAD users should be separated by (;)">Service Owners<QuestionCircleOutlined /></Tooltip></div>}>
                {isOwnersLoading ? <Spin /> : <Input.TextArea defaultValue={data.owners} style={{ width: "85%" }} onChange={event => OnOwnersChange(event)} />}
                <Button style={{ marginLeft: "5px" }} onClick={OnOwnersClick}>Update</Button>
            </Form.Item>
                <Form.Item name={allowedAadGroupName} label={<div><Tooltip title="Mail of AAD Groups, whose members will be allowed to create alerts under this service. Multi AAD group mails should be separated by ';' . 'all' to allow everyone, 'none' to allow none. ">Allowed AAD Groups <QuestionCircleOutlined /></Tooltip></div>}>
                    {isAadGroupsLoading ? <Spin /> : <Input.TextArea defaultValue={data.allowedAadGroups} style={{ width: "85%" }} onChange={event => OnAllowedAadGroupsChange(event)} />}
                    <Button style={{ marginLeft: "5px" }} onClick={OnAllowedGroupsClick}>Update</Button>
                </Form.Item>
                <Form.Item name={approversName} label={<div><Tooltip title="Mail of AAD User or Group, each of whom will be must reviewers to approve alert PR. Multi AAD users or groups should be separated by (;). 'none' to set empty.">Service Alerting Approvers <QuestionCircleOutlined /></Tooltip></div>}>
                    {isLoading ? <Spin /> : <Input.TextArea defaultValue={data.approvers} style={{ width: "85%" }} onChange={event => OnChange(event)} />}
                    <Button style={{ marginLeft: "5px" }} onClick={OnClick}>Update</Button>
                </Form.Item></>
        )
    } else {
        return (<div></div>);
    }
}

const icmColumns = [
    {
        title: 'TeamId',
        dataIndex: 'teamid',
        key: 'teamid',
    },
    {
        title: 'TeamName',
        dataIndex: 'teamname',
        key: 'teamname',
        onCell: () => {
            return {
                style: {
                    maxWidth: 150
                }
            }
        },
        render: (text) => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
    },
    {
        title: 'RoutingId',
        dataIndex: 'routingid',
        key: 'routingid',
        onCell: () => {
            return {
                style: {
                    maxWidth: 150
                }
            }
        },
        render: (text) => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
    },
    {
        title: "Detail",
        dataIndex: "detail",
        key: "detail",
        render: (title: string) => <a href={`https://prod.microsofticm.com/api/user/oncall/teams(${title})?$expand=Tenant`} target={"_blank"} rel="noopener noreferrer">Detail</a>,
    }
];
const IcmFormItem: React.FC<AdminsProps> = ({ active, data }) => {
    const { accounts, instance } = useMsal();
    const [icmConnectorId, SetIcmConnectorId] = useState('');
    const [icmTeams, setIcmTeams] = useState<any>([]);
    const [isLoading, SetLoading] = useState(false);
    const [icmCertUrl, SetIcmCertUrl] = useState('');
    let { id } = useParams<IdParams>();

    let email = accounts[0].username;

    const fetchIcmData = async () => {
        if (id) {
            let icmData = await fetchServiceIcm(accounts, instance, id);
            let tmpData = icmData.teamRules.map(item => ({ teamid: item.teamId, teamname: item.teamName, routingid: item.routingId, detail: item.teamId }))
            setIcmTeams(tmpData);
        }
    }

    const fetchIcmCertUrl = async () => {
        if (id) {
            let url = await fetchServiceIcmCertUrl(accounts, instance);
            SetIcmCertUrl(url);
        }
    }



    useEffect(() => {
        console.log("useeffect for icm teams loading.");
        fetchIcmData();
        fetchIcmCertUrl();
    }, [])

    const OnClick = async () => {
        //alert(icmConnectorId);
        if (data.owners.includes(email)) {
            if (icmConnectorId === 'undefined' || !icmConnectorId || !/[^\s]/.test(icmConnectorId)) {
                //为空
                message.error("Invalid change to update.");
            } else {
                SetLoading(true);
                await updateService(accounts, instance, data.id, icmConnectorId, "icmconnectorid");
                message.success("ICM connetor Id was updated.");
                SetLoading(false);
                setTimeout(function () { window.location.reload(); }, 100);
            }
        }
        else {
            message.error("Permission insufficient or invalid change.");
        }

    }

    const OnChange = (event) => {
        console.log(event.target.value);
        SetIcmConnectorId(event.target.value);
    }

    const content = (
        <div>
            You need to add SAN AntMonFun-icmcon.waws.monitoring.appservice.compute.ce.azure.net to your ICM connector as <a href="https://icmdocs.azurewebsites.net/developers/Connectors/ConnectorOnboarding.html" target="_blank" rel="noopener noreferrer">ICM connector setup guide</a> before updating connector Id here.
        </div>
    );

    if (active) {
        console.log(data.description);
        return (
            <><Popover content={content} title="Prepare ICM connector" placement="topLeft"><Form.Item label="ICM Connector Id:">
                {isLoading ? <Spin /> : <Input defaultValue={data.icmconnectorid} style={{ width: "85%" }} onChange={event => OnChange(event)} />}
                <Button style={{ marginLeft: "5px" }} onClick={OnClick}>Update</Button>
            </Form.Item></Popover>
                <Form.Item label="ICM Teams">
                    <Table dataSource={icmTeams} columns={icmColumns} pagination={false} />
                </Form.Item></>
        )
    } else {
        return (<div></div>);
    }
}
const KustosFormItem: React.FC<AdminsProps> = ({ active, data }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { accounts, instance } = useMsal();
    const [kustos, setKustos] = useState<any>([]);

    let { id } = useParams<IdParams>();

    const kustoColumns = [
        {
            title: 'Cloud',
            dataIndex: 'cloud',
            key: 'cloud',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Cluster',
            dataIndex: 'cluster',
            key: 'cluster',
        },
        {
            title: 'Database',
            dataIndex: 'database',
            key: 'database',
        },
        {
            title: 'Action', dataIndex: '', key: 'action', render: function (text, record, index) {
                //alert(text.operation + " " + record.operation)
                return (
                    <Popconfirm title="Are you sure to delete the kusto cluster?" okText="Yes" cancelText="No" onConfirm={async () => {
                        await updateService(accounts, instance, id, text.description, "deletekusto");
                        message.success("kusto cluster was removed successfully.");
                        setTimeout(function () { window.location.reload(); }, 1000);

                    }} >
                        <a style={{ color: "red" }} >Delete</a>
                    </Popconfirm>
                )
            }
        },
    ];

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const fetchKustoData = async () => {
        if (id) {
            console.log(id);
            let kustoData = await fetchKustosByServiceId(accounts, instance, id);
            setKustos(kustoData);
        }
    }

    useEffect(() => {
        console.log("useeffect for kusto loading.");
        fetchKustoData();
    }, [])


    if (active) {
        console.log(data.description);

        const content = (
            <div>
                Public Azure: Run the command on your gloabl Azure kusto cluster to grant access: .add database [dbname] viewers ('aadapp=c96c8405-70e9-417a-9ca2-3d8058a162e3;33e01921-4d64-4f8c-a055-5bdaffd5e33d') 'Azure Alerting Access'
                <br></br>
                Mooncake: Run the command on your China Azure kusto cluster to grant access: .add database [dbname] viewers ('aadapp=57082e6a-7cf4-4534-9909-8efbcfc59fb9;a55a4d5b-9241-49b1-b4ff-befa8db00269') 'Azure Alerting Access'
                <br></br>
                Fairfax: Run the command on your Fairfax Azure kusto cluster to grant access: .add database [dbname] viewers ('aadapp=8f66e562-88b6-4119-bc4b-633a7eea553e;cab8a31a-1906-4287-a0d8-4eef66b95f6e') 'Azure Alerting Access'
            </div>
        );

        return (
            <><><Form.Item label="Kusto Clusters">
                <Popover content={content} title="Prepare Kusto access" placement="topLeft"><Button style={{ marginLeft: "2px" }} onClick={showModal}>+</Button></Popover>
                <Table dataSource={kustos} columns={kustoColumns} pagination={false} />
            </Form.Item></>
                <Modal title="Add Kusto" visible={isModalVisible} onCancel={handleCancel} footer={null} >
                    <KustoForm serviceId={data.id} accounts={accounts} instance={instance} />
                </Modal></>
        )
    } else {
        return (<div></div>);
    }
}
type genevaProps = {
    id: string;
    extensionOpts: any[];
    genevaExts: any[];
    setGenevaExts: any;
    genevaRestrictedOpts: string[];
    setGenevaRestrictedOpts: any;
    active: boolean;
}
const GenevaExtension: React.FC<genevaProps> = ({ id, extensionOpts, genevaExts, setGenevaExts, genevaRestrictedOpts, setGenevaRestrictedOpts, active }) => {
    const { accounts, instance } = useMsal();
    const [genevaExtLoading, setGenevaExtLoading] = useState(false);
    const [genevaRestrictedLoading, setGenevaRestrictedLoading] = useState(false);

    if (active) {
        const onSelectExtension = (val) => {
            setGenevaExts(val);
        }
        const onUpdateExts = async () => {
            setGenevaExtLoading(true);
            let resp = await updateService(accounts, instance, id, genevaExts.toString(), "genevaextensions");
            if (resp.status === 200) {
                message.success("Successfully updated geneval extensions")
            } else {
                message.error('Failed to update geneva extensions')
            }
            setGenevaExtLoading(false);
        }
        const onUpdateRestrictedOpts = async () => {
            setGenevaRestrictedLoading(true);
            console.log(genevaRestrictedOpts.toString());
            let resp = await updateService(accounts, instance, id, genevaRestrictedOpts.toString(), "genevarestrictedoperations");
            if (resp.status === 200) {
                message.success("Successfully updated geneval restricted operations")
            } else {
                message.error('Failed to update restricted operations')
            }
            setGenevaRestrictedLoading(false);
        }
        return (
            <div>
                <Form.Item name="enableAMEApproval" label={<div><Tooltip title="All geneva alerts under your service will require AME approval.">Enable Geneva AME Approval <QuestionCircleOutlined /></Tooltip></div>}><Switch disabled checked={id==="10060"} /></Form.Item>
                <Form.Item name="genevaExtensions" label={<div><Tooltip title="Select Geneva Extensions for auto mitigation.">Geneva Extensions <QuestionCircleOutlined /></Tooltip></div>}>
                    <Select options={extensionOpts} value={genevaExts} mode="multiple" showSearch optionFilterProp='label' onChange={(val) => onSelectExtension(val)}></Select>
                    <Button loading={genevaExtLoading} onClick={onUpdateExts}>Update</Button>
                </Form.Item>
                <Form.Item label={<div><Tooltip title="Operations added here are restricted and will not be listed in Geneva action form.">Restricted Operations <QuestionCircleOutlined /></Tooltip></div>}>
                    <EditableTagGroup readonly={false} tags={genevaRestrictedOpts} setTags={setGenevaRestrictedOpts} item="Item" />
                    <Button loading={genevaRestrictedLoading} onClick={onUpdateRestrictedOpts}>Update</Button>
                </Form.Item>
            </div>
        )
    }
    else {
        return (<div></div>);
    }
}


type IdParams = {
    id: string;
}
type OnboardProps = {};
const ServiceOnboard: React.FC<OnboardProps> = () => {
    const [formDisplay, SetFormDisplay] = useState(false);
    const [onboardDisplay, SetOnboardDisplay] = useState(false);
    const [onboardStatus, SetOnboardStatus] = useState(false);
    const { accounts, instance } = useMsal();
    const [services, setServices] = useState<any>([]);
    const [richDes, setRichDes] = useState('');
    const [serviceLoading, setServiceLoading] = useState(false);
    const [extensionOpts, setExtensionOpts] = useState<any>([]);
    const [genevaExts, setGenevaExts] = useState<any>([]);
    const [genevaRestrictedOpts, setGenevaRestrictedOpts] = useState<string[]>([]);
    const [form] = Form.useForm();
    let [service, SetService] = useState<any>({ "description": "" });
    let email = accounts[0].username;

    let { id } = useParams<IdParams>();
    console.log(id);

    async function onChange(value) {
        console.log(`selected ${value}`);
        //alert(`selected ${value}`);
        if (value === 'undefined' || !value || !/[^\s]/.test(value)) {
            //为空
        } else {
            if (value !== id) {
                //redirect to reload the new service
                window.location.href = `/service/${value}`;
                return;
            }
            setServiceLoading(true);
            let result = await fetchServiceById(accounts, instance, value);

            //let tmpData = tableData.map(item => ({ value: item.Id, description: item.description }))[0];
            SetService(result);
            setRichDes(result.alertDefaultDescription);
            if (result) {
                if (result?.owners) {
                    SetOnboardDisplay(true);
                    SetOnboardStatus(true);
                    //var isManager = await checkUserServiceManagementPermission(accounts, instance, id);
                    if (result.owners.includes(email)) {
                        SetFormDisplay(true);
                    }
                    else {
                        SetFormDisplay(false);
                    }
                }
                else {
                    SetOnboardDisplay(true);
                    SetOnboardStatus(false);
                    SetFormDisplay(false);
                }
                if (result.genevaExtensions) {
                    setGenevaExts(result.genevaExtensions);
                }
                if (result.genevaRestrictedOperations) {
                    setGenevaRestrictedOpts(result.genevaRestrictedOperations);
                }
            }
            setServiceLoading(false);

            let tmpExts: any[] = [];
            if(result.owners !== 'undefined' && result.owners)
            {
                if(!window.location.host.includes("localhost"))
                {
                    let response = await getGenevaActionExtensions(accounts, instance, value);

                    if(!Array.isArray(response)){
                        message.error(response);
                    }else{
                        tmpExts = response;
                    }                
                }
            }

            let exts: any[] = [];
            if(tmpExts)
            {
                tmpExts.forEach((ext) => {
                    //Temporary logic to prevent other teams from using these two extensions before we assign dedeicated Aad App to each service to run Geneva.
                    if (ext === 'Antares' && id !== '10060') {
                    }
                    else if (ext === 'ApiManagement' && id !== '10110') {
                    }
                    else {
                        exts.push({
                            label: ext,
                            value: ext
                        })
                    }
                })
            }

            setExtensionOpts(exts);
        }
    }

    const fetchData = async () => {
        let tableData = await fetchServices(accounts, instance, "");
        let tmpData = tableData.map(item => ({ value: item.id, label: item.serviceName }))
        setServices(tmpData);
    }

    const onFinish = async () => {
        let rawIcmData2 = form.getFieldValue('description');
        console.log(rawIcmData2);
    }

    useEffect(() => {
        console.log("useeffect in service onboard.");
        fetchData();
        if (id) {
            onChange(id);
        }
    }, [])

    return (
        <div style={{ padding: "50px" }}>
            <Form
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                onFinish={onFinish}
                initialValues={{ remember: false }}
                preserve={false}
            >
                <Card style={{ width: "100%" }} title="Service Onboarding" loading={serviceLoading}>

                    <Form.Item label="Select Service" rules={[{ required: true, message: "You must provide a Service Name" }]}>
                        <Select options={services} defaultValue={id} optionFilterProp="label" showSearch={true} onChange={onChange}>
                        </Select>
                    </Form.Item>
                    <DescriptionFormItem active={onboardDisplay} onboarded={onboardStatus} data={service} />
                    <AlertRuleSetting active={formDisplay} id={service.id} richDes={richDes} setRichDes={setRichDes} />
                    <AdminsFormItem active={formDisplay} data={service} />
                    <GenevaExtension id={service.id} extensionOpts={extensionOpts} genevaExts={genevaExts} setGenevaExts={setGenevaExts} genevaRestrictedOpts={genevaRestrictedOpts} setGenevaRestrictedOpts={setGenevaRestrictedOpts} active={formDisplay} />
                    <GenevaActionsRBAC serviceId={service.id} active={formDisplay} disabled={false} />
                    <KustosFormItem active={formDisplay} data={service} />
                    <IcmFormItem active={formDisplay} data={service} />
                </Card>
            </Form>
        </div>
    )
}

export default ServiceOnboard;