import React from 'react';
import {
    Form,
    Select
} from 'antd';
import { BrowserRouter, Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import Paragraph from 'antd/lib/typography/Paragraph';
type MachineActionProps = {
    active: boolean,
    readonly: boolean
}

const machineActionOptions = [
    {label: "Reboot", value: 1},
    {label: "Reimage", value: 2},
    {label: "Rebuild", value: 4},
    // {label: "Redeploy", value: 8},
    // {label: "Delete", value: 16}
]
const MachineAction: React.FC<MachineActionProps> = ({active, readonly}) => {
  let history = useHistory();
  const renderMachine = () => {
    if (active) {
      return (
        <Form.Item label="Machine Action">
          <Form.Item name="machineAction">
            <Select style={{visibility:"hidden"}} options={machineActionOptions} disabled={readonly} />
          </Form.Item>
          <div>
            <p style={{color:"red"}}>Be careful! This will affect production. Make sure you really need this.</p>
            Tips:
            <ol>
              <li>Requested machine action will be performed against VM(s) based on stamp and instanceName(s).</li>
              Hence <b>Stamp</b>, <b>InstanceName</b> and <b>RequestedAction</b> are required fields of Kusto query result for machine actions. If <b>InstanceName</b> is Ip address(for worker role instances), then <b>RoleName</b> is also required. <b>RequestReason</b> will be set to alert name by default when it's not specified in query output.
              <li>For worker roles, maximum 10 VMs will be reboot/reimaged every time an alert with machine action is triggered.</li>
              <li>For ASE, the above limitation is set to 1. Only 1 VM of ASE will be reboot/reimaged every time.</li>
              <li>For non-worker roles, the above limitation is also set to 1 by default. Only 1 VM of non-worker roles will be reboot/reimaged every time.</li>
              <li>A single action(e.g: reboot/reimage/redeploy) will not be applied to same VM again within 1 hour.</li>
              <li>However for a new action with higher level/priority (i.e: reboot &lt; reimage &lt; redeploy &lt; deleteinstances ), it will be performed immediately.</li>
              <li>No action will be taken for VM(s) added into protected machines list.</li>
              <li>The supported list of actions for Cloud Service stamps is Reboot, Reimage, and Rebuild. The supported list of actions for VMSS stamps is Reboot, Reimage, ReimageAll, Redeploy, and DeleteInstances.</li>
              <li>For Hybrid stamp, OperationOnVmss and ServiceName are also required. For example:</li>
              <pre>
                    | project InstanceName = RoleInstance, Stamp = EventPrimaryStampName, ServiceName = iif(Tenant =~ EventPrimaryStampName, strcat(EventStampName, &#39;-rg&#39;), EventStampName),<br/>
                      RequestedAction = iif(Tenant =~ EventPrimaryStampName, &#39;redeploy&#39;, &#39;rebuild&#39;), TimeStamp = now(), OperationOnVmss = iif(Tenant =~ EventPrimaryStampName, &#39;true&#39;, &#39;false&#39;)<br />
              </pre>
              <li>Machine actions should always run against EventPrimaryStampName (not EventStampName). To specify CloudService worker role instances, use the IP address of the VM, RoleName, and EventPrimaryStampName. (This method works with VMSS as well.)</li>
              <pre>                                     
                  | project InstanceName = EventIpAddress, RoleName = Role, Stamp = EventPrimaryStampName, RequestedAction = &#39;reboot&#39;, TimeStamp = now(), RequestReason = &#39;AlertingV2 Auto-Mitigation&#39;<br/>
              </pre>
            </ol>
            Sample Kusto Query:<br />
                <Paragraph>
                  <pre>
                    RoleInstanceHeartbeat<br/>
                    | where Tenant == &#39;waws-prod-xxxxxxxx-xxx&#39;<br/>
                    | where PreciseTimeStamp &gt; ago(1d)<br/>                                        
                    | project InstanceName = RoleInstance, Stamp = EventPrimaryStampName, RequestedAction = &#39;reboot&#39;, TimeStamp = now(), RequestReason = &#39;AlertingV2 Auto-Mitigation&#39;<br/>                                        
                    | distinct *<br/>
                    | take 3<br/>
                  </pre>      
                </Paragraph>
            <BrowserRouter>
            <Link to="/faq#machineaction" onClick={()=>{history.push('/faq#machineaction')}}>For more detail, go to FAQ</Link>
            </BrowserRouter>
          </div>
        </Form.Item>
      )
    } else {
        return (<div></div>)
    }
  }
  return renderMachine();
}

export default MachineAction;