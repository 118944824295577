import React, { useEffect, useState } from 'react';
import { PageHeader, Button } from 'antd';
import './styles.less';
import { useAccount, useMsal } from '@azure/msal-react';

type Props = {};

const Header: React.FC<Props> = () => {
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");

  useEffect(() => {
      if (account && account.name) {
          setName(account.name.split(" ")[0]);
      }
  }, [account]);

  // if (accounts.length > 0) {
  //     const request = {
  //         scopes: ["User.Read"],
  //         account: accounts[0]
  //     };
  //     instance.acquireTokenSilent(request).then(response => {
  //       console.log(response);
  //       if (response.account !== null)
  //       {
  //         setName(response.account.username);
  //       }
  //     }).catch(error => {
  //         // acquireTokenSilent can fail for a number of reasons, fallback to interaction
  //         if (error instanceof InteractionRequiredAuthError) {
  //             instance.acquireTokenPopup(request).then(response => {
  //               if (response.account !== null)
  //               {
  //                 setName(response.account.username);
  //               }
  //             });
  //         }
  //     });
  // }
  const handleLogout = () => {
    instance.logoutRedirect();
  }

  return (
  <PageHeader title={name} style={{backgroundColor: "white", paddingLeft: "50px", paddingRight:"50px" }} extra={[<Button key="3" onClick={handleLogout}>Logout</Button>]}/>
  );
};


export default Header;
