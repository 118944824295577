import { Form } from "antd";
import React from "react";
import ReactQuill from 'react-quill-2';
import 'react-quill-2/dist/quill.snow.css';
import '../../../styles/base.less'
type EditorProps = {
  value: string;
  setValue: any;
  readonly: boolean;
  labelVal?: string;
  placeHolder?: string;
}
const RichTextEditor:React.FC<EditorProps> = ({value, setValue, readonly, labelVal="Description", placeHolder=""}) => {
  const onChangeText = (val)=> {
    setValue(val);
  }
  return (
    <Form.Item label={labelVal} required rules={[{ required: true, message: 'Description is required' }]}>
      <ReactQuill placeholder={placeHolder} readOnly={readonly} theme="snow" value={value} onChange={onChangeText} style={{height:"240px"}} />
      <br/>
    </Form.Item>
  );
}
export default RichTextEditor;