import { Button, Form, FormInstance, Input, Radio, Row, Col, InputNumber, Table, Checkbox, Popconfirm } from 'antd';
import KustoQueryDisplay from './KustoQueryDisplay';
import React from 'react';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { ColumnsType } from 'antd/es/table';

type QueryProps = {
    accounts: any,
    instance: any,
    formField: string,
    form: FormInstance,
    disabled: boolean,
    queries: any[],
    clusters: string[],
    setQueries: any,
    serviceId: string,
    compactMode?: boolean
}
interface DataType {
  title: string;
  isGraphInReport: boolean;
  kustoQuery: string;
  position: number; // position works as the key of each secondary query, also decides the order of secondary queries. the value begins from 1.
}

const DynamicKustos : React.FC<QueryProps> = ({accounts, instance, formField, form, disabled, clusters, queries, setQueries, serviceId, compactMode = false}) => {
  const add = ()=>{
    let tmpQueires = [...queries, {title:'',kustoQuery:'', isGraphInReport: false, position: queries.length + 1}]
		form.setFieldsValue({[formField]:tmpQueires})
		return setQueries(tmpQueires)
	}
	const del = (index)=>{
    form.setFieldsValue({[formField]:[...queries.slice(0,index),...queries.slice(index+1)]})
		return setQueries([...queries.slice(0,index),...queries.slice(index+1)])
	}
	
	const onChange = (index,name,event)=>{
		let tempArray = form.getFieldValue(formField);
		if('title'===name) {
      //tempArray[index] = {...tempArray[index],title:event.target.value}
      tempArray[index]['title'] = event.target.value
      //console.log('after: ', tempArray[index])
    } else if('isGraphInReport'===name) {
      //tempArray[index] = {...tempArray[index],isGraphInReport:event.target.value}
      tempArray[index]['isGraphInReport'] = event.target.value
    } else {
      //tempArray[index] = {...tempArray[index],kustoQuery:event}
      tempArray[index]['kustoQuery'] = event
    }
    form.setFieldsValue({[formField]: tempArray})
		return setQueries(tempArray)
	}

  const columns: ColumnsType<DataType> = [
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Render as a graph',
      dataIndex: 'isGraphInReport',
      render: (value) => <Checkbox checked={value} disabled={true}></Checkbox>
    },
    {
      title: 'Action',
      render: (_, record: { position: React.Key }, index) =>
      queries.length >= 1 ? (
        <Popconfirm title="Sure to delete?" onConfirm={() =>del(index)}>
          {disabled?<span style={{color: "rgba(0, 0, 0, 0.25)"}}>Delete</span>:<a>Delete</a>}
        </Popconfirm>
      ) : null,
    },
  ];
  
  interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
  }
  
  const SortableRow = (props: RowProps) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: props['data-row-key'],
    });
  
    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
      transition,
      cursor: 'move',
      ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };
  
    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
  };  

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    }),
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = queries.findIndex((i) => i.position === active.id);
      const overIndex = queries.findIndex((i) => i.position === over?.id);
      const newQueries = arrayMove(queries, activeIndex, overIndex);
      form.setFieldsValue({[formField]: newQueries})
      return setQueries(newQueries);
    }
  };

  const sortableQueriesItems = <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
  <SortableContext
    // rowKey array
    items={queries.map((i) => i.position)}
    strategy={verticalListSortingStrategy}
  >
      <Table
        style={{marginBottom: "20px"}}
        components={{
          body: {
            row: SortableRow,
          },
        }}
        rowKey={record => record.position}
        columns={columns}
        dataSource={queries}
        expandable={{
          expandedRowRender: record => 
          <KustoQueryDisplay serviceId={serviceId} readOnly={true} content={record.kustoQuery} accounts={accounts} instance={instance} clusters={clusters} onChange={event => {}} validate={true}></KustoQueryDisplay>
        }}
        pagination={false}
      />
    </SortableContext>
  </DndContext>

  const queriesItems = queries.map((item,index)=>{
		return <Form.Item key={index} noStyle>
              <Col span={24}>
                <Button type="primary" disabled={disabled} onClick={()=>del(index)}>-</Button>
              </Col>
                  <Form.Item label="Title" name={[formField,index,'title']} required rules={[{ required: true, message: "Please provide a query title" }]}><Input disabled={disabled} onChange={(event)=>onChange(index,'title',event)}/></Form.Item>
                  <Form.Item label="Render as a graph" name={[formField,index,'isGraphInReport']} >
                    <Radio.Group disabled={disabled}  onChange={ (event) => onChange(index, 'isGraphInReport', event)}>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item hidden={true} label='Position' name={[formField, index, 'position']}>

                    <InputNumber disabled={disabled} />
                  </Form.Item>
              <Col span={24}>
                <Form.Item label="Kusto" name={[formField, index, 'kustoQuery']}>
                  <KustoQueryDisplay serviceId={serviceId} readOnly={disabled} content={queries[index]['kustoQuery']} accounts={accounts} instance={instance} clusters={clusters} onChange={event => onChange(index, 'Kusto', event)} validate={true}></KustoQueryDisplay>
                </Form.Item>
              </Col>
			</Form.Item>
	})


  
  return <Row>
      <Col span={24}>
        {compactMode?sortableQueriesItems:queriesItems}
        <Form.Item><Button type="primary" onClick={add} disabled={disabled || compactMode}>+SecondaryKustoQuery</Button></Form.Item>
      </Col>
	  </Row>
}
export default DynamicKustos;