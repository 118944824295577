import React from 'react';
import { Spin } from 'antd';
import { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react';
import { fetchMachineActionOverallChart, fetchGenevaActionsOverallChart } from '@utils/helpers';
import { Bar } from 'react-chartjs-2';
import internal from 'stream';
import { ceilNumber } from '@utils/converters';

type MachineActionChartProps = {
    timeRange: string;
    type: string;
    maxvalue: number;
    onClickChartElement: (status: string, num: string, alertName: string) => void;
};

const getRGB = (status) => {
  switch (status) {
      case 'NotStarted':
          return '255, 99, 152';
      case 'Skipped':
          return '153, 204, 255';
      case 'Enqueued':
          return '235, 119, 132';
      case 'Executing':
          return '51, 102, 204';
      case 'Succeeded':
          return '135, 219, 32';
      case 'Failed':
          return '255, 51, 0';
      case 'Paused':
          return '155, 199, 52';
      case 'PartiallyCompleted':
          return '51, 204, 204';
      case 'CompletedWithError':
          return '204, 153, 0';
      default:
          return '255, 255, 255';
  }
}

const StackedBarChart: React.FC<MachineActionChartProps> = ({ timeRange, type, maxvalue, onClickChartElement }) => {
  const [chartData, setChartData] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const { accounts, instance } = useMsal();

  const FetchData = async () => {
    setLoading(true);
    var chartResults: any;
    if (type === 'Machine Actions') {
      chartResults = await fetchMachineActionOverallChart(accounts, instance, timeRange);
    }
    else if ( type === 'Geneva Actions') {
      chartResults = await fetchGenevaActionsOverallChart(accounts, instance, timeRange);
    }    
    setLoading(false);
    setChartData(chartResults);
  }

  //FetchData();

  useEffect(() => {
    FetchData();
  }, [timeRange]);


  var labels = ['a','b'];
  var alertIDs = ['a','b'];
  var failed = [0, 1];
  var completed = [0, 1];

  // var labels = ['a','b','c','d','e','f'];
  // var failed = [0, 9, 2, 300, 4, 5];
  // var completed = [0, 1, 2, 5000, 4 ,5];

  labels = [];
  alertIDs = [];
  failed = [];
  completed = [];

  //console.log(chartData);

  var maxScale = 0;

  chartData.forEach(element => {
    labels.push(element.Name);
    alertIDs.push(element.AlertId);
    failed.push(element.Failed);
    completed.push(element.Completed);

    if (maxScale < (element.Failed + element.Completed)){
      maxScale = element.Failed + element.Completed;
    }    
  });

  var ceil = ceilNumber(maxScale);
  maxScale = ceil > 30000 ? 30000 : ceil; 

  function OnClick(evt, element) {
    if(element.length > 0)
    {
      var countIndex = element[0].index;
      var status = data.datasets[element[0].datasetIndex].label;

      if (countIndex !== undefined && status !== undefined) {
        var count = data.datasets[element[0].datasetIndex].data[countIndex];
        var alertId = alertIDs[countIndex];

        // console.log(status);
        // console.log(count);
        // console.log(alertId);

        onClickChartElement(status, count.toString(), alertId);
      }
    }
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Failed',
        data: failed,
        backgroundColor: 'rgb(255, 99, 132)',
      },
      {
        label: 'Completed',
        data: completed,
        backgroundColor: 'rgb(75, 192, 192)',
      },
    ],
  };
  
  const options = {
    onClick: OnClick,
    onHover: (event, chartElement) => {
        const target = event.native ? event.native.target : event.target;
        target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
    plugins: {
      title: {
        display: true,
        text: type,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        display: false
      },
      y: {
        stacked: true,
        max: maxvalue > 0 ? maxvalue : maxScale
      }
    },
  };
  
  return (
    <Spin tip="Loading data..." size="default" spinning={loading} delay={500}>
      <Bar data={data} options={options} />
    </Spin>    
  );

}
export default StackedBarChart;