/* eslint no-useless-escape: "off" */
import React, {useRef, useState} from 'react';
import Editor, { EditorDidMount } from '@monaco-editor/react';
import { monaco } from '@monaco-editor/react';
import { languages } from 'monaco-editor';
import { Button, message } from 'antd';
import { runKustoQuery } from '@utils/helpers';


const EMPTY_ELEMENTS: string[] = [
	'area',
	'base',
	'br',
	'col',
	'embed',
	'hr',
	'img',
	'input',
	'keygen',
	'link',
	'menuitem',
	'meta',
	'param',
	'source',
	'track',
	'wbr'
];


const kustoLanguage : languages.IMonarchLanguage = {
    defaultToken: '',

    brackets: [
        { open: '{', close: '}', token: 'delimiter.curly' },
        { open: '[', close: ']', token: 'delimiter.bracket' },
        { open: '(', close: ')', token: 'delimiter.parenthesis' }
    ],
    tokenizer: {
        root: [
            { include: '@whitespace' },
            { include: '@numbers' },
            { include: '@strings' },
            { include: '@keywords'},

            [/[,:;]/, 'delimiter'],
            [/[{}\[\]()]/, '@brackets'],

            [/@[a-zA-Z_]\w*/, 'tag'],
            [
                /[a-zA-Z_]\w*/,
                {
                    cases: {
                        '@default': 'identifier'
                    }
                }
            ]
        ],
        // can add more of these from: https://github.com/rosshamish/kuskus/blob/master/kusto-syntax-highlighting/syntaxes/kusto.tmLanguage
        keywords: [
            ['(where|summarize|extend|mvexpand|project|sort|project|-away|join|union|limit|order|sort|top|print|datatable)', 'keyword'],
            ['(let|count|ingestion_time|and|or|max|min|iff|isempty|isnotempty|log|sum|extract|now|false|true|makeset|makelist|any|arg_max|arg_min|any|dcount|sumif|countif|avg|materialize|pack|database|strcat|translate|substring|tostring|toscalar|strlen|contains|in|startswith|endswith|split|typeof)', '']
        ],

        // Deal with white space, including single and multi-line comments
        whitespace: [
            [/\s+/, 'white'],
            [/(^#.*$)/, 'comment'],
            [/'''/, 'string', '@endDocString'],
            [/"""/, 'string', '@endDblDocString']
        ],
        endDocString: [
            [/[^']+/, 'string'],
            [/\\'/, 'string'],
            [/'''/, 'string', '@popall'],
            [/'/, 'string']
        ],
        endDblDocString: [
            [/[^"]+/, 'string'],
            [/\\"/, 'string'],
            [/"""/, 'string', '@popall'],
            [/"/, 'string']
        ],

        // Recognize hex, negatives, decimals, imaginaries, longs, and scientific notation
        numbers: [
            [/-?0x([abcdef]|[ABCDEF]|\d)+[lL]?/, 'number.hex'],
            [/-?(\d*\.)?\d+([eE][+\-]?\d+)?[jJ]?[lL]?/, 'number']
        ],

        // Recognize strings, including those broken across lines with \ (but not without)
        strings: [
            [/'$/, 'string.escape', '@popall'],
            [/'/, 'string.escape', '@stringBody'],
            [/"$/, 'string.escape', '@popall'],
            [/"/, 'string.escape', '@dblStringBody']
        ],
        stringBody: [
            [/[^\\']+$/, 'string', '@popall'],
            [/[^\\']+/, 'string'],
            [/\\./, 'string'],
            [/'/, 'string.escape', '@popall'],
            [/\\$/, 'string']
        ],
        dblStringBody: [
            [/[^\\"]+$/, 'string', '@popall'],
            [/[^\\"]+/, 'string'],
            [/\\./, 'string'],
            [/"/, 'string.escape', '@popall'],
            [/\\$/, 'string']
        ]
    }
}


monaco
  .init()
  .then(monaco => {
      monaco.languages.register( {id : "kusto" });
      monaco.languages.setLanguageConfiguration("kusto", {	wordPattern: /(-?\d*\.\d\w*)|([^\`\~\!\@\$\^\&\*\(\)\=\+\[\{\]\}\\\|\;\:\'\"\,\.\<\>\/\s]+)/g,

      comments: {
          blockComment: ['{{!--', '--}}']
      },
  
      brackets: [
          ['<!--', '-->'],
          ['<', '>'],
          ['{{', '}}'],
          ['{', '}'],
          ['(', ')']
      ],
  
      autoClosingPairs: [
          { open: '{', close: '}' },
          { open: '[', close: ']' },
          { open: '(', close: ')' },
          { open: '"', close: '"' },
          { open: "'", close: "'" }
      ],
  
      surroundingPairs: [
          { open: '<', close: '>' },
          { open: '"', close: '"' },
          { open: "'", close: "'" }
      ],
  
      onEnterRules: [
          {
              beforeText: new RegExp(
                  `<(?!(?:${EMPTY_ELEMENTS.join('|')}))(\\w[\\w\\d]*)([^/>]*(?!/)>)[^<]*$`,
                  'i'
              ),
              afterText: /^<\/(\w[\w\d]*)\s*>$/i,
              action: {
                  indentAction: languages.IndentAction.IndentOutdent
              }
          },
          {
              beforeText: new RegExp(
                  `<(?!(?:${EMPTY_ELEMENTS.join('|')}))(\\w[\\w\\d]*)([^/>]*(?!/)>)[^<]*$`,
                  'i'
              ),
              action: { indentAction: languages.IndentAction.Indent }
          }
      ]})
      monaco.languages.setMonarchTokensProvider("kusto", kustoLanguage)
    })
  .catch(error => console.error('An error occurred during initialization of Monaco: ', error));

type KustoQueryProps = {
    readOnly : boolean;
    content : string;
    accounts: any;
    instance: any;
    onChange : (s: string) => void;
    clusters: string[];
    validate: boolean;
    serviceId: string;
}

// workaround to get the type correctly in there :)
type IStandaloneCodeEditor = Parameters<EditorDidMount>[1] 

const KustoQueryDisplay: React.FC<KustoQueryProps> = ( { readOnly, serviceId, content, accounts, instance, onChange, clusters, validate }) => {
    const editorRef = useRef<IStandaloneCodeEditor>();
    const [loading, setLoading] = useState<boolean>(false);
    const handleEditorDidMount: EditorDidMount = (getEditorValue, editor) => {

        editorRef.current = editor;
        editor.onDidChangeModelContent((ev) => {
            onChange(editor.getValue())
        })
      // Now you can use the instance of monaco editor
      // in this component whenever you want
    }

    const runNow = async () => {
        setLoading(true);
        let runResult = await runKustoQuery(accounts, instance, content, clusters, serviceId);
        if (runResult.result) {
            message.success('Kusto runs successfully');
        } else {
            message.error(`${runResult.message}`, 3);
        }
        setLoading(false);
    }

    let button = validate ? <Button type="primary" disabled={content===undefined||content===""} loading={loading} onClick={runNow}>Validate Query</Button> : null;

    return(
        <div>
            <Editor theme="vs-dark" height="60vh" value={content} language="kusto" options={{readOnly : readOnly, wordWrap: "on"}} editorDidMount={handleEditorDidMount}/>
            {button}
        </div>
        
   );
 };
export default KustoQueryDisplay