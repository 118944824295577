import React from 'react';
import { Spin } from 'antd';
import { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react';
import { Line } from 'react-chartjs-2';
import { getNumberOfAlertsByMonth } from '@utils/helpers';

type TimeChartProps = {
    isAlerts: boolean;
};

const TimeChartAlerts: React.FC<TimeChartProps> = ({ isAlerts }) => {

    const { accounts, instance } = useMsal();
    const [numbers, setNumbers] = useState<number[]>([]);
    const [months, setMonths] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);
    var isLoaded = false;

    const FetchData = async () => {
        setLoading(true);
        var results = await getNumberOfAlertsByMonth(accounts, instance);

        setNumbers(results["Numbers"]);
        setMonths(results["Months"]);

        setLoading(false);
        
        isLoaded = true;
    }      

    useEffect(() => {    
        if (!isLoaded){
            FetchData();
        }        
    }, []);  

    var data = [{
        label: 'Number of Alerts',
        data: numbers,
        fill: false,        
        backgroundColor: 'rgb(0, 153, 51)',
        borderColor: 'rgba(0, 153, 51, 0.3)',
        pointStyle: 'rectRot',
        pointRadius: 8,
        pointBorderColor: 'rgb(0, 0, 0)'
    }];

    var chartData = {
        labels: months,
        datasets: data
    }   

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        },
        animation: {
            duration: 200
        },
        plugins:{
            title: {
               display: false,
               text: ""
               }                    
        },
    };

    return (
        <Spin tip="Loading data..." size="default" spinning={loading} delay={500}>
            <Line data={chartData} options={options}/>
        </Spin>        
    );
}

export default TimeChartAlerts;