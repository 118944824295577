import React from 'react';
import { Line } from 'react-chartjs-2';

type TimeChartProps = {
    isServices: boolean;
};

const TimeChart: React.FC<TimeChartProps> = ({ isServices }) => {

    var labels = ['Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct' ];
    var dates = ['Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct' ];
    var nums = [0, 5, 5, 7, 11, 11, 12, 12, 12, 12, 12, 13 ];


    const footer = (tooltipItems) => {
        let x = 0;

        tooltipItems.forEach(function (tooltipItem) {
            x = tooltipItem.parsed.x;
        });

        switch (x) {
            case 1:
                return '- Azure SignalR Service \n' +
                    '- Azure Distributed Managed Service for Spring \n' +
                    '- API Management \n' +
                    '- App Service (Web Apps) \n' +
                    '- WATestOps';
            case 2:
                return '- Azure SignalR Service \n' +
                    '- Azure Distributed Managed Service for Spring \n' +
                    '- API Management \n' +
                    '- App Service (Web Apps) \n' +
                    '- WATestOps';
            case 3:
                return '- LogicApps \n' +
                    '- Azure Kubernetes Service \n' +
                    '- Azure SignalR Service \n' +
                    '- Azure Distributed Managed Service for Spring \n' +
                    '- API Management \n' +
                    '- App Service (Web Apps) \n' +
                    '- WATestOps';
            case 4:
                return '- Azure Global Build Out Automation \n' +
                    '- Azure Purview \n' +
                    '- CloudPC Service \n' +
                    '- Auto Correlation Test Service \n' +
                    '- LogicApps \n' +
                    '- Azure Kubernetes Service \n' +
                    '- Azure SignalR Service \n' +
                    '- Azure Distributed Managed Service for Spring \n' +
                    '- API Management \n' +
                    '- App Service (Web Apps) \n' +
                    '- WATestOps';
            case 5:
                return '- Azure Global Build Out Automation \n' +
                    '- Azure Purview \n' +
                    '- CloudPC Service \n' +
                    '- Auto Correlation Test Service \n' +
                    '- LogicApps \n' +
                    '- Azure Kubernetes Service \n' +
                    '- Azure SignalR Service \n' +
                    '- Azure Distributed Managed Service for Spring \n' +
                    '- API Management \n' +
                    '- App Service (Web Apps) \n' +
                    '- WATestOps';
            case 6:
                return '- Azure Container Apps \n' +
                    '- Azure Global Build Out Automation \n' +
                    '- Azure Purview \n' +
                    '- CloudPC Service \n' +
                    '- Auto Correlation Test Service \n' +
                    '- LogicApps \n' +
                    '- Azure Kubernetes Service \n' +
                    '- Azure SignalR Service \n' +
                    '- Azure Distributed Managed Service for Spring \n' +
                    '- API Management \n' +
                    '- App Service (Web Apps) \n' +
                    '- WATestOps';
            case 7:
                return '- Azure Container Apps \n' +
                    '- Azure Global Build Out Automation \n' +
                    '- Azure Purview \n' +
                    '- CloudPC Service \n' +
                    '- Auto Correlation Test Service \n' +
                    '- LogicApps \n' +
                    '- Azure Kubernetes Service \n' +
                    '- Azure SignalR Service \n' +
                    '- Azure Distributed Managed Service for Spring \n' +
                    '- API Management \n' +
                    '- App Service (Web Apps) \n' +
                    '- WATestOps';
            case 8:
                return '- Azure Container Apps \n' +
                    '- Azure Global Build Out Automation \n' +
                    '- Azure Purview \n' +
                    '- CloudPC Service \n' +
                    '- Auto Correlation Test Service \n' +
                    '- LogicApps \n' +
                    '- Azure Kubernetes Service \n' +
                    '- Azure SignalR Service \n' +
                    '- Azure Distributed Managed Service for Spring \n' +
                    '- API Management \n' +
                    '- App Service (Web Apps) \n' +
                    '- WATestOps';
            case 9:
                return '- Azure Container Apps \n' +
                    '- Azure Global Build Out Automation \n' +
                    '- Azure Purview \n' +
                    '- CloudPC Service \n' +
                    '- Auto Correlation Test Service \n' +
                    '- LogicApps \n' +
                    '- Azure Kubernetes Service \n' +
                    '- Azure SignalR Service \n' +
                    '- Azure Distributed Managed Service for Spring \n' +
                    '- API Management \n' +
                    '- App Service (Web Apps) \n' +
                    '- WATestOps';
            case 10:
                return '- Azure Container Apps \n' +
                    '- Azure Global Build Out Automation \n' +
                    '- Azure Purview \n' +
                    '- CloudPC Service \n' +
                    '- Auto Correlation Test Service \n' +
                    '- LogicApps \n' +
                    '- Azure Kubernetes Service \n' +
                    '- Azure SignalR Service \n' +
                    '- Azure Distributed Managed Service for Spring \n' +
                    '- API Management \n' +
                    '- App Service (Web Apps) \n' +
                    '- WATestOps';
            case 11:
                return '- Legion \n' +
                    '- Azure Container Apps \n' +
                    '- Azure Global Build Out Automation \n' +
                    '- Azure Purview \n' +
                    '- CloudPC Service \n' +
                    '- Auto Correlation Test Service \n' +
                    '- LogicApps \n' +
                    '- Azure Kubernetes Service \n' +
                    '- Azure SignalR Service \n' +
                    '- Azure Distributed Managed Service for Spring \n' +
                    '- API Management \n' +
                    '- App Service (Web Apps) \n' +
                    '- WATestOps';                   
            default:
                return '';
        }

    };

    var data = [{
        label: 'Services',
        data: nums,
        fill: false,
        backgroundColor: 'rgb(51, 102, 204)',
        borderColor: 'rgba(51, 102, 204, 0.3)',
        date: dates,
        pointStyle: 'triangle',
        pointRadius: 8,
        pointBorderColor: 'rgb(0, 0, 0)'
    }];

    var chartData = {
        labels: labels,
        datasets: data
    }

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        },
        animation: {
            duration: 5000
        },
        plugins: {
            title: {
                display: false,
                text: ""
            }
            ,
            tooltip: {
                callbacks: {
                    footer: footer,
                },
                footerFont: { weight: 'lighter' },
            }
        },
    };

    return (
        <Line data={chartData} options={options} />
    );
}

export default TimeChart;