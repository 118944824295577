import React from 'react';
import { Card  } from 'antd';
import AlertForm from '@components/AlertForm/AlertForm';


type Props = {};

const NewAlert: React.FC<Props> = () => {
  return (
    
      <div style={{"margin": "2%"}}>
        <Card title="Create Alert">
            <AlertForm isNewAlert={true} isDisabled={false}/>
        </Card>
      </div>
    
  );
};

export default NewAlert;
