import React, { useState } from 'react';
import {
  Form,
  Select,
  Input,
  Tooltip,
  Button,
  message
} from 'antd';
import { useMsal } from '@azure/msal-react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { addRoleBasedClaims } from '@utils/helpers';

type GenevaActionsRBACItemProps = {
  active: boolean;
  disabled: boolean;
  serviceId: string;
};

const { Option } = Select;

const GenevaActionsRBAC: React.FC<GenevaActionsRBACItemProps> = ({ active, disabled, serviceId }) => {
  const { accounts, instance } = useMsal();
  const [form] = Form.useForm();
  const [RBACLoading, setRBACLoading] = useState(false);
  const [comments, setComments] = useState<string>("");

  const addRBACClaim = async () => {
    setRBACLoading(true);

    var tenant = form.getFieldValue("RBACTenant");
    var scope = form.getFieldValue("RBACScope");
    var role = form.getFieldValue("RBACRole");

    if (tenant === undefined || tenant.length === 0) {
      message.error(`The selected Tenant is invalid or empty.`, 4);
    }
    else if (scope === undefined || scope.length === 0) {
      message.error(`The specified Scope is invalid or empty.`, 4);
    }
    else if (role === undefined || role.length === 0) {
      message.error(`The specified Role is invalid or empty.`, 4);
    }
    else {
      var response = await addRoleBasedClaims(accounts, instance, tenant, scope.toString(), role.toString(), serviceId);
      if (response.status === 200) {
        message.success(`addRoleBasedClaims request was submitted.`, 4);
        setComments("This addRoleBasedClaims request has been submitted. You will receive an email with the approval link. Please contact service tree admin to approve it.");
      } else {
        message.error(`addRoleBasedClaims request failed: ${response.data}`, 8)
      }
    }
    setRBACLoading(false);
  }

  if (active === true) {
    return (
      <Form.Item name="RBAC" label={<div><Tooltip title="Grant AlertingV2 AAD App with required Role/Claim to call Geneva Operations">RBAC Claim <QuestionCircleOutlined /></Tooltip></div>}>
        <Form form={form}>
          <Form.Item name="RBACTenant" label={<div style={{ width: "40px" }}><Tooltip title="Public: AME, Test: MSIT PROD, Mooncake: CME, Fairfax: USME">Tenant</Tooltip></div>} rules={[{ required: true, message: 'Tenant is required' }]}>
            <Select placeholder="Select Tenant">
              <Option value="AME">AME</Option>
              <Option value="MSIT PROD">MSIT PROD</Option>
              <Option value="EXME">EXME</Option>
              <Option value="RXME">RXME</Option>
              {/* <Option value="PME">PME</Option>
              <Option value="CME">CME</Option>
              <Option value="USME">USME</Option>
              <Option value="DEME">DEME</Option>*/}
            </Select>
          </Form.Item>
          <Form.Item name="RBACScope" label={<div style={{ width: "40px" }}><Tooltip title="Use Get-AllScopes cmdlet or Geneva portal to view scopes belong to a specific extension/service tree id.">Scope</Tooltip></div>} rules={[{ required: true, message: 'Scope is required' }]}>
            <Input placeholder='Scope for example: Antares, ApiHub, ApiManagement, AFEC' />
          </Form.Item>
          <Form.Item name="RBACRole" label={<div style={{ width: "40px" }}><Tooltip title="Standard Roles: PlatformServiceViewer, PlatformServiceOperator, PlatformServiceAdministrator, CustomerServiceViewer, CustomerServiceOperator, CustomerServiceAdministrator, PackageAdministrator">Role</Tooltip></div>} rules={[{ required: true, message: 'Role is required' }]}>
            <Input placeholder='Role for example: PlatformServiceOperator, CustomerServiceOperator' />
          </Form.Item>
          <Button loading={RBACLoading} onClick={addRBACClaim}>Submit</Button>
          <p style={{ color: "green" }}>{comments}</p>
        </Form>
      </Form.Item>)
  }
  else {
    return (<></>);
  }
}
export default GenevaActionsRBAC