//export const WAWS_ALERTING_API = 'https://wawsalertingfunctionint.azurewebsites.net/api';
function getBaseEnvVariables() {
  switch(process.env.NODE_ENV) {
    case 'development':
      return {
        'BaseDevOpUri': 'https://msazure.visualstudio.com/One/_git/AAPT-Antares-WAWSAlertingMetadataTest',
        'BaseUri': 'http://localhost:7071/api',
        'HostKey': ''
      }
    default:
      if (window.location.host==="azurealerting.trafficmanager.net" || window.location.host==="azurealerting.azurewebsites.net" || window.location.host==="azurealerting2.azurewebsites.net" ) {
        return {
          'BaseDevOpUri': 'https://msazure.visualstudio.com/One/_git/AAPT-Antares-WAWSAlertingMetadata',
          'BaseUri': 'https://azurealertingfunctions.azurewebsites.net/api',
          'HostKey': ''
        }
      } 
      else if(window.location.host==="azurealerting-staging.azurewebsites.net" || window.location.host==="azurealerting2-staging.azurewebsites.net") {
        return {
          'BaseDevOpUri': 'https://msazure.visualstudio.com/One/_git/AAPT-Antares-WAWSAlertingMetadata',
          'BaseUri': 'https://azurealertingfunctions-staging.azurewebsites.net/api',
          'HostKey': ''
         }
      } 
      else if(window.location.host==="wawsalertingv2genevaactionspoc.azurewebsites.net" || window.location.host==="wawsalertingv2genevaactionspoc.trafficmanager.net") {
        return {
          'BaseDevOpUri': 'https://msazure.visualstudio.com/One/_git/AAPT-Antares-WAWSAlertingMetadataPPE',
          'BaseUri': 'https://wawsalertingv2genevaactionsfunction.azurewebsites.net/api',
          'HostKey': ''
        }
      }
      else if(window.location.host==="azurealertingpreprod.azurewebsites.net" || window.location.host==="azurealertingpreprod.trafficmanager.net") {
        return {
          'BaseDevOpUri': 'https://msazure.visualstudio.com/One/_git/AAPT-Antares-WAWSAlertingMetadataPPE',
          'BaseUri': 'https://azurealertingpreprodfunctions.azurewebsites.net/api',
          'HostKey': ''
        }
      }
      else if(window.location.host==="azurealertingpreprod.appservice.microsoft.scloud") {
        return {
          'BaseDevOpUri': 'https://ado.azure.microsoft.scloud/One/_git/Antares/AAPT-Antares-WAWSAlertingMetadata',
          'BaseUri': 'https://alertingpreprodfunctions.appservice.microsoft.scloud/api',
          'HostKey': ''
        }
      }
      else if(window.location.host==="ussecazurealerting.appservice.microsoft.scloud" || window.location.host==="ussecazurealerting2.appservice.microsoft.scloud") {
        return {
          'BaseDevOpUri': 'https://ado.azure.microsoft.scloud/One/_git/Antares/AAPT-Antares-WAWSAlertingMetadata',
          'BaseUri': 'https://ussecazurealertingfunctions.appservice.microsoft.scloud/api',
          'HostKey': ''
        }
      }
      else if(window.location.host==="usnatazurealerting.appservice.eaglex.ic.gov" || window.location.host==="usnatazurealerting2.appservice.eaglex.ic.gov") {
        return {
          'BaseDevOpUri': 'https://ado.azure.eaglex.ic.gov/One/_git/Antares/AAPT-Antares-WAWSAlertingMetadata',
          'BaseUri': 'https://usnatazurealertingfunctions.appservice.eaglex.ic.gov/api',
          'HostKey': ''
        }
      }
      else {
        return {
          'BaseDevOpUri': 'https://msazure.visualstudio.com/One/_git/AAPT-Antares-WAWSAlertingMetadataTest',
          'BaseUri': 'https://wawsalertingfunctionint.azurewebsites.net/api',
          'HostKey': ''
        }
      }
  }
}

function getCloudSpecificVariables() {
  let AAD_APP_CLIENT_ID = "546ae698-2794-41fd-a80e-e5d396f3b3f1";
  let AAD_APP_AUTHORITY = "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47";
  let AZURE_DEVOPS_SCOPE = "499b84ac-1321-427f-aa17-267ca6975798/user_impersonation";
  // USSec
  if(window.location.host==="ussecazurealerting.appservice.microsoft.scloud" || window.location.host==="ussecazurealerting2.appservice.microsoft.scloud" || window.location.host === "azurealertingpreprod.appservice.microsoft.scloud") {
    AAD_APP_CLIENT_ID = "9cee9ed4-4b89-4b49-b726-0df41fba7519";
    AAD_APP_AUTHORITY = "https://login.microsoftonline.microsoft.scloud/20ac2fc4-effc-4f76-ba7d-63c5ae134f5f";
  }
  // USNat
  if(window.location.host==="usnatazurealerting.appservice.eaglex.ic.gov" || window.location.host==="usnatazurealerting2.appservice.eaglex.ic.gov") {
    AAD_APP_CLIENT_ID = "5314e1b4-d249-41a7-9f2a-ef1c2baf88ca";
    AAD_APP_AUTHORITY = "https://login.microsoftonline.eaglex.ic.gov/70a90262-f46c-48aa-ac4c-37e37f8be1a2";
  }
  return {AAD_APP_CLIENT_ID, AAD_APP_AUTHORITY, AZURE_DEVOPS_SCOPE}
}

export const WAWS_BASE_CONFIG = getBaseEnvVariables();
// export const WAWS_ALERTING_API = getBaseUri();
export const WAWS_ALERTING_API = WAWS_BASE_CONFIG['BaseUri'];
//int func key
export const APP_HOST_KEY_DEFAULT = WAWS_BASE_CONFIG['HostKey']
//prod func key
//export const APP_HOST_KEY_DEFAULT = "heGv5GrDx0np3F1SSqDzLUMgO37fB2CoEWQSkggnQY68e2v0GaoX6A=="

export const BACKEND_SCOPE = "6bda3a57-cd64-42c7-99d2-9be10315332a/wawsalertingbackend";

export const {AAD_APP_CLIENT_ID, AAD_APP_AUTHORITY, AZURE_DEVOPS_SCOPE} = getCloudSpecificVariables();