import { message, Spin } from "antd";
import React from "react";
import {  updateService, TestKustoCluster } from '@utils/helpers';
import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";


type KustoProps = { serviceId: string, accounts: AccountInfo[], instance: IPublicClientApplication };
type KustoState = { cloud: string, description: string, cluster: string, database: string, clustersuffix: string, isSubmitting: boolean };

class KustoForm extends React.Component<KustoProps, KustoState> {
    constructor(props) {
        super(props);
        this.state = {cloud:"Public", description:"West US Kusto", cluster:"[your-service-kusto-cluster-name]", database: "[your-kusto-database-name]", clustersuffix:"Kusto.Windows.Net", isSubmitting: false};
        this.handleChange1 = this.handleChange1.bind(this);
        this.handleChange2 = this.handleChange2.bind(this);
        this.handleChange3 = this.handleChange3.bind(this);
        this.handleChange4 = this.handleChange4.bind(this);
        this.handleChange5 = this.handleChange5.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleChange1(event) {
        this.setState({ cloud: event.target.value });
    }
    handleChange2(event) {
        this.setState({ description: event.target.value });
    }
    handleChange3(event) {
        this.setState({ cluster: event.target.value });
    }
    handleChange4(event) {
        this.setState({ database: event.target.value });
    }
    handleChange5(event) {
        this.setState({ clustersuffix: event.target.value });
    }

    async handleSubmit(event) {
        console.log(JSON.stringify(this.state));
        this.setState({ isSubmitting: true });
        let result = false;
        // bypass test kusto cluster if adding airgap clusters since it will not be able to connect
        if (this.state.cloud.toLowerCase() === "ussec" || this.state.cloud.toLowerCase() === "usnat")
        {
            result = true;
        }
        else {
            result = await TestKustoCluster(this.props.accounts, this.props.instance, JSON.stringify(this.state))
        }
        if(result)
        {
            var res = await updateService(this.props.accounts, this.props.instance, this.props.serviceId, JSON.stringify(this.state), "addkusto");
            if(res.status === 200)
            {
                message.success('Kusto cluster was added successfully.');
            }
            else
            {
                message.error("Failed : " + res.data);
            }
            setTimeout(function() {window.location.reload(); }, 1000);
            event.preventDefault();
        }
        else
        {
            message.error("The Kusto cluster could not be connected at this time. Please check https://azurealerting.trafficmanager.net/faq for requirement.", 3);
        }
        this.setState({ isSubmitting: false });
    }

    render() {
        return (
            <>
                <form onSubmit={this.handleSubmit}>
                    <table>
                        <tr><td style={{textAlign:"right", paddingRight:"10px"}}>
                            <label>Cloud:</label>
                        </td><td>
                                <select value={this.state.cloud} onChange={this.handleChange1} style={{ border: "1px gray solid", width: "300px", paddingLeft:"5px"}}>
                                    <option value="Public">Public</option>
                                    <option value="Mooncake">Mooncake</option>
                                    <option selected value="Fairfax">Fairfax</option>
                                    <option value="USSec">USSec</option>
                                    <option value="USNat">USNat</option>
                                </select></td></tr>
                        <tr><td style={{textAlign:"right", paddingRight:"10px"}}><label>Kusto Description: </label></td><td><input type="text" value={this.state.description} onChange={this.handleChange2} style={{ border: "1px gray solid", width: "300px", paddingLeft:"5px" }} /></td></tr>
                        <tr><td style={{textAlign:"right", paddingRight:"10px"}}><label>Kusto Cluster: </label></td><td><input type="text" value={this.state.cluster} onChange={this.handleChange3} style={{ border: "1px gray solid", width: "300px", paddingLeft:"5px" }} /></td></tr>
                        <tr><td style={{textAlign:"right", paddingRight:"10px"}}><label>Kusto Database: </label></td><td><input type="text" value={this.state.database} onChange={this.handleChange4} style={{ border: "1px gray solid", width: "300px", paddingLeft:"5px" }} /></td></tr>
                        <tr><td style={{textAlign:"right", paddingRight:"10px"}}><label>Kusto Suffix: </label></td><td><input type="text" value={this.state.clustersuffix} onChange={this.handleChange5} style={{ border: "1px gray solid", width: "300px", paddingLeft:"5px" }} /></td></tr>
                       <tr><br /></tr>
                        <tr><td></td><td style={{textAlign:"right", paddingRight:"5px"}}>
                            {this.state.isSubmitting? <Spin /> : <input type="submit" value="Submit" style={{padding:"5px 10px", cursor:"pointer", backgroundColor:"#4CAF50", color:"white"}} />}
                            </td></tr></table>                    
                </form></>
        );
    }
}

export default KustoForm;