import React from 'react';
import 'antd/dist/antd.css';
import { Table, Tag, Space, Card, Form, Select, Button, Radio, Tooltip } from 'antd';
import { useState, useEffect } from 'react'
import { fetchAlerts2, fetchRecentAlertsHistory, fetchAlertHistoryById, fetchActionHistoryById, fetchAlertHistoryRawLogById, fetchAlert, fetchServiceTeams } from '@utils/helpers';
import { useMsal } from '@azure/msal-react';
import { useHistory, useParams } from 'react-router';
import LineChart from '@components/LineChart';
import parse from 'html-react-parser';
import moment from 'moment';
import { message } from "antd";

type IdParams = {
  id : string;
}

function getWindowWidth() {
  const { innerWidth: width, innerHeight: height } = window;
  return width;
}

const isEllipsis = (getWindowWidth()<= 1280) ? true : false;

const columns = [
  {
    title: 'Alert Name',
    dataIndex: 'name',
    key: 'name',
    width: isEllipsis?160:320,
    render: (text, row) => <a href={`/alert/${row.id}`} target="_blank" rel="noopener noreferrer">{text}</a>,
  },
  {
    title: 'Execution Time',
    dataIndex: 'timestamp',
    key: 'timestamp',
    width:160,
  },
  {
    title: 'Execution Id',
    dataIndex: 'execution',
    key: 'execution',
    width:isEllipsis?170:200,
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    width:110,
    render: state => {
      switch (state) {
        case "NotStarted": {
          return (<Tag color={"gray"} >{state.toUpperCase()}</Tag>)
        }
        case "Enqueued": {
          return (<Tag color={"orange"} >{state.toUpperCase()}</Tag>)
        }
        case "Executing": {
          return (<Tag color={"yellow"} >{state.toUpperCase()}</Tag>)
        }
        case "Succeeded": {
          return (<Tag color={"green"} >{state.toUpperCase()}</Tag>)
        }
        case "Skipped": {
          return (<Tag color={"gray"} >{state.toUpperCase()}</Tag>)
        }
        case "Failed": {
          return (<Tag color={"red"} >{state.toUpperCase()}</Tag>)
        }
        case "Paused": {
          return (<Tag color={"orange"} >{state.toUpperCase()}</Tag>)
        }
        case "PartiallyCompleted": {
          return (<Tag color={"red"} >{state.toUpperCase()}</Tag>)
        }
        case "CompletedWithError": {
          return (<Tag color={"red"} >{state.toUpperCase()}</Tag>)
        }
      }
    }
  },
  {
    title: 'Actions',
    key: 'actions',
    width:80,
    dataIndex: 'actions',
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
    ellipsis: isEllipsis,
    render: text => {      
      return  text.indexOf('<') !== -1 ? <div><Tooltip placement="rightTop" overlayStyle={{maxWidth: '700px'}} title={text}>{ text.substring(0, text.indexOf('<')) + "..." }</Tooltip></div> : text;
    },
  },
  {
    title: 'Logs in Kusto',
    key: 'kusto',
    width:120,
    dataIndex: 'kusto',
    render: text => (
      <Space size="middle">
        <a href={`${text}`} target="_blank"  rel="noopener noreferrer" >Check Kusto</a>
      </Space>
    ),
  }
];
const actionColumns = [
  {
    title: 'Action Type',
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: 'Action Time',
    dataIndex: 'time',
    key: 'time',
  },
  {
    title: 'Action Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Action State',
    dataIndex: 'state',
    key: 'state',
    render: state => {
      switch (state) {
        case "NotStarted": {
          return (<Tag color={"gray"} >{state.toUpperCase()}</Tag>)
        }
        case "Running": {
          return (<Tag color={"yellow"} >{state.toUpperCase()}</Tag>)
        }
        case "Succeeded": {
          return (<Tag color={"green"} >{state.toUpperCase()}</Tag>)
        }
        case "Failed": {
          return (<Tag color={"red"} >{state.toUpperCase()}</Tag>)
        }
        case "Paused": {
          return (<Tag color={"orange"} >{state.toUpperCase()}</Tag>)
        }
        case "Skipped": {
          return (<Tag color={"gray"} >{state.toUpperCase()}</Tag>)
        }
        default: {
          return (<Tag color={"gray"} >{"NOTSTARTED"}</Tag>)
        }
      }
    }
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
    ellipsis: false,
    render: text => {      
      return  text.indexOf('<') !== -1 ? <div><Tooltip placement="rightTop" overlayStyle={{maxWidth: '700px'}} title={text}>{ text.substring(0, text.indexOf('<')) + "..." }</Tooltip></div> : text;
    },
  },
  {
    title: 'Log',
    key: 'log',
    dataIndex: 'log',
    render: (text, row) => {
      if( row.type === 'MachineAction') {
        //console.log(text);
        //console.log(row);
        return (        
        <Space size="middle">
                  <a href={`/history?${text}`} target="_blank" rel="noopener noreferrer" >Raw Log</a>
                  &nbsp;                
                  <a href={`/machineactionhistory/${text.split('alertId=')[1]}?state=${row.state==='Failed'?"2":""}&timespan=30m&timestamp=${moment.utc(row.time).add(-1, 'minutes').toISOString()}`} target="_blank" rel="noopener noreferrer" >Machine Action</a>
                </Space>
                )
      }
      if( row.type === 'GenevaAction') {
        return (<Space size="middle">
                  <a href={`/history?${text}`} target="_blank" rel="noopener noreferrer" >Raw Log</a>
                  &nbsp;                
                  <a href={`/genevaactionhistory/${text.split('alertId=')[1]}?state=${row.state==='Failed'?"2":""}&timespan=30m&timestamp=${moment.utc(row.time).add(-1, 'minutes').toISOString()}`} target="_blank" rel="noopener noreferrer" >Geneva Action</a>
                </Space>
                )
      }
      else {
        return (<Space size="middle">
                  <a href={`/history?${text}`} target="_blank" rel="noopener noreferrer" >Raw Log</a>
                </Space>)
      } 
    },
  },
  {
    title: 'Report',
    key: 'urlParameters',
    dataIndex: 'urlParameters',
    render: (text, row) => {
      if( row.type === 'EmailAction' || row.type === 'ICMAction') {
        var typeDesc = row.type === 'EmailAction' ? 'Email Report' : 'IcM Report';
        return (        
        <Space size="middle">
                  <a href={`/history?${text}`} target="_blank" rel="noopener noreferrer" >{typeDesc}</a>
                </Space>
                )
      }
      else {
        return (<Space size="middle">
                </Space>)
      } 
    },
  },
];

const HistoryExecution: React.FC = () => {
  //let [alertId, setAlertId] = useState<string>();
  const [services, setServices] = useState<any>([]);
  const [alerts, setAlerts] = useState<any>([]);
  const { accounts, instance } = useMsal();
  const [alertDetail, setAlertDetail] = useState<any>([]);
  const [alertHistory, setAlertHistory] = useState<any>([]);
  const [actionHistory, setActionHistory] = useState<Map<string, any>>();
  const [alertDescription, setAlertDescription] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const params = new URLSearchParams(window.location.search);
  let [serviceSeleted, setServiceSelected] = useState(params.get("serviceId")?.toString());
  const [alertId, setAlertId] = useState(params.get("alertId")?.toString());
  const [loading, setLoading] = useState<boolean>(false);

  //Use single array to ensure all states set at one time, filters[0] = state, filters[1] = timespan, filters[2] = timestamp, filters[3] = count
  const [filters, setFilters] = useState<string[]>([ params.get("state") !== null ? String(params.get("state")) : "" , params.get("timespan") !== null ? String(params.get("timespan")) : "6h" , params.get("timestamp") !== null ? String(params.get("timestamp")) : "", params.get("count") !== null ? String(params.get("count")) : "200"]);
  //const [previousFilters, setPreviousFilters] = useState<string[]>();

  let { id } = useParams<IdParams>();

  const FetchData = async () => {    
    setLoading(true);

    let historyResult = await fetchRecentAlertsHistory(accounts, instance, filters[3], filters[0], filters[1], filters[2]);
    let tmpData = historyResult.map(item => ({ 
      key:item.AlertEventID, 
      id: item.AlertID, 
      timestamp: moment(item.TimeStamp).format("YYYY-MM-DD HH:mm:ss"), 
      name: item.AlertName, 
      execution: item.AlertEventID, 
      state: item.AlertEventState, 
      actions: item.Actions,
      message: item.Message,
      kusto: item.KustoUrl }));

      var queryStr = `?state=${filters[0]}&timespan=${filters[1]}&timestamp=${filters[2]}&count=${filters[3]}`;
      window.history.replaceState(null, "Alert Execution History", `/history${queryStr}`);
      document.title = "Alert Execution History";
      
    //console.log("setAlertHistory1:",tmpData);
    setAlertHistory(tmpData);

    //setPreviousFilters(filters);
    setLoading(false);
  }

  const fetchServiceData = async (needRefresh = true) => {
    let tableData = await fetchServiceTeams(accounts, instance)
    let tmpData = tableData.map(item => ({ value: item.id, label: item.serviceName }))
    setServices(tmpData);

    let defaultService = "10060";

    if (needRefresh) {
      if (serviceSeleted===""||serviceSeleted===undefined){
        //onServiceChange(tmpData[0].value)
        onServiceChange(defaultService)
      }
    }
    else {
      //setServiceSelected(tmpData[0].value);      
      let params = {} as QueryParams;
      
      if(serviceSeleted===""||serviceSeleted===undefined){
        setServiceSelected(defaultService);
        params.serviceId = defaultService;
      }
      else {
        params.serviceId = serviceSeleted;
      }      
      
      //params.serviceId = tmpData[0].value;
      params.sort = "Title";
      let alertsData = await fetchAlerts2(accounts, instance, params);
      let tmpData2 = alertsData.map(item => ({ value: item.id, label: item.title }));
      setAlerts(tmpData2);
    }
  }

  async function onServiceChange(value) {

    //console.log("onServiceChange:", value);
    //console.log("serviceSeleted:", serviceSeleted);

    if (value !== undefined && value.length > 0 && serviceSeleted !== undefined && serviceSeleted.length > 0 && serviceSeleted !== value){
      setAlerts([]);
      setAlertHistory([]);
      setAlertDetail("");
      setAlertTitle("");
      setAlertDescription("");
      setAlertId("");
    }

    if (value === 'undefined' || !value || !/[^\s]/.test(value)) {
      //为空
    } else {
      setServiceSelected(value);
      let params = {} as QueryParams;
      params.serviceId = value;
      params.sort = "Title";
      let alertsData = await fetchAlerts2(accounts, instance, params);
      let tmpData = alertsData.map(item => ({ value: item.id, label: item.title }));
      setAlerts(tmpData);      
    }
  }

  async function onAlertChange(value) {
    setLoading(true);
    setAlertId(value);
    if (value === 'undefined' || !value || !/[^\s]/.test(value)) {
    } else {
      let alertData = await fetchAlert(value, accounts, instance);
      setAlertDetail(alertData);
      setAlertDescription(alertData.description);
      setAlertTitle(alertData.title);

      if(serviceSeleted !== alertData.serviceId){
        setServiceSelected(alertData.serviceId);
        onServiceChange(alertData.serviceId);
      }

      let historyResult = await fetchAlertHistoryById(accounts, instance, value, filters[0], filters[1], filters[2], filters[3]);
      let tmpData = historyResult.map(item => ({ 
        key:item.AlertEventID, 
        id: value, 
        timestamp: moment(item.TimeStamp).format("YYYY-MM-DD HH:mm:ss"), 
        name: item.AlertName, 
        execution: item.AlertEventID, 
        state: item.AlertEventState, 
        actions: alertData.actions?.length,
        message: item.Message,
        kusto: item.KustoUrl }));              
        
        //console.log("setAlertHistory2:",tmpData);
        setAlertHistory(tmpData);

      var queryStr = `?state=${filters[0]}&timespan=${filters[1]}&timestamp=${filters[2]}&count=${filters[3]}`;
      window.history.replaceState(null, "Alert Execution History: " + alertData.title, `/history/${value}${queryStr}`);
      document.title = "Alert Execution History: " + alertData.title;
    }
    setLoading(false);
  }

  const renderExtraTable =  (row) => {
    //console.log(`expanded ${row.execution}`);
    if (row.execution === 'undefined' || !row.execution || !/[^\s]/.test(row.execution)) {
    } else {
      //let historyResult = (async() =>  await fetchActionHistoryById(accounts, instance, row.execution))();
      //let historyResult = await fetchActionHistoryById(accounts, instance, row.execution);
      //let tmpData = historyResult.map(item => ({ key: item.ActionEventID, id: item.ActionEventID, time: item.TimeStamp, type: item.ActionType, state: item.ActionEventState, eventId: row.execution }));
      //setActionHistory(tmpData);
    }
    return (
      <div style={{ marginLeft: "40px", border: "1px gray solid", fontSize: "10px" }}>
        <Table
          columns={actionColumns}
          dataSource={actionHistory?.get(row.execution)} />
      </div>
    )
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);  
    
    // redirect to raw log
    if(params.get("alertId") && params.get("eventId"))
    {
      fetchAlertHistoryRawLogById(accounts, instance, params.get("alertId"), params.get("eventId")).then(
        res=>{
          if(res !== "")
          {
            window.location.href = res;
          }
          else
          {
            message.error("No report was found for alert event at " + params.get("eventId") + ".");
            setTimeout(function () {
              window.location.href = "/";
          }, 5000);           
          }
        }
      )
    }
    else if (id)
    {
      fetchServiceData(false);           
      onAlertChange(id);             
    }
    else if (alertId){      
      if (serviceSeleted) {
        
        if (services === undefined || services.length === 0){
          fetchServiceData();
        }        

        onServiceChange(serviceSeleted);
        onAlertChange(alertId);      
      }
      else {
        onAlertChange(alertId);      
      }      
    }
    else
    {
      // fetch recent alert execution records if alertId is not specified
      if (!params.get("alertId")) {
        FetchData();
      }

      // initialize service selection dropdown list
      fetchServiceData();

      // initialize page for the service/alert selection
      if (params.get("serviceId")) {
        onServiceChange(params.get("serviceId"));
        if (params.get("alertId")) {
          onAlertChange(params.get("alertId"));
        }
      }
    }

  }, [filters])

  const renderOpenNewTab = (uri) => {
    window.open(uri);
  }

  let hasMachineAction = false;
  let hasGenevaAction = false;
  
  alertDetail?.actions?.forEach(action=>{
    if ('actionType' in action){
      hasMachineAction = true;
    }
    else if ('genevaOperation' in action){
      hasGenevaAction = true;
    }      
  })

  const onChangeTimeRange = e => {
    setFilters([filters[0], e.target.value, "", "" ]);
  };

  const onChangeState = e => {
    setFilters([e.target.value, filters[1],"", "" ]);
  };

  const setFilterSettings = (status: string, num: string, t_stamp: string) => {   
    if (!t_stamp.includes(':')){
      t_stamp = t_stamp + " 00:00";
    }

    var dateObj = new Date();
    var utcMonth = dateObj.getUTCMonth() + 1;
    var month = Number(t_stamp.substring(0, 2));
    
    //Check if selected date cross year. If true, year = current year - 1;
    var year = dateObj.getUTCFullYear();
    if(month > utcMonth && month === 12){
      year = year - 1;
    }
        
    t_stamp = year.toString() + '-' + t_stamp;  
    var isoDateTime = moment.utc(t_stamp);   

    //limit max row count to 2000
    var count = Number(num);
    if (count > 2000){
      num = "2000";
    }

    setFilters([status, filters[1], isoDateTime.toISOString(), num]);
  }

  return (
    <div style={{ padding: "10px" }}>
      <div style={{ display: "flex", width: "100%" }} >
        <Card style={{ width: "100%", border: "red 0px solid", padding:"10px" }}>
          <div style={{ width: "100%", float: "left", border: "red 0px solid", padding:"0px" }}>
            <Form.Item style={{ minWidth: "35%", float: "left" }} label="- Select Service: " rules={[{ required: true, message: "You must provide a Service Name" }]}>
              <Select options={services} defaultValue={serviceSeleted} key={serviceSeleted} optionFilterProp="label" showSearch={true} onChange={onServiceChange}>
              </Select>
            </Form.Item>
            <Form.Item style={{ minWidth: "35%", float: "left", paddingLeft: "10px" }} label="Select Alert: " rules={[{ required: true, message: "You must provide a Service Alert" }]}>
              <Select options={alerts} defaultValue={alertId} value={alertId} optionFilterProp="label" showSearch={true} onChange={onAlertChange}>
              </Select>
            </Form.Item>
            <Space size="middle">&nbsp;&nbsp;&nbsp;</Space>
            {hasMachineAction? <Button type="primary" onClick={()=>renderOpenNewTab(`/machineactionhistory/${alertDetail.id}?state=${filters[0]==='Failed'?"2":""}&timespan=${filters[1]}`)}>
                Machine Action History
              </Button> : ""}
            {hasGenevaAction? <Button type="primary" onClick={()=>renderOpenNewTab(`/genevaactionhistory/${alertDetail.id}?state=${filters[0]==='Failed'?"2":""}&timespan=${filters[1]}`)}>
                Geneva Actions History
              </Button> : ""}
          </div>
          <div style={{ width: "100%", float: "left", border: "red 0px solid", padding:"0px" }}>
            <Form.Item style={{ minWidth: "35%", float: "left", paddingLeft: "10px" }} label="State: " rules={[{ required: false, message: "Filter by state." }]}>
              <Radio.Group onChange={onChangeState} defaultValue={filters[0]} value={filters[0]}>
                <Radio.Button value="All">All</Radio.Button>
                <Radio.Button value="Enqueued">Enqueued</Radio.Button>
                <Radio.Button value="Executing">Executing</Radio.Button>
                <Radio.Button value="Succeeded">Succeeded</Radio.Button>
                <Radio.Button value="Failed">Failed</Radio.Button>
                <Radio.Button value="PartiallyCompleted">PartiallyCompleted</Radio.Button>
                <Radio.Button value="CompletedWithError">CompletedWithError</Radio.Button>
                <Radio.Button value="Skipped">Skipped</Radio.Button>
              </Radio.Group>
            </Form.Item>
           </div>
          <div style={{border:"0px red solid", float:"left", width:'100%'}}>
            <div style={{float:'left', width:'100%', border: "red 0px solid", padding:"0px"}}>
              <Form.Item style={{ float:'left'}}>
                <Card title={alertTitle? alertTitle : "Alert Execution Statistics"} bordered={true} style={{width:'39vw'}}>
                  <Radio.Group onChange={onChangeTimeRange} defaultValue={filters[1]} value={filters[1]}>
                      <Radio value="30d">1 Month</Radio>
                      <Radio value="14d">2 Weeks</Radio>
                      <Radio value="7d">1 Week</Radio>
                      <Radio value="3d">3 Days</Radio>
                      <Radio value="1d">1 Day</Radio>                      
                      <Radio value="6h">6 Hours</Radio>
                      <Radio value="1h">1 Hour</Radio>
                      <Radio value="30m">30 Mins</Radio>
                  </Radio.Group>
                  <LineChart alertId={alertDetail.id? alertDetail.id : ""} timeRange={filters[1]} alertName="" type="Alert" onClickChartElement={setFilterSettings}/>
                </Card>
              </Form.Item>
              <Form.Item style={{float:'left'}}>
                <Card bordered={false}>
                  <div style={{width:'31vw', overflow:'hidden'}}>{parse(alertDescription)}</div>
                  <label>{alertDetail.description? "Created by " : ""}{alertDetail.createdBy}{alertDetail.description? ". " : ""}</label>
                  <br/><a href={`/service/${serviceSeleted}`} target="_blank" rel="noopener noreferrer">{alertDetail.description? "Check Service Detail." : ""}</a>
                </Card>
              </Form.Item>
            </div>
          </div>
        <Table
          columns={columns}
          dataSource={alertHistory}
          loading={loading}
          expandable={{
            expandedRowRender: record => renderExtraTable(record)
          }}
          onExpand = {async (expanded, record) =>{
            if(expanded === true)
            {
              let historyResult = await fetchActionHistoryById(accounts, instance, record.execution);
              let tmpData = historyResult.map(item => ({ 
                key: item.ActionEventID, 
                id: item.ActionEventID, 
                time: moment(item.TimeStamp).format("YYYY-MM-DD HH:mm:ss"), 
                type: item.ActionType, 
                state: item.ActionEventState, 
                eventId: record.execution,
                alertId: item.AlertID,
                message: item.Message,
                log: "eventId=" + record.execution +"&alertId="+ item.AlertID,
                urlParameters: "eventId=" + record.execution + "-" + item.ActionEventID +".html&alertId="+ item.AlertID,
               }));
              var actionsCache = actionHistory;
              if(!actionsCache)
              {
                actionsCache = new Map( [[record.execution, tmpData]]);
              }
              else
              {
                //actionsCache?.set(record.execution, tmpData)
                actionsCache = new Map(actionsCache);
                actionsCache?.set(record.execution, tmpData)
                //actionsCache = new Map( [[record.execution, tmpData]]);
              }
              //actionsCache.push({[record.execution]: tmpData});
              //setActionHistory( new Map( [[record.execution, tmpData]])); 
              setActionHistory( actionsCache); 
            }
          }}
          />
        </Card>
      </div>
    </div>

  )
}

export default HistoryExecution;

