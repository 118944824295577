import { Tag, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

type EditableTagGroupProps = {
    tags : string[];
    setTags : (tags : string[]) => void;
    readonly: boolean;
    item : string;
};


const EditableTagGroup: React.FC<EditableTagGroupProps> = ({tags, setTags, readonly, item}) => {
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const handleInputConfirm = () => {
        // need to make sure the tag is unique :)
        if (inputValue === ''){
          setInputVisible(false);
          return;
        }
        if (tags.indexOf(inputValue) < 0)
        {
            setTags([...tags, inputValue.trim()]);
            setInputVisible(false);
            setInputValue("");
        }
    }

    const renderAddInput = () => {
        if(inputVisible)
        {
            return(
                <Input
                  disabled={readonly}
                  style={{width:"78px", marginRight:"8px", verticalAlign:"top"}}
                  type="text"
                  size="small"
                  className="tag-input"
                  value={inputValue}
                  onChange={(e)=>{setInputValue(item==="Tag"?e.target.value.toLowerCase():e.target.value)}}
                  onBlur={handleInputConfirm}
                  onPressEnter={handleInputConfirm}
                  autoFocus
                />
            )
        } else
        {
            return(
                <Tag className="site-tag-plus" onClick={() => {setInputVisible(!readonly)}}>
                  <PlusOutlined disabled={readonly}/> New {item}
                </Tag>
            )
        }
    }

    const handleClose = (removedTag : string)=> {
        const newTags = tags.filter(tag => tag !== removedTag);
        console.log(newTags);
        setTags(newTags);
    };

    return (
        <div>
            {tags.map((tag, index) => {
                return(
                    <Tag color={item==="Tag"?"processing":"warning"} className="edit-tag" key={tag} closable={!readonly} onClose={() => handleClose(tag)}>
                        {tag}
                    </Tag>
                )
            })}
            {renderAddInput()}
        </div>
    );
}


export default EditableTagGroup;