import React from 'react';
import { Spin } from 'antd';
import { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react';
import { fetchAlertOverallChart } from '@utils/helpers';
import { Pie } from 'react-chartjs-2';

type AlertChartProps = {
  alertId: string;
  days: string;
  onClickChartElement: (status: string, num: string, t_stamp: string) => void;
};

const PieChart: React.FC<AlertChartProps> = ({ alertId, days, onClickChartElement }) => {
  const [chartData, setChartData] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  //let days = "30";
  const { accounts, instance } = useMsal();

  const FetchData = async () => {
    setLoading(true);
    var chartResults = await fetchAlertOverallChart(accounts, instance, days);

    var tmpData = chartResults.map(item => ({
      state: item.AlertEventState,
      count: item.count_
    }));

    setLoading(false);
    setChartData(tmpData);    
  }

  //FetchData();

  useEffect(() => {
    FetchData();
  }, [days]);

  const getRGB = (status) => {
    switch (status) {
        case 'NotStarted':
            return '255, 99, 152';
        case 'Skipped':
            return '179, 179, 255';
        case 'Enqueued':
            return '0, 153, 51';
        case 'Executing':
            return '54, 162, 235';
        case 'Succeeded':
            return '135, 219, 32';
        case 'Failed':
            return '255, 99, 132';
        case 'Paused':
            return '155, 199, 52';
        case 'PartiallyCompleted':
            return '51, 204, 204';            
        case 'CompletedWithError':
            return '255, 159, 64';          
        return '';
        default:
            return '255, 255, 255';
    }
}

  var labels = ['a', 'b'];
  var values = [0, 1];
  var bgcolors = ['a', 'b'];
  var bdcolors = ['a', 'b'];

  labels = [];
  values = [];
  bgcolors = [];
  bdcolors = [];
 
  //console.log(chartData);
  //Currently we only have one element in the array because fetchAlertOverallChart is used for PieChart.
  chartData.forEach(element => {
    labels.push(element.state);
    values.push(element.count);
    bgcolors.push('rgba(' + getRGB(element.state) + ', 0.2)');
    bdcolors.push('rgba(' + getRGB(element.state) + ', 1)');
  });

  function OnClick(evt, element) {

    if(element.length > 0)
    {
      var countIndex = element[0].index;
      var status = labels[countIndex];

      if (countIndex !== undefined && status !== undefined) {
        var count = data.datasets[element[0].datasetIndex].data[countIndex];

        // console.log(status);
        // console.log(count);

        onClickChartElement(status, "", "");
      }
    }
  };

  var data = {

    labels: labels,

    datasets: [
      {
        label: 'Num',
        data: values,
        backgroundColor: bgcolors,
        borderColor: bdcolors,
        borderWidth: 1,
      },
    ],
  };

  let position : "right" | undefined = "right";
  //let progress = document.getElementById('initialProgress') as HTMLProgressElement;

  var options = {
    onClick: OnClick,
    onHover: (event, chartElement) => {
      const target = event.native ? event.native.target : event.target;
      target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
    // animation: {
    //   onProgress: function(animation) {
    //       progress.value = animation.currentStep / animation.numSteps;
    //   }
    // },
    layout: {
      padding: {
          left: 0,
          top: 0,
          buttom: 0,
      }
    },
    responsive: false,
    plugins: {
      legend: {
        position: position
      },
        title: {
          display: false,
          text: 'Alerts Execution Overview'
      },
    },
  };

  return (
    <Spin tip="Loading data..." size="default" spinning={loading} delay={500}>
      <Pie data={data} options={options} width="420" height="250"/>
    </Spin>
  );

}
export default PieChart;