import React from 'react';
import { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react';
import { fetchAlertOverallChart } from '@utils/helpers';
import { Bar } from 'react-chartjs-2';

type AlertChartProps = {
  alertId: string;
  days: string;
  onClickChartElement: (status: string, num: string, t_stamp: string) => void;
};

const getRGB = (status) => {
  switch (status) {
      case 'NotStarted':
          return '255, 99, 152';
      case 'Skipped':
          return '153, 204, 255';
      case 'Enqueued':
          return '235, 119, 132';
      case 'Executing':
          return '51, 102, 204';
      case 'Succeeded':
          return '135, 219, 32';
      case 'Failed':
          return '255, 51, 0';
      case 'Paused':
          return '155, 199, 52';
      case 'PartiallyCompleted':
          return '51, 204, 204';
      case 'CompletedWithError':
          return '204, 153, 0';
      default:
          return '255, 255, 255';
  }
}

const HorizontalBarChart: React.FC<AlertChartProps> = ({ alertId, days, onClickChartElement }) => {
  const [chartData, setChartData] = useState<any>([]);

  //let days = "30";
  const { accounts, instance } = useMsal();

  const FetchData = async () => {
    var chartResults = await fetchAlertOverallChart(accounts, instance, days);

    var tmpData = chartResults.map(item => ({
      state: item.AlertEventState,
      count: item.count_
    }));

    setChartData(tmpData);
  }

  //FetchData();

  useEffect(() => {
    FetchData();
  }, [days]);

  var labels = ['a', 'b'];
  var values = [0, 1];

  labels = [];
  values = [];

  //console.log(chartData);

  chartData.forEach(element => {
    labels.push(element.state);
    values.push(element.count);
  });

  var backgroundColors = ['a','b'];
  var borderColors = ['a','b'];
  backgroundColors = [];
  borderColors = [];

  labels.forEach(element => {
    backgroundColors.push('rgba(' + getRGB(element) + ', 0.2)');
    borderColors.push('rgba(' + getRGB(element) + ', 1)')
  });

  function OnClick(evt, element) {

    if(element.length > 0)
    {
      var countIndex = element[0].index;
      //var status = data.datasets[element[0].datasetIndex].label;
      var status = labels[countIndex];

      if (countIndex !== undefined && status !== undefined) {
        var count = data.datasets[element[0].datasetIndex].data[countIndex];

        // console.log(status);
        // console.log(count);
        // console.log(timeStamp);

        onClickChartElement(status, count.toString(), "");
      }
    }
  };

  var data = {
    labels: labels,
    datasets: [
      {
        label: 'Num',
        data: values,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  let indexAxis : "y" | undefined = "y";
  let position : "right"| undefined = "right";

  const options = {
    onClick: OnClick,
    onHover: (event, chartElement) => {
      const target = event.native ? event.native.target : event.target;
      target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
    indexAxis: indexAxis,
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: position,
      },
      title: {
        display: false,
        text: 'Execution Results',
      },
    },
  };

  return (
    <Bar data={data} options={options} />
  );

}
export default HorizontalBarChart;