export const StandardTimeMap = {
    0: 'Never',
    1: 'Every minute',
    2: 'Every 5 minutes',
    3: 'Every 15 minutes',
    4: 'Every 30 minutes',
    5: 'Every hour',
    6: 'Every 4 hours',
    7: 'Daily',
    8: 'Weekly'
}

export const WeekdayMap = {
    0: 'Monday',
    1: 'Tuesday',
    2: 'Wedenesday',
    3: 'Thursday',
    4: 'Friday',
    5: 'Saturday',
    6: 'Sunday',
    7: 'Everyday'
}

export const DefaultMooncakeCluster = ["[\"Mooncake;cnwsbjs20.chinanorth2.kusto.chinacloudapi.cn;cnwsprod\"]"]
export const DefaultFairfaxCluster = ["[\"Fairfax;gcwsbn1ff.kusto.usgovcloudapi.net;gcwsprod\"]"]
export const DefaultUSSecCluster = ["[\"USSec;antareseast.usseceast.kusto.core.microsoft.scloud;antaresprod\"]"]
export const DefaultUSNatCluster = ["[\"USNat;antareseast.ussecnat.kusto.core.eaglex.ic.gov;antaresprod\"]"]
export const DefaultMooncakeDbname = "cnwsprod"
export const DefaultFairfaxDbname = "gcwsprod"
export const DefaultUSSecDbname = "antaresprod"
export const DefaultUSNatDbname = "antaresprod"