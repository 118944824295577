import React, { CSSProperties, useEffect, useState } from 'react';
import {
  Form,
  BackTop,
  Button,
  Select,
  Space,
  Tag,
  message,
  Alert
} from 'antd';
import { useMsal } from '@azure/msal-react';
import { fetchGenevaActionGroupOpts, getGenevaActionRequest, getKustoResultsWithColumns } from '@utils/helpers';
import GenevaTable from './GenevaTable';
import '../../../styles/base.less';
import { UpCircleOutlined, InfoCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { NationalClouds } from '@constants/Enums';

type GenevaFormProps = {
  active: boolean;
  isDisabled: boolean;
  serviceId: string;
  extensionOptions: any[];
  restrictedOperations: string[];
  kustoQuery: string;
  kustoClusters: string[];
  MooncakeQuery: string;
  FairfaxQuery: string;
  MooncakeClusters: any[];
  FairfaxClusters: any[];
  getGenevaData: Function;
  genevaSaveLoading: boolean;
  existingRequest: any;
  validateNCloudQuery: number;
  onValidationSuccess : (s: boolean) => void;
  onValidateNCloudQuery : (s: boolean) => void;
  dSTSEnabled: boolean;
};

const GenevaActionsFormItem: React.FC<GenevaFormProps> = ({ active, isDisabled, serviceId, dSTSEnabled, extensionOptions, restrictedOperations, kustoQuery, kustoClusters, MooncakeQuery, FairfaxQuery, MooncakeClusters, FairfaxClusters, existingRequest, getGenevaData, genevaSaveLoading, validateNCloudQuery, onValidationSuccess, onValidateNCloudQuery }) => {
  const { accounts, instance } = useMsal();
  const [tagValues, setTagValues] = useState<string[]>([]);
  const [missingColumns, setmissingColumns] = useState<string[]>([]);

  const [genevaAction, setGenevaAction] = useState<any>([]);
  const [actions, setActions] = useState<any[]>([]);
  const [extension, setExtension] = useState<string>("");
  
  const [groups, setGroups] = useState<any[]>([]);
  const [groupSelected, setGroupSelected] = useState<string>("");
  const [operationList, setOperationList] = useState<any[]>([]);
  const [usedSTS, setUsedSTS] = useState<boolean>(false);
  
  const [request, setRequest] = useState<string>("");
  const [operationDetails, setOperationDetails] = useState<string>("");
  const [response, setResponse] = useState<string>("");
  const [parameters, setParameters] = useState<string[]>([]);

  const [kustoQueryloading, setKustoQueryloading] = useState<boolean>(false);
  const [validationSuccess, setValidationSuccess] = useState<boolean>(false);
  const [loadCompleted, setLoadCompleted] = useState<boolean>(false);

  const alias = accounts[0].username.split("@", 1)[0];

  const defaultRequest = "";
  const defaultParameters = [];

  useEffect(() => {

    if (validateNCloudQuery === NationalClouds.Mooncake || validateNCloudQuery === NationalClouds.Fairfax){
      validateColumns(true);
    }

    if (!loadCompleted && existingRequest["extension"] !== undefined && existingRequest["extension"]?.length > 0 ) {      
      setExtension(existingRequest["extension"]);      
    }

    if (!loadCompleted && existingRequest["usedSTS"] !== undefined ) {      
      setUsedSTS(existingRequest["usedSTS"]);      
    }

    if ((groups === undefined || groups.length === 0) && (extension !== undefined && extension.length > 0)) {
      fetchDetailsGroup(extension);
    }

    if (!loadCompleted && existingRequest["group"] !== undefined && existingRequest["group"]?.length > 0 ) {      
      setGroupSelected(existingRequest["group"]);
      onGroupChange(existingRequest["group"]);
    }

    if (!loadCompleted && existingRequest["id"] !== undefined && existingRequest["id"]?.length > 0 ) {
      setGenevaAction(existingRequest["id"]);
      onGenevaActionChange(existingRequest["id"]);      
    }  

    if(!loadCompleted && genevaAction.length > 0 && operationDetails.length > 0 && request.length > 0){
      validateColumns();
      setLoadCompleted(true);
    }
  }, [existingRequest, extension, request, operationDetails, validateNCloudQuery]);

  /*----------------------------------------- Event Hook Functions -------------------------------------*/

  const fetchDetailsGroup = async (ext:string) => {

    let groupDetails = await fetchGenevaActionGroupOpts(accounts, instance, ext, serviceId);

    if (groupDetails["Operations"] !== undefined && groupDetails["Operations"].length > 0) {
      setOperationList(groupDetails["Operations"]);
    }

    let groupArray = groupDetails["OperationGroups"];

    let groupOpts: any = [];
    groupArray.forEach(element => {
      let entry = { label: element, value: element }
      groupOpts.push(entry);
    });
    setGroups(groupOpts);
  }

  async function onExtensionChange(value) {
    setExtension(value);
    setGroups([]);
    setActions([]);
    setGroupSelected("");
    setGenevaAction("");
    setRequest("");
    setOperationDetails("");
    setUsedSTS(false);  

    if (value === 'undefined' || !value || !/[^\s]/.test(value)) {
      setGroups([]);
    } else {
      fetchDetailsGroup(value);
    }
  }

  async function onGroupChange(value) {

    setGroupSelected(value);

    if (value === 'undefined' || !value || !/[^\s]/.test(value)) {
      setActions([]);

    } else {      
      
      let actionList = operationList.filter(opt => opt["OperationGroup"] === value && !restrictedOperations.includes(opt["Id"]));
      let tmpData = actionList.map(item => ({ value: item.Id, label: item.Name }));

      setGroupSelected(value);
      setActions(tmpData);
    }
  }

  async function onGenevaActionChange(genevaAction) {

    if (extension === undefined || extension.length === 0 || genevaAction === undefined || !genevaAction || !/[^\s]/.test(genevaAction)) {
    }
    else {
      let operationDetails = await getGenevaActionRequest(accounts, instance, extension, genevaAction, serviceId);
      let operationRequest = operationDetails.request;

      setGenevaAction(genevaAction);
      setParameters(Object.keys(operationRequest["parameters"]));

      if(usedSTS === true){ 
        operationRequest["usedSTS"] = true;
      }

      setRequest(JSON.stringify(operationRequest, null, "\t"));
      setOperationDetails(JSON.stringify(operationDetails, null, "\t"));

    }
  }

  const fetchActionsOnClick = async () => {
    if (groupSelected !== undefined && groupSelected.length > 0 ) 
    { 
      onGroupChange(groupSelected) 
    }
  }

  const getColumns = async () => {

    setValidationSuccess(false);

    if (kustoClusters === undefined || kustoClusters.length === 0) {

      message.error(`The selected Kusto clusters are invalid or empty.`, 4);

    }
    else if (kustoQuery === undefined || kustoQuery.length === 0) {
      message.error(`The Kusto query is invalid or empty.`, 4);
    }
    else {
      setKustoQueryloading(true);

      let runResult = await getKustoResultsWithColumns(accounts, instance, kustoQuery, kustoClusters, serviceId);

      if (runResult.result) 
      {
        setTagValues(runResult.columns);

        var operationRequestStr = "";
        if (!request || request.length === 0) {
          operationRequestStr = defaultRequest;
        }
        else {
          operationRequestStr = request;
        }

        var exitFlag = false;

        if (operationRequestStr === "") {
          message.error(`Please select Geneva action group and operation from dropdown list.`, 4);
          exitFlag = true;
        }
        else {

          var operationRequestJSON = JSON.parse(operationRequestStr);
          var missing: string[];
          missing = [];        
          
          Object.keys(operationRequestJSON["parameters"]).forEach(function (key) {
            if (operationRequestJSON["parameters"][key].startsWith('<@') && !exitFlag) {             

              if (!runResult.columns.includes(key)) {
                missing.push(key);                
              }
            }
          });

          var resultsCols = runResult.columns;

          if (!resultsCols.includes("Endpoint") && !resultsCols.includes("endpoint") && !resultsCols.includes("ENDPOINT")) {
            missing.push("endpoint");
          }

          if (missing.length > 0) {
            exitFlag = true;
            setmissingColumns(missing);
            message.error(`Kusto query results don't contain parameter: ${missing.join(', ')}`, 4);
          }

        }
        if (exitFlag === false) {
          setmissingColumns([]);

          message.success(`Kusto query validation succeeded.`, 4);
          onValidationSuccess(true);
          setValidationSuccess(true);

        }
      } 
      else 
      {
        if (runResult.message !== undefined) {
          message.error(`${runResult.message}`, 8);
        }
        else {
          message.error(`${runResult.data}`, 8);
        }
      }

      if(usedSTS === true){
        var requestJSON = JSON.parse(request);      
        requestJSON["usedSTS"] = true;
        
        var requestString = JSON.stringify(requestJSON, null, "\t");
        setRequest(requestString);
        getGenevaData(requestString);        
      }
      else {
        getGenevaData(request);
      }      
      setKustoQueryloading(false);
    }
  }

  const validateColumns = async (isNCloudQuery = false) => {

    if(isNCloudQuery){
      if (validateNCloudQuery === NationalClouds.Mooncake){
        kustoClusters = MooncakeClusters;
        kustoQuery = MooncakeQuery;
      }
      else if (validateNCloudQuery === NationalClouds.Fairfax) {
        kustoClusters = FairfaxClusters;
        kustoQuery = FairfaxQuery;
      }
    }

    if (kustoClusters === undefined || kustoClusters.length === 0) {
    }
    else if (kustoQuery === undefined || kustoQuery.length === 0) {
    }
    else {      

      let runResult = await getKustoResultsWithColumns(accounts, instance, kustoQuery, kustoClusters, serviceId);

      if (runResult.result) {
        setTagValues(runResult.columns);

        var operationRequestStr = "";
        if (!request || request.length === 0) {
          operationRequestStr = defaultRequest;
        }
        else {
          operationRequestStr = request;
        }

        var exitFlag = false;

        if (operationRequestStr === "") {
          exitFlag = true;
        }
        else {

          var operationRequestJSON = JSON.parse(operationRequestStr);
          var missing: string[];
          missing = [];

          Object.keys(operationRequestJSON["parameters"]).forEach(function (key) {
            if (operationRequestJSON["parameters"][key].startsWith('<@') && !exitFlag) {
                if (!runResult.columns.includes(key)) {
                  missing.push(key);
                }              
            }
          });

          var resultsCols = runResult.columns;

          if (!resultsCols.includes("Endpoint") && !resultsCols.includes("endpoint") && !resultsCols.includes("ENDPOINT")) {
            missing.push("endpoint");
          }

          if (missing.length > 0) {
            exitFlag = true;
            setmissingColumns(missing);

            if(isNCloudQuery){
              message.error(`Geneva Action Kusto query validation failed in National Cloud. Some required columns are missing: ${missing.join(', ')}`, 4);              
              onValidateNCloudQuery(false);
            }
          }
        }

        if (!exitFlag) {
          setmissingColumns([]);

          if(isNCloudQuery){
            message.success('Geneva Action Kusto query validation succeeded in National Cloud.', 4);
            onValidateNCloudQuery(true);         
          }
        }
      }
      else if (isNCloudQuery)
      {
        if (runResult.message !== undefined) {          
          message.error(`Geneva Action Kusto query validation failed in National Cloud. ${runResult.message}`, 8);
          onValidateNCloudQuery(false);
        }
        else {
          message.error(`Geneva Action Kusto query validation failed in National Cloud. ${runResult.data}`, 8);
          onValidateNCloudQuery(false);
        }
      }

      if(usedSTS === true){
        var requestJSON = JSON.parse(request);      
        requestJSON["usedSTS"] = true;
        
        var requestString = JSON.stringify(requestJSON, null, "\t");
        setRequest(requestString);
        getGenevaData(requestString);        
      }
      else {
        getGenevaData(request);
      }   
    }
  }

  const ondSTSChange = e => {
    setUsedSTS(e.target.value);

    if (e.target.value === true && request && request.length > 0) {
      var operationRequestJSON = JSON.parse(request);      
      operationRequestJSON["usedSTS"] = true;
      setRequest(JSON.stringify(operationRequestJSON, null, "\t"));
    }
  };

  const styling: CSSProperties = { width: "100%" }

  if (active) {
    let throttlingMsg = <div>
      <b>No Throttling on Geneva Actions</b>: AzureAlerting does not impose throttling on Geneva actions. Ensure that the action will not be triggered multiple times within a short timeframe. 
      Additionally, configure Geneva policies for your service using<a href="https://eng.ms/docs/products/oneaccess/geneva-actions/policy" style={{marginLeft: "5px"}}>Geneva Actions integration with Policy subsystem (eng.ms)</a>.</div>
    return (
      <Form.Item noStyle>
          <Space direction="vertical" style={styling}>
            <h3>Geneva Actions</h3>                         
              <div>
                <Alert message="Be Careful with Geneva Actions" type="error" 
                    description={<div>
                      {serviceId == "10060"?                        
                      <ol style={{marginTop: "10px"}}>
                          <li>{throttlingMsg}</li>
                          <li><b>Go to Machine Actions to perform VM Operations</b>:
                            Please be advised that Machine action is always preferred way to perform VM operations like reboot/reimage/deleteinstance in AzureAlerting.
                          </li>
                          <li>
                            <b>Restricted Actions and Permissions</b>:
                            <ul>
                            <li>Geneva action runner of Alerting has been granted with Antares-PlatformServiceAdministrator claim but it doesn't have permission  for operations requiring <b>SuperUser</b> access.</li>
                          <li>By default, <b>operations not in use are added to the restricted operations list</b>. If you need to use an operation not displayed in the dropdown list,  please contact: <a href='mailto:antmonchamp@microsoft.com'>antmonchamp</a>.</li>
                          <li>AntaresCmd(<b>RunSuperUserCommand</b> and <b>RunLimitedUserCommand</b>) is restricted in AzureAlerting due to security concern.</li>
                            </ul>
                          </li>
                         
                      
                      </ol>:<>{throttlingMsg}</>
                    }
                    </div>}
                    icon = {<WarningOutlined />}
                    showIcon />
                <Alert style={{marginTop: "20px", marginBottom: "20px", backgroundColor: "#f5f5f5", border: "1px solid #a5a5a5"}}  type="info" message="Tips"
                    description={
                      <div>Be careful and test on Geneva portal before adding any Geneva action alert that can affect production. Also, it's always a good idea to have email-only for some test period and validate that all data which would be used for auto-mitigations work as expected.
                        <ul style={{marginTop: "10px"}}>
                        <li>Please press <b>Validate Geneva Kusto Query</b> button to validate Kusto query. Make sure it returns all required parameters of selected geneva action. For example: </li>
                        <div style={{marginLeft:20,color:"darkred", fontSize:"80%"}}>
                            <br/>
                            smestampnameparam = EventStampName, smeroleinstanceparam = RoleInstance <br/>
                            smelocationparam = split(EventStampName,'-')[2], smeroleparam = split(RoleInstance,'_')[0] <br/>
                            <br/>
                          </div>
                        <li>Kusto columns correctly binded with parameters are highlighted in Green. Missing columns are in Red.</li>                                    
                        <li>Endpoint is also read from corresponding column of Kusto result. For instances, endpoints of Antares can be set as:
                          <div style={{marginLeft:20,color:"darkred", fontSize:"80%"}}>
                            <br/>
                            endpoint = strcat('Antares - WAWSPROD', toupper(split(EventStampName, '-')[2]), 'RGM Geomaster')<br/>
                            endpoint = strcat('Antares ', tolower(EventStampName))<br/>
                            endpoint = 'Antares - WAWSPRODSN1 Geomaster'<br/>
                            <br/>
                          </div>
                        </li>
                        <li>
                          AAD authentication is used by default. However Swagger/OpenAPI based Geneva operations only support dSTS delegated authentication.
                          In this case, a new service client identity for dSTS authentication needs to be configured. Please contact: <a href='mailto:antmonchamp@microsoft.com'>antmonchamp</a> to enable this feature if required.
                        </li>
                      </ul>
                      </div>       
                    }
                showIcon />         
              </div>
                            
              <Form.Item label="Geneva Extension: " rules={[{ required: true, message: "You must select an Extension" }]}>
                <Select style={{width:"60%"}} options={ extensionOptions } value={extension} optionFilterProp="label" showSearch={true} onChange={onExtensionChange}>
                </Select>                
                {(extensionOptions === undefined || extensionOptions.length <= 0)?<div style={{color:"red"}}>No Geneva extension(s) is configured. Service owner needs to go to Service Onboard page to select required Geneva extensions for this service.</div>:""}
              </Form.Item>

              <Form.Item label="Operation Group: " rules={[{ required: true, message: "You must provide a Group Name" }]}>
                <Select style={{width:"60%"}} options={groups} value={groupSelected} optionFilterProp="label" showSearch={true} onChange={onGroupChange}>
                </Select>
              </Form.Item>
              <Form.Item label="Operation: " rules={[{ required: true, message: "You must select an Operation" }]}>
                <Select style={{width:"60%"}} options={actions} value={genevaAction} optionFilterProp="label" showSearch={true} onClick={fetchActionsOnClick} onChange={onGenevaActionChange}>
                </Select>
              </Form.Item>
              
              {/* <Form.Item label="Authentication Type: " rules={[{ required: true, message: "You must select an Authentication type" }]}>
                <Radio.Group defaultValue={usedSTS} value={usedSTS} onChange={ondSTSChange}>
                      <Radio value={false}><Tooltip title="Please make sure required RBAC claim has been assigned in Service Management page for AAD authentication. Swagger/OpenAPI based Geneva operations only support dSTS delegated authentication.">Use AAD authentication <QuestionCircleOutlined /></Tooltip></Radio>
                      <Radio disabled={!dSTSEnabled} value={true}><Tooltip title="Swagger/OpenAPI based Geneva operations only support dSTS delegated authentication.">Use dSTS authentication <QuestionCircleOutlined /></Tooltip></Radio>                   
                </Radio.Group>
                {(dSTSEnabled === false || dSTSEnabled === undefined) ? <div style={{color:"red"}}> (A new service client identity needs to be configured for dSTS authentication. Please contact: <a href='mailto:antmonchamp@microsoft.com'>antmonchamp</a> to enable this feature if required.)</div>:""}                
              </Form.Item> */}

              <Form.Item style={{ marginTop: "0px" }}>              
                <Button type="primary" disabled={false} loading={kustoQueryloading} onClick={getColumns}>Validate Geneva Kusto Query</Button>
              </Form.Item>

              <GenevaTable readOnly={isDisabled} details={operationDetails} content={(request.length === 0 && JSON.stringify(existingRequest,null,"\t").length > 3) ? JSON.stringify(existingRequest,null,"\t") : request}
                    accounts={accounts} instance={instance} />

              <Form.Item label="Kusto Columns">                
                  {tagValues.map(tag => (<Tag vertical-align="buttom" color={((request.length === 0 ? defaultRequest : request).indexOf(tag) !== -1) || tag.toLowerCase() === "endpoint" ? "cyan" : "purple"}>{tag}</Tag>))}
                  <p style={{marginBottom: "-7px", color:"lightseagreen", visibility: ( tagValues === undefined || tagValues.length === 0) && (missingColumns === undefined || missingColumns.length === 0) ? "visible":"hidden"}}>(press 'Validate Geneva Kusto Query' to retrieve columns list.)</p>                
              </Form.Item>
              <Form.Item style={{ marginTop: "0px", visibility: (missingColumns === undefined || missingColumns.length === 0)?"hidden":"visible" }} label={<div style={{ color: "lightcoral" }}> Missing column(s)</div>}>
                {(missingColumns === undefined || missingColumns.length === 0) ? "" : missingColumns.map(tag => (<Tag color="red">{tag}</Tag>))}
                <p style={{color:"green", visibility: validationSuccess?"visible":"hidden"}}>Validation passed. All required parameters of the operation have been provided by Kusto query result.</p>
              </Form.Item>
            
          </Space>          
          <BackTop style={{ marginBottom: '5%', marginRight: '20px' }}><UpCircleOutlined style={{ fontSize: '400%', color: '#3F51B5' }} /></BackTop>
        </Form.Item>
     )
  }
  else {
    return (<div></div>);
  }
};
export default GenevaActionsFormItem