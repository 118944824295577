import React from 'react';
import 'antd/dist/antd.css';
import { Table, Tag, Card, Tabs, Input, Form, Select, Radio, Space, Tooltip } from 'antd';
import { useState, useEffect } from 'react'
import { fetchMachineActionHistory, fetchMachineActionAlerts, fetchAlert } from '@utils/helpers';
import { useMsal } from '@azure/msal-react';
import { useParams } from 'react-router';
import StackedBar from '@components/StackedBar';
import LineChart from '@components/LineChart';
import parse from 'html-react-parser';
import moment from 'moment';

function getWindowWidth() {
  const { innerWidth: width, innerHeight: height } = window;
  return width;
}

const isEllipsis = (getWindowWidth()<= 1280) ? true : false;

type IdParams = {
  id: string;
}

const columns = [
  {
    title: 'Alert Name',
    dataIndex: 'name',
    key: 'name',
    width: isEllipsis?160:320,
    render: (text, row) => <a href={`/alert/${row.id}`} target="_blank" rel="noopener noreferrer" >{text}</a>,
  },
  {
    title: 'Execution Time',
    dataIndex: 'timestamp',
    key: 'timestamp',
    width: 160,
  },
  {
    title: 'Action Type',
    dataIndex: 'actiontype',
    key: 'actiontype',
    width: 110,
    render: actiontype => {
      switch (actiontype) {
        case "Reboot": {
          return (<Tag color={"cyan"} >{actiontype}</Tag>)
        }
        case "Reimage": {
          return (<Tag color={"blue"} >{actiontype}</Tag>)
        }
        case "Rebuild": {
          return (<Tag color={"purple"} >{actiontype}</Tag>)
        }
        case "Redeploy": {
          return (<Tag color={"geekblue"} >{actiontype}</Tag>)
        }
        case "Delete": {
          return (<Tag color={"red"} >{actiontype}</Tag>)
        }
        case "None": {
          return (<Tag color={"gray"} >{actiontype}</Tag>)
        }
      }
    }
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    width: isEllipsis?70:100,
    render: state => {
      switch (state) {
        case "NotStarted": {
          return (<Tag color={"gray"} >{state.toUpperCase()}</Tag>)
        }
        case "Succeeded": {
          return (<Tag color={"green"} >{state.toUpperCase()}</Tag>)
        }
        case "Failed": {
          return (<Tag color={"red"} >{state.toUpperCase()}</Tag>)
        }
      }
    }
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
    render: text => {      
      return  text.indexOf('<') !== -1 ? <div><Tooltip placement="rightTop" overlayStyle={{maxWidth: '700px'}} title={text}>{ text.substring(0, text.indexOf('<')) + "..." }</Tooltip></div> : text;
    },
  },
  {
    title: 'Kusto Logs',
    key: 'kusto',
    width:120,
    dataIndex: 'kusto',
    render: text => (
      <Space size="middle">
        <a href={`${text}`} target="_blank"  rel="noopener noreferrer" >Check Kusto</a>
      </Space>
    ),
  },
];

const MachineActionHistory: React.FC = () => {
  const [machineActionHistory, setMachineActionHistory] = useState<any>([]);
  const [alerts, setAlerts] = useState<any>([]);
  const [alertId, setAlertId] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [alertDetail, setAlertDetail] = useState<any>([]);  
  const [maxValue, setMaxValue] = useState<number>(5000);
  const params = new URLSearchParams(window.location.search);

  //Use single array to ensure all states set at one time, filters[0] = state, filters[1] = timespan, filters[2] = timestamp, filters[3] = count
  const [filters, setFilters] = useState<string[]>([ params.get("state") !== null ? String(params.get("state")) : "" , params.get("timespan") !== null ? String(params.get("timespan")) : "6h" , params.get("timestamp") !== null ? String(params.get("timestamp")) : "", params.get("count") !== null ? String(params.get("count")) : "200"]);

  let { id } = useParams<IdParams>();
  //let count = "200";

  const { accounts, instance } = useMsal();

  const getState = (message) => {
    if (message.includes('Completed machine action')) {
      return 'Succeeded';
    }
    else if (message.includes('Failed machine action')) {
      return 'Failed';
    }
    else {
      return 'NotStarted'
    }
  }

  const getScale = () => {    
    if (filters[1] === "30m" || filters[1] === "1h") {
      return 200;
    }
    else if (filters[1] === "6h") {
      return 1000;
    }
    else if (filters[1] === "1d") {
      return 2000;
    }
    else {
      return 5000
    }
  }

  const getActionType = (message) => {
    if (message.includes('Reboot')) {
      return 'Reboot';
    }
    else if (message.includes('Reimage')) {
      return 'Reimage';
    }
    else if (message.includes('Redeploy')) {
      return 'Redeploy';
    }
    else if (message.includes('Rebuild')) {
      return 'Rebuild';
    }
    else if (message.includes('Delete')) {
      return 'Delete';
    }
    else {
      return 'None'
    }
  }

  async function onAlertChange(value) {

    if (value === 'undefined' || !value || !/[^\s]/.test(value)) {
    }
    //else if (alertId !== value || state !== previousstate || timeRange !== previoustimespan ) 
    else {
      setLoading(true);

      let machineHistoryResult = await fetchMachineActionHistory(accounts, instance, value, filters[3], filters[0], filters[1], filters[2]);
      let tmpData = machineHistoryResult.map(item => ({
        name: item.AlertName,
        id: item.AlertID,
        timestamp: moment(item.TimeStamp).format("YYYY-MM-DD HH:mm:ss"),
        message: item.Message,
        state: getState(item.Message),
        actiontype: getActionType(item.Message),
        kusto: item.KustoUrl
      }));

      setMachineActionHistory(tmpData);
      setAlertId(value);

      let alertData = await fetchAlert(value, accounts, instance);
      setAlertDetail(alertData);

      let title = "Machine Action History: ";
      if (tmpData[0] !== undefined && tmpData[0].name !== undefined) {
        title = title + tmpData[0].name;
      }
      else {
        title = "Machine Action History";
      }

      var queryStr = `?state=${filters[0]}&timespan=${filters[1]}&timestamp=${filters[2]}&count=${filters[3]}`;
      window.history.replaceState(null, title, `/machineactionhistory/${value}${queryStr}`);
      document.title = title;

      setLoading(false);
    }
  }

  useEffect(() => {
    if (alertId !== undefined && alertId.length > 0) {
      onAlertChange(alertId);
    }
    else if (id) {
      FetchData(false);
      onAlertChange(id);
    }
    else {
      FetchData();
    }
  }, [alertId, filters]);

  const FetchData = async (loadData = true) => {

    setLoading(true);

    let machineActionAlerts = await fetchMachineActionAlerts(accounts, instance, null, null, null);
    let dropdownListItems = machineActionAlerts.map(item => ({ value: item.id, label: item.title }));
    setAlerts(dropdownListItems);

    if (loadData) {
      let machineHistoryResult = await fetchMachineActionHistory(accounts, instance, null, filters[3], filters[0], filters[1], filters[2]);
      let tmpData = machineHistoryResult.map(item => ({
        name: item.AlertName,
        id: item.AlertID,
        timestamp: moment(item.TimeStamp).format("YYYY-MM-DD HH:mm:ss"),
        message: item.Message,
        state: getState(item.Message),
        actiontype: getActionType(item.Message),
        kusto: item.KustoUrl
      }));

      let title = "Machine Action History";      
      var queryStr = `?state=${filters[0]}&timespan=${filters[1]}&timestamp=${filters[2]}&count=${filters[3]}`;
      window.history.replaceState(null, title, `/machineactionhistory${queryStr}`);
      document.title = title;

      setMachineActionHistory(tmpData);
    }
    setLoading(false);
  }

  const onChangeTimeRange = e => {
    setFilters([filters[0], e.target.value, "", "" ]);
  };

  const onChangeState = e => {
    setFilters([e.target.value, filters[1], "", "" ]);
  };

  const setFilterSettings = (status: string, num: string, t_stamp: string) => {   
    if (!t_stamp.includes(':')){
      t_stamp = t_stamp + " 00:00";
    }

    var dateObj = new Date();
    var utcMonth = dateObj.getUTCMonth() + 1;
    var month = Number(t_stamp.substring(0, 2));
    
    //Check if selected date cross year. If true, year = current year - 1;
    var year = dateObj.getUTCFullYear();
    if(month > utcMonth && month === 12){
      year = year - 1;
    }
        
    t_stamp = year.toString() + '-' + t_stamp;  
    var isoDateTime = moment.utc(t_stamp);   

    //limit max row count to 2000
    var count = Number(num);
    if (count > 2000){
      num = "2000";
    }

    if (status === "Completed"){
      status = "1";
    } 
    else if (status === "Failed") 
    {
      status = "2";
    }
    else {
      status = "0";
    }

    setFilters([status, filters[1], isoDateTime.toISOString(), num]);
  }

  const setAlertFilterSettings = (status: string, num: string, a_id: string) => {   

    //limit max row count to 2000
    var count = Number(num);
    if (count > 2000){
      num = "2000";
    }

    if (status === "Completed"){
      status = "1";
    } 
    else if (status === "Failed") 
    {
      status = "2";
    }
    else {
      status = "0";
    }

    var queryStr = `?state=${status}&timespan=${filters[1]}&timestamp=&count=${num}`;
    window.history.replaceState(null, document.title, `/machineactionhistory/${a_id}${queryStr}`);
    window.history.go();
    
    //setFilters([status, filters[1], "", num]);
    //setAlertId(a_id);
  }
  //FetchData();

  return (
    <div style={{ padding: "50px" }}>
      <div style={{ display: "flex", width: "100%" }} >
        <Card style={{ width: "100%", border: "red 0px solid", padding: "0px", minWidth: "480px" }}>
          <div style={{ width: "100%", float: "left", border: "red 0px solid", padding: "0px" }}>
            <Form.Item style={{ minWidth: "35%", float: "left", paddingLeft: "10px" }} label="Select Alert: " rules={[{ required: true, message: "Please select an alert includes machine action." }]}>
              <Select options={alerts} defaultValue={alertId} value={alertId} optionFilterProp="label" showSearch={true} onChange={onAlertChange}>
              </Select>
            </Form.Item>
            <Form.Item style={{ minWidth: "35%", float: "left", paddingLeft: "10px" }} label="State: " rules={[{ required: false, message: "Filter by state." }]}>
              <Radio.Group onChange={onChangeState} defaultValue={filters[0]} value={filters[0]}>
                <Radio.Button value="0">All</Radio.Button>
                <Radio.Button value="1">Succeeded</Radio.Button>
                <Radio.Button value="2">Failed</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>
          <div style={{border:"0px red solid", float:"left", width:'100%'}}>
            <div style={{float:'left', width:'100%', border: "red 0px solid", padding:"0px"}}>
              <Form.Item style={{ float:'left'}}>               
                <Card title={alertDetail.title? alertDetail.title : "Machine Action Execution Statistics"} bordered={true} style={{width:'39vw'}}>                              
                  <Radio.Group onChange={onChangeTimeRange} defaultValue="6h" value={filters[1]}>
                      <Radio value="30d">1 Month</Radio>
                      <Radio value="14d">2 Weeks</Radio>
                      <Radio value="7d">1 Week</Radio>
                      <Radio value="3d">3 Days</Radio>
                      <Radio value="1d">1 Day</Radio>                      
                      <Radio value="6h">6 Hours</Radio>
                      <Radio value="1h">1 Hour</Radio>
                      <Radio value="30m">30 Mins</Radio>
                  </Radio.Group>
                  {(alertDetail.id !== undefined && alertDetail.id.length > 0) ? <LineChart onClickChartElement={setFilterSettings} alertId={alertDetail.id? alertDetail.id : ""} timeRange={filters[1]} alertName={alertDetail.name} type="MachineAction"/>
                  : <StackedBar onClickChartElement={setAlertFilterSettings} timeRange={filters[1]} type="Machine Actions" maxvalue={0} /> }
                </Card>
              </Form.Item>
              <Form.Item style={{float:'left'}}>
                <Card bordered={false}>
                  <div style={{width:'31vw', overflow:'hidden'}}>{parse(alertDetail.description !== undefined?alertDetail.description:"")}</div>
                  <label>{alertDetail.description? "Created by " : ""}{alertDetail.createdBy}{alertDetail.description? ". " : ""}</label>
                  <br/><a href={`/service/${alertDetail.serviceId}`} target="_blank" rel="noopener noreferrer">{alertDetail.description? "Check Service Detail." : ""}</a>
                </Card>
              </Form.Item>
            </div>
          </div>
          <Table
            style={{ width: "100%" }}
            columns={columns}
            dataSource={machineActionHistory}
            loading={loading}
          />
        </Card>
      </div>
    </div>

  )
}

export default MachineActionHistory;

