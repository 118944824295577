import { Button, Card, Input, Table, Tabs, Tooltip } from 'antd';
import React from 'react'
import { useState, useCallback, useEffect } from 'react'
import 'antd/dist/antd.css';
import { fetchServices } from '@utils/helpers';
import { useMsal } from '@azure/msal-react';
import { useHistory } from 'react-router';
const { TabPane } = Tabs;
const { Search } = Input;

const servicesColumns = [
    {
        title: "Service",
        dataIndex: "serviceName",
        key: "serviceName",
        render: (title: string, row: IServiceManagement) => <a href={`/service/${row.id}`}>{title}</a>,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (user: string) => user,
    },
    {
        title: 'Owners',
        key: 'owners',
        dataIndex: "owners",
        onCell: () =>{
            return{
                style:{
                    maxWidth: 150
                }
            }
        },
        render: (text)=><Tooltip placement="topLeft" title={text}>{text}</Tooltip>
    },
    {
        title: 'AlertApprovers',
        key: 'alertapprovers',
        dataIndex: "alertapprovers",
        onCell: () =>{
            return{
                style:{
                    maxWidth: 150
                }
            }
        },
        render: (text)=><Tooltip placement="topLeft" title={text}>{text}</Tooltip>
    },
    {
        title: 'KustoClusters',
        key: 'kustoclusters',
        dataIndex: "kustoclusters"
    },
    /*{
        title: 'Alerts',
        key: 'alerts',
        dataIndex: "alerts"
    },*/
    {
        title: 'IcmTeams',
        key: 'icmteams',
        dataIndex: "icmteams"
    },
    {
        title: 'Details',
        key: 'details',
        dataIndex: "details",
        render: (title: string, row: IServiceManagement) => <a href={`/service/${row.id}`}>{title}</a>,
    }
]

const Management: React.FC = () => {
    const { accounts, instance } = useMsal();
    const [loading, setLoading] = useState(false);
    let [status, setStatus] = useState("Onboarded");
    let [keyword, setKeyword] = useState("");
    let [owner, setOwner] = useState(accounts[0].username);
    let [tableData, setTableData] = useState<IServiceManagement[]>([]);
    let [tabValue, setTab] = useState<string>("my");
    let [page, setPage] = useState(1);
    let email = accounts[0].username;

    let history = useHistory();
    const handleOnboardService = () => {
      history.push('/serviceonboard');
    };

    // const onSearch = async (val) => {
    //   setKeyword(keyword=val);
    //     setPage(1);
    //     fetchData()
    // }

    const onSearch = useCallback(
      async(val) => {
        //setKeyword(keyword=val);
        //onKeywordChange(val);
        setKeyword(keyword=val);
        setPage(1);
        fetchData();
      },
      [keyword, owner, status, tabValue],
    )

    const tabChange = useCallback(
      (currentTab) => {
        setTab(tabValue=currentTab);
        setStatus(status=currentTab==="my"?"Onboarded":"NotOnboarded");
        setOwner(owner=currentTab==="my"?email:"");
        fetchData();
      },
      [tabValue, keyword, status, owner],
    )

    const fetchData = useCallback(
      async () => {
        setLoading(true);
        tableData = await fetchServices(accounts, instance, keyword, status, owner);
        setTableData(tableData);
        setLoading(false);
      },
      [keyword, onSearch],
    )
  
      useEffect(
        ()=>{
          fetchData();
        }, []
      ) // eslint-disable-line react-hooks/exhaustive-deps

    if(!document.title.startsWith("Management - Azure Alerting"))
    {
        document.title = "Management - Azure Alerting";
    } 

    return (
        <div style={{ padding: "50px" }}>
            <div style={{ display: "flex", width: "100%", border: "red, 1px solid" }} >
                <Card style={{ width: "100%" }}>
                    <div style={{ width: "50%", float: "left", border: "red, 1px solid" }}>
                        <Search placeholder="Search Microsoft Services" enterButton style={{ width: "360px" }} onSearch={onSearch} onChange={(e)=>{setKeyword(e.target.value)}} allowClear/>
                    </div>
                    <div style={{ width: "50%", float: "left", textAlign: "right", verticalAlign: "middle", border: "red, 1px solid" }}>
                        <Button type="primary" style={{ border: 'green 1px solid' }} onClick={handleOnboardService} >
                            + Onboard Service
                        </Button>
                    </div>
                </Card>
            </div>
            <Card>
                <div>
                    <Tabs tabBarGutter={80} size="large" activeKey={tabValue} onChange={tabChange}>
                        <TabPane tab="My Onboarded Services" key="my"></TabPane>
                        <TabPane tab="All Microsoft Services" key="all"></TabPane>
                    </Tabs>
                </div>
                <Table
                    columns={servicesColumns}
                    dataSource={tableData}
                    rowKey={record => record.id}
                    loading={loading}
                />
            </Card>
        </div>
    )
}
export default Management;