import React from 'react';
import {
    Form,
    Input,
    Tooltip
} from 'antd';
import { Link } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import KustoQueryDisplay from '@components/KustoQueryDisplay';
import { IPublicClientApplication } from '@azure/msal-browser';
type KustoQueryWrapperProps = {
    accounts: any,
    instance: IPublicClientApplication,
    disabled: boolean,
    query: string,
    setQuery: any,
    clusters: string[],
    formName: string[],
    serviceId: string
}
const KustoQueryCommon: React.FC<KustoQueryWrapperProps> = ({ serviceId, query, accounts, instance, disabled, setQuery, clusters, formName }) => {
    //const [kustoQuery, setKustoQuery] = useState<string>(content === undefined ? "" : content);

    return (
        <Form.Item noStyle>
            <Form.Item label="Title" name={formName} required>
                <Input disabled={disabled} />
            </Form.Item>
            <Form.Item label={
              <div>Kusto <Tooltip title={
                <div>
                  <p><b>Efficient Query: run locally and make sure it finishes within a few seconds.</b></p>
                  <p>Get approval before assigning Sev2 to Servicing loop.</p>
                  <p>Use |limit, |summarize etc. Use the right Cluster – User queries with union across Kusto clusters Vs generic query. If you need summarize across all public azure clusters, use union in  your query and run on single cluster.</p>
                  <p><Link to="/faq#kusto" style={{color:"cyan"}}>For more detail, go to FAQ.</Link></p>
                </div>
                }><QuestionCircleOutlined/></Tooltip></div>} name={["primaryKustoQuery", 'kustoQuery']} required>
                <KustoQueryDisplay serviceId={serviceId} readOnly={disabled} content={query} accounts={accounts} instance={instance} onChange={setQuery} clusters={clusters} validate={true} />
            </Form.Item>
        </Form.Item>
    )
}

export default KustoQueryCommon;