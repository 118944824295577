import React from 'react';
import {
  Tooltip,
  Typography
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons'
const { Paragraph } = Typography;


export const CustomizedTooltip = (title, tooltipBody)=>{
  return (
    <>
      {title}
      <Tooltip title={tooltipBody} >
        <QuestionCircleOutlined/>
      </Tooltip>
    </>
  )
}

export const acknowledgment = <Paragraph>
    <h4>Please read following tips and guidelines</h4>
    <Paragraph>
    <b>Provide link to dashboard (stamp dashboard is good enough for many but specialized dashboards appreciated)</b>
    </Paragraph>
    <b>Follow TSG guidelines</b>
    <Paragraph>
      <li>1. What kind of failure it is looking for.</li>
      <li>2. What the failures mean</li>
      <li>3. What impact this causes or will potentially cause</li>
      <li>4. Where to start looking (and if feasible, where to go next or who to escalate to)</li>
      <li>5. When is this considered mitigated (ideally provide a query)</li>
      <li>6. Common mitigations if applicable</li>
    </Paragraph>
    <Paragraph>
    <b>Add Signal validation - some signal-noise ratio expectation, still thinking on this and would appreciate opinions</b>
    <li>a. Option 1: test alert against at least 30 days of relevant data (the word "relevant" is there to cover scenarios where the data hasn't been plumbed through yet)</li>
    <li>b. Option 2: follow SDP</li>
    </Paragraph>
  </Paragraph>

  // de-duplicate mix of 'all' and invidual clusters for same cloud-db pattern. Only for V2 cluster
  export const filterKustoCluster = (clusters:string[])=> {
    let res:KustoCluster[]=[]
    let kustoClusterMap = new Map<string, KustoCluster[]>(); // key: {cloud}-{db}, value: list of clusters
    // group by cloud-db combination into a map.
    for(let cluster of clusters){
      let cloudDb = '' // {cloud}-{db} format
      let tmpCluster:KustoCluster;
      let clusterObj:KustoCluster = JSON.parse(cluster);
      if(clusterObj.cloud==="All"&&clusterObj.cluster==="All"&&clusterObj.database==="All"){
        return [clusterObj]
      }
      let tmpCloud = clusterObj.cloud;
      let tmpDb = clusterObj.database;
      cloudDb = tmpCloud+'-'+tmpDb;
      tmpCluster = clusterObj;
      let tmpClusters = kustoClusterMap.get(cloudDb)|| [];
      tmpClusters.push(tmpCluster);
      kustoClusterMap.set(cloudDb, tmpClusters)
    }
    // de-duplicate when cloud-all-database happens
    kustoClusterMap.forEach((value: KustoCluster[], key: string)=>{
      let containAll = false;
      for(let tmpKusto of value){
        if(tmpKusto.cluster==="All"){
          res.push(tmpKusto);
          containAll = true;
          break;
        }
      }
      if(!containAll){
        res.push(...value);
      }
    })
    return res;
  }

  export const convertAllTagToKustoCluster = (allTag:string)=>{
    let res:KustoCluster = {
      cloud: allTag.split('-')[0],
      serviceName:"",
      cluster: "All",
      database: allTag.split('-')[2],
      description: "",
      backupCluster: "",
      followerCluster: "",
      clusterSuffix: "",
      useSts: ""
    }
    return res;
  }

  export const convertV2ClusterToV1 = (v2clusters:KustoCluster[], clusterMap: Map<string, string>) => {
    console.log('printing v2clusters   ', v2clusters);

  }
