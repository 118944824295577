import React from 'react';
import 'antd/dist/antd.css';
import { Card, Col, Row, Tag, Radio } from 'antd';
import { fetchAlerts2, fetchMachineActionAlerts, fetchGenevaActionAlerts, fetchAdminCommandAlerts, fetchServiceTeams } from '@utils/helpers';
import { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import TimeChart from '@components/TimeChart';
import TimeChartAlerts from '@components/TimeChartAlerts';
import PieChart from '@components/PieChart';
import LineChart from '@components/LineChart';
import HorizontalBar from '@components/HorizontalBar';
import StackedBar from '@components/StackedBar';
import moment from 'moment';

const Dashboard: React.FC = () => {
  const cols : any[] = [];
  const { accounts, instance } = useMsal();
  const [services, setServices] = useState<any>([]);
  const [alerts, setAlerts] = useState<any>([]);
  const [runneralerts, setRunnerAlerts] = useState<any>([]);
  const [genevaalerts, setGenevaAlerts] = useState<any>([]);
  const [admincmdalerts, setAdminCmdAlerts] = useState<any>([]);
  const [machineActionScale, setMachineActionScale] = useState<number>(10000);
  const [genevaActionScale, setGenevaActionScale] = useState<number>(5000);
  
  useEffect(() => {
    fetchServices();
    fetchAlerts();
    fetchRunnerAlerts();
    fetchGenevaAlerts();
    fetchAdminCmdAlerts();
  }, [])

  async function fetchServices() {
    let tableData = await fetchServiceTeams(accounts, instance);
    var items: any[];
    items = [];

    tableData.forEach(element => {
      //items.push(<li>{element.serviceName}</li>);      
      items.push(<li><a href={`/service/${element.id}`} target="_blank" rel="noopener noreferrer">{element.serviceName}</a></li>);
      
    });
    
    setServices(items);    
  }
  async function fetchAlerts() {
    
    let params = {} as QueryParams;    
    params.sort = "LastUpdated";
    params.limit = 10;
    let alertsData = await fetchAlerts2(accounts, instance, params);
    
    var items: any[];
    items = [];

    alertsData.slice(0, 10).forEach(element => {
      //items.push(<li>{element.title}</li>);
      var tmpLabels:any[] = [];
      tmpLabels = getActionLabels(element.actions);
     
      items.push(<div>{tmpLabels} <a href={`/alert/${element.id}`} target="_blank" rel="noopener noreferrer">{element.title}</a></div>);
    });
    
    setAlerts(items);    
  }

  const getActionLabels = (actions) => {
    var tmpLabels:any[] = [];
    actions.forEach(action => {
      if ('emailTo' in action) {
        tmpLabels.push(<Tag key="email" color="cyan">Email</Tag>);
      }
      if ('routingID' in action) {
        tmpLabels.push(<Tag key="icm" color="blue">ICM</Tag>)
      }
      if ('orgUri' in action) {
        tmpLabels.push(<Tag key="workitem" color="geekblue">WorkItem</Tag>)
      }
      if ('actionType' in action) {
        tmpLabels.push(<Tag key="machineaction" color="purple">Machine</Tag>)
      }
      if ('genevaOperation' in action) {
        tmpLabels.push(<Tag key="genevaaction" color="orange">Geneva</Tag>)
      }
      if ('kustoCommand' in action) {
        tmpLabels.push(<Tag key="controlcommandaction" color="green">Control Command</Tag>)
      }
    });
    return tmpLabels;
  }

  async function fetchRunnerAlerts() {
    
    let params = {} as QueryParams;    
    params.sort = "LastUpdated";
    let machineActionAlerts = await fetchMachineActionAlerts(accounts, instance, null, null, 'LastUpdated');
    
    var items: any[];
    items = [];

    machineActionAlerts.slice(0, 10).forEach(element => {      
      var tmpLabels:any[] = [];
      tmpLabels = getActionLabels(element.actions);    
      items.push(<div>{tmpLabels} <a href={`/alert/${element.id}`} target="_blank" rel="noopener noreferrer">{element.title}</a></div>);
    });
    
    setRunnerAlerts(items);    
  }

  async function fetchGenevaAlerts() {
    
    let params = {} as QueryParams;    
    params.sort = "LastUpdated";
    let genevaActionAlerts = await fetchGenevaActionAlerts(accounts, instance, null, null, 'LastUpdated');
    
    var items: any[];
    items = [];

    genevaActionAlerts.slice(0, 10).forEach(element => {      
      var tmpLabels:any[] = [];
      tmpLabels = getActionLabels(element.actions);    
      items.push(<div>{tmpLabels} <a href={`/alert/${element.id}`} target="_blank" rel="noopener noreferrer">{element.title}</a></div>);
    });
    
    setGenevaAlerts(items);    
  }

  async function fetchAdminCmdAlerts() {
    
    let params = {} as QueryParams;    
    params.sort = "LastUpdated";
    let genevaActionAlerts = await fetchAdminCommandAlerts(accounts, instance, null, null, 'LastUpdated');
    
    var items: any[];
    items = [];

    genevaActionAlerts.slice(0, 10).forEach(element => {      
      var tmpLabels:any[] = [];
      tmpLabels = getActionLabels(element.actions);    
      items.push(<div>{tmpLabels} <a href={`/alert/${element.id}`} target="_blank" rel="noopener noreferrer">{element.title}</a></div>);
    });
    
    setAdminCmdAlerts(items);    
  }

  // const renderOpenNewTab = (uri) => {
  //   window.open(uri);
  // }

  const jumpToAlertHistory = (status: string, num: string, t_stamp: string) => {   
    
    if (t_stamp !== undefined && t_stamp.length > 0) {
      if (!t_stamp.includes(':')) {
        t_stamp = t_stamp + " 00:00";
      }

      var dateObj = new Date();
      var utcMonth = dateObj.getUTCMonth() + 1;
      var month = Number(t_stamp.substring(0, 2));

      //Check if selected date cross year. If true, year = current year - 1;
      var year = dateObj.getUTCFullYear();
      if (month > utcMonth && month === 12) {
        year = year - 1;
      }

      t_stamp = year.toString() + '-' + t_stamp;
      var isoDateTime = moment.utc(t_stamp);

      t_stamp = isoDateTime.toISOString();
    }
    else {
      t_stamp = "";
    }

    //limit max row count to 2000
    var count = Number(num);
    if (count > 2000){
      num = "2000";
    }

    var timespan = "";
    if (t_stamp === "" && num === ""){
      timespan = "30d";
    }
    else if (t_stamp === "") {
      timespan = "1d"
    }
    else {
      timespan = "7d"
    }

    var queryStr = `?state=${status}&timespan=${timespan}&timestamp=${t_stamp}&count=${num}`;
    window.history.replaceState(null, "Alert Execution History", `/history${queryStr}`);
    window.history.go();
  }

  const jumpToGenevaHistory = (status: string, num: string, a_id: string) => {   

    //limit max row count to 2000
    var count = Number(num);
    if (count > 2000){
      num = "2000";
    }

    if (status === "Completed"){
      status = "1";
    } 
    else if (status === "Failed") 
    {
      status = "2";
    }
    else {
      status = "0";
    }

    var queryStr = `?state=${status}&timespan=7d&timestamp=&count=${num}`;
    window.history.replaceState(null, "Geneva Actions History", `/genevaactionhistory/${a_id}${queryStr}`);
    window.history.go();
  }

  const jumpToMachineHistory = (status: string, num: string, a_id: string) => {   

    //limit max row count to 2000
    var count = Number(num);
    if (count > 2000){
      num = "2000";
    }

    if (status === "Completed"){
      status = "1";
    } 
    else if (status === "Failed") 
    {
      status = "2";
    }
    else {
      status = "0";
    }

    var queryStr = `?state=${status}&timespan=7d&timestamp=&count=${num}`;
    window.history.replaceState(null, "Machine Action History", `/machineactionhistory/${a_id}${queryStr}`);
    window.history.go();
    
  }
 
  const onSetMachineActionScale = e => {
    setMachineActionScale(parseInt(e.target.value));
  };

  const onSetGenevaActionScale = e => {
    setGenevaActionScale(parseInt(e.target.value));
  };

  cols.push(
    <Col span={8} style={{minWidth: "480px"}}>
          <Card title="Services Onboard" bordered={false}>
            <p>
              <h4 className='title'>- Services onboarded with Azure Alerting V2 -</h4>
            </p>
            <TimeChart isServices={true}/>
          </Card>
        </Col>
  )

  cols.push(
    <Col span={8} style={{minWidth: "480px"}}>
          <Card title="Number of Alerts" bordered={false}>
            <p>
              <h4 className='title'>- Alerts created on Azure Alerting V2 -</h4>
            </p>
            <TimeChartAlerts isAlerts={true}/>
          </Card>
        </Col>
  )

  cols.push(
    <Col span={8} style={{minWidth: "480px"}}>
          <Card title="Alert Execution(24 hours)" bordered={false}>
            <p>
              <h4 className='title'>- Alert running statistics in the past 24 hours -</h4>
            </p>
            <HorizontalBar onClickChartElement={jumpToAlertHistory} alertId="" days="1"/>
          </Card>
        </Col>
  )
  cols.push(
    <Col span={8} style={{minWidth: "480px"}}>
          <Card title="Alert Execution Last Week" bordered={false}>
            <p>
              <h4 className='title'>- Alert running statistics in the past 7 days -</h4>
            </p>
            <LineChart alertId="" timeRange="7d" alertName="" type="" onClickChartElement={jumpToAlertHistory}/>
          </Card>
        </Col>
  )
  cols.push(
    <Col span={8} style={{minWidth: "480px"}}>
          <Card title="Alert Execution Overview" bordered={false}>
            <p>
              <h4 className='title'>- Overall Alert running results in the past 30 days -</h4>
            </p>
            <PieChart onClickChartElement={jumpToAlertHistory} alertId="" days="30" />
          </Card>
        </Col>
  )

  cols.push(
    <Col span={8} style={{minWidth: "480px"}}>
      <Card title="Machine Actions" bordered={false}>
            <p>
              <h4 className='title'>- Statistics of recent machine actions in the past 7 days -</h4>
            </p>
            <Radio.Group onChange={onSetMachineActionScale} defaultValue={5000} value={machineActionScale}>
                      <Radio value={1000}>1000</Radio>
                      <Radio value={3000}>3000</Radio>
                      <Radio value={5000}>5000</Radio>
                      <Radio value={10000}>10000</Radio>
                      <Radio value={30000}>30000</Radio>                      
                      <Radio value={50000}>50000</Radio>
            </Radio.Group>
            <StackedBar onClickChartElement={jumpToMachineHistory} timeRange="7d" type="Machine Actions" maxvalue={machineActionScale} />
      </Card>
    </Col>
  )

  cols.push(
    <Col span={8} style={{minWidth: "480px"}}>
      <Card title="Geneva Actions" bordered={false}>
            <p>
              <h4 className='title'>- Statistics of recent geneva actions in the past 7 days -</h4>
            </p>
            <Radio.Group onChange={onSetGenevaActionScale} defaultValue={1000} value={genevaActionScale}>
                      <Radio value={1000}>1000</Radio>
                      <Radio value={2000}>2000</Radio>
                      <Radio value={3000}>3000</Radio>
                      <Radio value={5000}>5000</Radio>
                      <Radio value={10000}>10000</Radio>                      
                      <Radio value={20000}>20000</Radio>
            </Radio.Group>
            <StackedBar onClickChartElement={jumpToGenevaHistory} timeRange="7d" type="Geneva Actions" maxvalue={genevaActionScale} />
      </Card>
    </Col>
  )

  cols.push(
    <Col span={8} style={{minWidth: "480px"}}>
      <Card title="Services" bordered={false}>        
        <ul>
          {services}    
        </ul>        
      </Card>
    </Col>
  )

  cols.push(
    <Col span={8} style={{minWidth: "480px"}}>
      <Card title="Recent Alert Rules" bordered={false}>
        <ul>
          {alerts}    
        </ul> 
      </Card>
    </Col>
  )

  cols.push(
    <Col span={8} style={{minWidth: "480px"}}>
      <Card title="Recent Machine Action Runners" bordered={false}>
        <ul>
          {runneralerts}    
        </ul> 
      </Card>
    </Col>
  )

  cols.push(
    <Col span={8} style={{minWidth: "480px"}}>
      <Card title="Recent Geneva Action Runners" bordered={false}>
        <ul>
          {genevaalerts}    
        </ul> 
      </Card>
    </Col>
  )

  cols.push(
    <Col span={8} style={{minWidth: "480px"}}>
      <Card title="Recent Kusto Admin Command Rules" bordered={false}>
        <ul>
          {admincmdalerts}    
        </ul> 
      </Card>
    </Col>
  )

  // cols.push(
  //   <Col span={8} style={{minWidth: "480px"}}>
  //     <Card title="Alert Triggers" bordered={false}>
  //       Card Content
  //     </Card>
  //   </Col>
  // )

  if(!document.title.startsWith("Dashboard - Azure Alerting"))
  {
    document.title = "Dashboard - Azure Alerting";
  } 

    return(
        <div className="site-card-wrapper" style={{margin:20, flexWrap:"wrap", display:"flex"}}>
        <Row gutter={[16,16]} style={{marginTop:"16px"}}>
          {cols}
        </Row>
      </div>     
    )
}

export default Dashboard;