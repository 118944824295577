import React, { Suspense } from 'react';
import { Switch, Route, withRouter, BrowserRouter } from 'react-router-dom';
import { Layout } from 'antd';
import Header from '@layout/header';
import SideMenu from '@layout/sidemenu';
import Home from '../screens/home/Home';
import NewAlert from '../screens/newalert/NewAlert';
import { QueryClient, QueryClientProvider } from "react-query";
import ExistingAlert from '../screens/existingalert/ExistingAlert';
import { PublicClientApplication, InteractionType, Configuration } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { AAD_APP_AUTHORITY, AAD_APP_CLIENT_ID } from '@constants/general';
import Management from '../screens/management/Management';
import Dashboard from "../screens/dashboard/dashboard";
import ServiceOnboard from "../shared/components/ServiceManagement/ServiceOnboard"
import HistoryExecution from "../screens/history/history"
import MachineActionHistory from "../screens/machineaction/machineactionhistory"
import GenevaActionHistory from "../screens/genevaaction/genevaactionhistory"
import End2EndTestExecution from "../screens/end2endtesthistory/end2endtesthistory"
import Faq from "../screens/faq/faq"
import ProtectionList from '../screens/admin/ProtectionList';
import ChangeLog from '../screens/admin/changelog';

type Props = {};
const { Footer } = Layout;
const queryClient = new QueryClient();

const msalConfig : Configuration = {
  auth: {
      clientId: AAD_APP_CLIENT_ID,
      authority: AAD_APP_AUTHORITY,
      redirectUri: window.location.origin
  }
};
const pca = new PublicClientApplication(msalConfig);

const Routes: React.FC<Props> = () =>{

  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter >
              <Layout style={{ minHeight:"100vh"}}>
                <SideMenu/>
              <Layout>
              <Header />
                <Switch>
                  <Suspense fallback={<div />}>
                    <Route exact={true} path="/" component={withRouter(Home)}/>
                    <Route exact={true} path="/alert/:id" component={withRouter(ExistingAlert)}/>
                    <Route exact={true} path="/newalert" component={withRouter(NewAlert)}/>
                    <Route exact={true} path="/service" component={withRouter(Management)}/>
                    <Route exact={true} path="/service/:id" component={withRouter(ServiceOnboard)}/>
                    <Route exact={true} path="/serviceonboard" component={withRouter(ServiceOnboard)}/>
                    <Route exact={true} path="/dashboard" component={withRouter(Dashboard)}/>
                    <Route exact={true} path="/history" component={withRouter(HistoryExecution)}/>
                    <Route exact={true} path="/history/:id" component={withRouter(HistoryExecution)}/>
                    <Route exact={true} path="/end2endtesthistory" component={withRouter(End2EndTestExecution)}/>
                    <Route exact={true} path="/machineactionhistory" component={withRouter(MachineActionHistory)}/>
                    <Route exact={true} path="/machineactionhistory/:id" component={withRouter(MachineActionHistory)}/>
                    <Route exact={true} path="/genevaactionhistory" component={withRouter(GenevaActionHistory)}/>
                    <Route exact={true} path="/genevaactionhistory/:id" component={withRouter(GenevaActionHistory)}/>
                    <Route exact={true} path="/faq" component={withRouter(Faq)}/>
                    <Route exact={true} path="/protectedmachines" component={withRouter(ProtectionList)}/>
                    <Route exact={true} path="/changelog" component={withRouter(ChangeLog)}/>
                    {/* <Route exact={true} path="/genevaactions" component={withRouter(GenevaActions)}/> */}
                    {/* {publicRoutes} */}
                    {/* <Route component={NotFound} /> */}
                  </Suspense>
                </Switch>
                <Footer style={{ textAlign: 'center'}}>© Microsoft {new Date().getFullYear()}</Footer>
              </Layout>
              </Layout>
            </BrowserRouter>
          </QueryClientProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
} 

export default Routes;
