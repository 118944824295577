import React from 'react';
import {
  Checkbox,
  Col,
  Row,
  Form,
  Input,
  Tooltip
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

type EmailFormItemProps = {
    active : boolean;
    readOnly: boolean;
};

const EmailFormItem: React.FC<EmailFormItemProps> = ({active, readOnly}) => {
    const renderEmail = () => {
        if (active)
        {
            return(
              // <Form.Item name="aliases" label="Email Aliases (;) separated" rules={[{ required: true, message: 'Aliases are required for sending an email' }]}>
              //   <Input disabled={readOnly}/>
              //   <Checkbox>Report Mode</Checkbox>
              // </Form.Item>
              <Form.Item labelAlign='right' label={<div><Tooltip title="Separated by (;)">Send Email <QuestionCircleOutlined/></Tooltip></div>} required>
            <Row gutter={24} >
                <Col span={16}>
                    <Form.Item name="aliases" required rules={[{ required: true, message: 'Aliases are required for sending an email' }]}>
                        <Input disabled={readOnly} placeholder='aliases (;) separated'/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={<div>Is Reporting Mode: <Tooltip title="Report will not show Kusto query when checked" ><QuestionCircleOutlined/></Tooltip></div>} name="isReportingMode" valuePropName='checked'>
                        <Checkbox disabled={readOnly}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form.Item>
            )
        } else{
            return (<></>);
        }
    }
    return renderEmail();
}

export default EmailFormItem