import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  Modal,
  Table
} from 'antd';
import { addProtectedMachine, deleteProtectedMachine, getMachineProtectionList } from '@utils/helpers';
import { useMsal } from '@azure/msal-react';

const ProtectionList: React.FC = () => {
  const {accounts, instance} = useMsal();
  const [tableData, setTableData] = useState<ProtectedMachine[]>([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [form] = Form.useForm();

  const columns = [
    {
      title: 'Stamp',
      key: 'stamp',
      dataIndex: 'stamp'
    },
    {
      title: 'Instance',
      key: 'instance',
      dataIndex: 'instance'
    },
    {
      title: 'createdBy',
      key: 'createdyBy',
      dataIndex: 'createdBy'
    },
    {
      title: 'LastUpdated',
      key: 'LastUpdated',
      dataIndex: 'lastUpdated'
    },
    {
      title: 'Action',
      key: 'action',
      render: (record)=>{
        return (
          <Button type="link" onClick={()=>onDelete(record)}>Delete</Button>
        )
      }
    }
  ]

  const onDelete = async(record) => {
    console.log('deleted');
    setLoading(true);
    await deleteProtectedMachine(accounts, instance, record);
    setLoading(false);
    window.location.reload();
  }

  const fetchProtectionList = async() => {
    setLoading(true);
    let data = await getMachineProtectionList(accounts, instance);
    setTableData(data);
    setLoading(false);
  }

  const onClickAddMachine = () => {
    setOpenModal(true);
  }
  const onModalOk = async () => {
    setLoading(true);
    await addProtectedMachine(accounts, instance, form.getFieldValue('stamp'), form.getFieldValue('instance'));
    setOpenModal(false);
    setLoading(false);
    window.location.reload();
  }
  const onModalCancel = () => {
    setOpenModal(false);
  }
  const MachineProtectionForm:React.FC<any> = () => {
    return (
      <Form
        form={form}
        labelCol={{span:3}}
        wrapperCol={{span:16}}
      >
        <Form.Item label='Stamp' name='stamp'>
          <Input placeholder='stamp name'/>
        </Form.Item>
        <Form.Item label='Instance' name='instance'>
          <Input placeholder='instance name'/>
        </Form.Item>
      </Form>
    )
  }
  useEffect(
    ()=>{
      fetchProtectionList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
  )
  return (
    <div style={{margin: '25px', marginTop:'0px'}}    >
      <Button type='primary' style={{marginTop:'25px'}} onClick={onClickAddMachine}>+Add Machine</Button>
      <Table
        dataSource={tableData}
        columns={columns}
        rowKey={record=>record?.id || ''}
        loading={loading}
      ></Table>
      <Modal visible={openModal} onOk={onModalOk} onCancel={onModalCancel} destroyOnClose>
      <h2>Add machine</h2>
        <MachineProtectionForm/>
        {/* <Input style={{width:'80%'}} placeholder='input your stamp name'></Input><br/>
        <Input style={{width:'80%'}} placeholder='input your instance name'></Input> */}
      </Modal>
    </div>
  )
}

export default ProtectionList;

