import React from 'react';
import {
  Form,
  Input,
  Select,
  Alert
} from 'antd';
import { useState } from 'react';

type ICMFormItemProps = {
    active : boolean;
    icmOpts: any[];
    readonly: boolean;
    loading: boolean;
};

const { Option } = Select;

const ICMFormItem: React.FC<ICMFormItemProps> = ({active, icmOpts, readonly, loading}) => {
    const [selectedSeverity, setSelectedSeverity] = useState<number | null>(null);

    const handleSeverityChange = (value: number) => {
        setSelectedSeverity(value);
    };

    const renderICM = () => {
        if (active)
        {
            return(
            <Form.Item label="Fire ICM" rules={[{ required: true, message: 'Time and day are required' }]}>
                <Input.Group compact>
                <Form.Item
                    name={['icm', 'severity']}
                    noStyle
                    rules={[{ required: true, message: 'Severity is required' }]}
                >
                    <Select style={{width:"15%"}}  placeholder="Select Severity" disabled={readonly} onChange={handleSeverityChange}>
                    <Option value={1}>1</Option>
                    <Option value={2}>2</Option>
                    <Option value={25}>25</Option>
                    <Option value={3}>3</Option>
                    <Option value={4}>4</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name={['icm', 'team']}
                    noStyle
                    rules={[{ required: true, message: 'Routing group is required' }]}
                >
                    <Select style={{width:"60%"}} disabled={readonly||loading} placeholder="Select ICM team" loading={loading} options={icmOpts} labelInValue showSearch optionFilterProp='label'>
                    </Select>
                </Form.Item>
                </Input.Group>
                {selectedSeverity === 25 && 
                    <Alert style={{width: "75%", marginTop: "20px"}}  message="Severity 25" type="info" 
                    description={<div>
                        Notifications are sent during a team's business hours.<br/>
                        This is a preview feature of IcM. Please make sure your team has enabled sev25 Incidents before you select this option. <a href='https://icmdocs.azurewebsites.net/workflows/Incidents/incident-severity.html?q=severity'>More information.</a>
                    </div>}
                    showIcon />

                }
            </Form.Item>)     
        } else{
            return (<></>);
        }
    }
    return renderICM();
}

export default ICMFormItem