import React from 'react';
import {
  Card,
  Typography
} from 'antd';

const ChangeLog : React.FC = ()=>{
  return(
    <Card style={{width:'100%'}}>
      <Typography>
        <h2>Change Log</h2>
        <h3>31 Dec 2022</h3>
        <ul>
          <li>Support ICM severity override in kusto query.</li>
        </ul>
        <h3>30 Nov 2022</h3>
        <ul>
          <li>Support Teams notification action. User can create alert action as Microsoft Teams notification.</li>
          <li>Optimize machine mitigation by concurrent stamp instance actions.</li>
          <li>Blob trigger alert metadata to sovereign clouds sync.</li>
          <li>Alerts on USSec</li>
        </ul>
        <h3>30 October 2022</h3>
        <ul>
          <li>Support AME security pipeline for alerting critical actions. Alert will be approved via release pipeline.</li>
        </ul>
        <h3>30 August 2022</h3>
        <ul>
          <li>Support multiple kusto database; Support All cluster tag to automatically adopt latest onboarded kusto cluster and databases</li>
          <li>Provide email report in alerting web</li>
          <li>Support Icm auto correlation</li>
        </ul>
        <h3>31 July 2022</h3>
        <ul>
          <li>Support Geneva action runner in Mooncake/Fairfax</li>
          <li>Antares action runner and Geneva action runner metadata regular release established twice a week.</li>
        </ul>
        <h3>28 June 2022</h3>
        <ul>
          <li>DSTS authentication of Geneva action runner has been supported.</li>
          <li>Alert list supports filtering by action type.</li>
          <li>Mooncake action runners have been migrated.</li>
          <li>AlertingV2 was refactored to access dependency resources secretlessly with MSI, including storage, cosmos, eventhub, function, key vault, etc.</li>
          <li>AlertingV2 reports supported stamp name and cloud name reflection in alert description.</li>
          <li>IcM incident fields overwrite: Support to overwrite all incident fields by customize column name in your primary kusto query.</li>
        </ul>
        <h3>31 May 2022 </h3>
        <ul>
          <li>Alert with pending update state will be executed with previous approved version.</li>
          <li>Abandoned PR will be processed through Service Hook and related alert will be recovered to the state prior to the modification.</li>
          <li>Alert runs are scoped to scheduled time in execution to overcome dependency and system delay.</li>
          <li>Owners of onboarded service can customize alert users and alert reviewers with existing AAD groups</li>
          <li>Support of using separate AAD apps and certificates to invoke Geneva actions of different services</li>
          <li>Max number of machines to mitigate for different roles per alert run is configurable. Detailed steps: <a target="_blank" rel="noopener noreferrer" href='https://microsoft.sharepoint.com/:o:/r/teams/Antares/_layouts/15/doc2.aspx?sourcedoc=%7Bf1d02dae-db6b-4c60-8a1d-d17cc2892498%7D&action=edit&wd=target(Monitoring.one%7C8AD3A494-8978-4AF9-BA58-6451EEC6A5BE%2FSet%20max%20number%20of%20machines%20to%20mitigate%20for%20different%20roles%7CA6D1E568-5A16-4096-97BE-A135577C927C%2F)&ovuser=72f988bf-86f1-41af-91ab-2d7cd011db47%2Cjingzhang%40microsoft.com&params=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMjA1MDEwMTAwOSJ9'>Set max number of machines to mitigate for different roles</a></li>
          <li>Support of using separate AAD apps and certificates for queries against Kusto clusters of different services</li>
          <li>Throttling on IcM: Default throttling value is 20 when creating incidents, the value is configurable for each service.</li>
        </ul>
        
      </Typography>
    </Card>
  
  )
  
}
export default ChangeLog;