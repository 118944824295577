import React from 'react';
import 'antd/dist/antd.css';
import { Table, Tag, Space, Card, Form, Select, Button, Radio, Tooltip } from 'antd';
import { useState, useEffect } from 'react'
import { fetchAlerts2, fetchEnd2EndTestAuth, fetchEnd2EndTestAlertHistory, fetchEnd2EndTestRecords, fetchEnd2EndTestEnquer, fetchActionHistoryById, fetchAlertHistoryRawLogById } from '@utils/helpers';
import { useMsal } from '@azure/msal-react';
import { useParams } from 'react-router';
import moment from 'moment';
import { message } from "antd";

type IdParams = {
  id : string;
}

function getWindowWidth() {
  const { innerWidth: width, innerHeight: height } = window;
  return width;
}

const isEllipsis = (getWindowWidth()<= 1280) ? true : false;


const columns = [
  {
    title: 'Alert Name',
    dataIndex: 'name',
    key: 'name',
    width: isEllipsis?160:320,
    render: (text, row) => <a href={`/alert/${row.id}`} target="_blank" rel="noopener noreferrer">{text}</a>,
  },
  {
    title: 'Execution Time',
    dataIndex: 'timestamp',
    key: 'timestamp',
    width:160,
  },
  {
    title: 'Execution Id',
    dataIndex: 'execution',
    key: 'execution',
    width:isEllipsis?170:200,
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    width:110,
    render: state => {
      switch (state) {
        case "NotStarted": {
          return (<Tag color={"gray"} >{state.toUpperCase()}</Tag>)
        }
        case "Enqueued": {
          return (<Tag color={"orange"} >{state.toUpperCase()}</Tag>)
        }
        case "Executing": {
          return (<Tag color={"yellow"} >{state.toUpperCase()}</Tag>)
        }
        case "Succeeded": {
          return (<Tag color={"green"} >{state.toUpperCase()}</Tag>)
        }
        case "Skipped": {
          return (<Tag color={"gray"} >{state.toUpperCase()}</Tag>)
        }
        case "Failed": {
          return (<Tag color={"red"} >{state.toUpperCase()}</Tag>)
        }
        case "Paused": {
          return (<Tag color={"orange"} >{state.toUpperCase()}</Tag>)
        }
        case "PartiallyCompleted": {
          return (<Tag color={"red"} >{state.toUpperCase()}</Tag>)
        }
        case "CompletedWithError": {
          return (<Tag color={"red"} >{state.toUpperCase()}</Tag>)
        }
      }
    }
  },
  {
    title: 'Actions',
    key: 'actions',
    width:80,
    dataIndex: 'actions',
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
    ellipsis: isEllipsis,
    render: text => {      
      return  text.indexOf('<') !== -1 ? <div><Tooltip placement="rightTop" overlayStyle={{maxWidth: '700px'}} title={text}>{ text.substring(0, text.indexOf('<')) + "..." }</Tooltip></div> : text;
    },
  },
  {
    title: 'Logs in Kusto',
    key: 'kusto',
    width:120,
    dataIndex: 'kusto',
    render: text => (
      <Space size="middle">
        <a href={`${text}`} target="_blank"  rel="noopener noreferrer" >Check Kusto</a>
      </Space>
    ),
  }
];
const actionColumns = [
  {
    title: 'Action Type',
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: 'Action Time',
    dataIndex: 'time',
    key: 'time',
  },
  {
    title: 'Action Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Action State',
    dataIndex: 'state',
    key: 'state',
    render: state => {
      switch (state) {
        case "NotStarted": {
          return (<Tag color={"gray"} >{state.toUpperCase()}</Tag>)
        }
        case "Running": {
          return (<Tag color={"yellow"} >{state.toUpperCase()}</Tag>)
        }
        case "Succeeded": {
          return (<Tag color={"green"} >{state.toUpperCase()}</Tag>)
        }
        case "Failed": {
          return (<Tag color={"red"} >{state.toUpperCase()}</Tag>)
        }
        case "Paused": {
          return (<Tag color={"orange"} >{state.toUpperCase()}</Tag>)
        }
        case "Skipped": {
          return (<Tag color={"gray"} >{state.toUpperCase()}</Tag>)
        }
        default: {
          return (<Tag color={"gray"} >{"NOTSTARTED"}</Tag>)
        }
      }
    }
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
    ellipsis: false,
    render: text => {      
      return  text.indexOf('<') !== -1 ? <div><Tooltip placement="rightTop" overlayStyle={{maxWidth: '700px'}} title={text}>{ text.substring(0, text.indexOf('<')) + "..." }</Tooltip></div> : text;
    },
  },
  {
    title: 'Log',
    key: 'log',
    dataIndex: 'log',
    render: (text, row) => {
      if( row.type === 'MachineAction') {
        //console.log(text);
        //console.log(row);
        return (        
        <Space size="middle">
                  <a href={`/history?${text}`} target="_blank" rel="noopener noreferrer" >Raw Log</a>
                  &nbsp;                
                  <a href={`/machineactionhistory/${text.split('alertId=')[1]}?state=${row.state==='Failed'?"2":""}&timespan=30m&timestamp=${moment.utc(row.time).add(-1, 'minutes').toISOString()}`} target="_blank" rel="noopener noreferrer" >Machine Action</a>
                </Space>
                )
      }
      if( row.type === 'GenevaAction') {
        return (<Space size="middle">
                  <a href={`/history?${text}`} target="_blank" rel="noopener noreferrer" >Raw Log</a>
                  &nbsp;                
                  <a href={`/genevaactionhistory/${text.split('alertId=')[1]}?state=${row.state==='Failed'?"2":""}&timespan=30m&timestamp=${moment.utc(row.time).add(-1, 'minutes').toISOString()}`} target="_blank" rel="noopener noreferrer" >Geneva Action</a>
                </Space>
                )
      }
      else {
        return (<Space size="middle">
                  <a href={`/history?${text}`} target="_blank" rel="noopener noreferrer" >Raw Log</a>
                </Space>)
      } 
    },
  },
  {
    title: 'Report',
    key: 'urlParameters',
    dataIndex: 'urlParameters',
    render: (text, row) => {
      if( row.type === 'EmailAction' || row.type === 'ICMAction') {
        return (        
        <Space size="middle">
                  <a href={`/history?${text}`} target="_blank" rel="noopener noreferrer" >Email Report</a>
                </Space>
                )
      }
      else {
        return (<Space size="middle">
                </Space>)
      } 
    },
  },
];

const End2EndTestExecution: React.FC = () => {
  const [timestamps, setTimestamps] = useState<any>([]);
  const { accounts, instance } = useMsal();
  const [alertHistory, setAlertHistory] = useState<any>([]);
  const [actionHistory, setActionHistory] = useState<Map<string, any>>();
  const [alertTitle, setAlertTitle] = useState("");
  const params = new URLSearchParams(window.location.search);
  let [timestampSeleted, setTimestampSeleted] = useState(params.get("enqueuedtimestamp")?.toString());
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<string[]>([ params.get("enqueuedtimestamp") !== null ? String(params.get("enqueuedtimestamp")) : "" , params.get("timespan") !== null ? String(params.get("timespan")) : "recent" ]);

  const end2endTestAuth = async () => {
    let data = await fetchEnd2EndTestAuth(accounts, instance);
    if (data.authorized === 401){
      window.location.href = "/dashboard";
      message.error("Permission denied for end-to-end test report.")
    }
  }

  const FetchData = async () => {    
    setLoading(true);
    
    let tempData = await fetchEnd2EndTestRecords(accounts, instance, filters[1]);
    let dropdownListItems = tempData.map(item => ({ value: item, label: item }));
    setTimestamps(dropdownListItems);
    setTimestampSeleted(tempData[0]);

    
    let response = await fetchEnd2EndTestAlertHistory(accounts, instance, tempData[0]);
    let historyResult = response.data;
    console.log(historyResult);
    let tmpData = historyResult.map(item => ({ 
        key:item.AlertEventID, 
        id: item.AlertId, 
        timestamp: moment(item.TimeStamp).format("YYYY-MM-DD HH:mm:ss"), 
        name: item.AlertName, 
        execution: item.AlertEventID, 
        state: item.AlertEventState, 
        actions: item.Actions,
        message: item.Message,
        kusto: item.KustoUrl }));

    var queryStr;
    if (tempData[0] === undefined) {
      let tempData = await fetchEnd2EndTestRecords(accounts, instance, "recent");
      queryStr = `?enqueuedtimestamp=${tempData[0]}`;
      setTimestampSeleted(tempData[0]);
    }
    else {
      queryStr = `?enqueuedtimestamp=${tempData[0]}`;
    }

    window.history.replaceState(null, "End2End Test History", `/end2endtesthistory${queryStr}`);
    document.title = "End2End Test History";
    
    setAlertHistory(tmpData);
    setLoading(false);
  }

  const renderExtraTable =  (row) => {
    //console.log(`expanded ${row.execution}`);
    if (row.execution === 'undefined' || !row.execution || !/[^\s]/.test(row.execution)) {
    } else {
      //let historyResult = (async() =>  await fetchActionHistoryById(accounts, instance, row.execution))();
      //let historyResult = await fetchActionHistoryById(accounts, instance, row.execution);
      //let tmpData = historyResult.map(item => ({ key: item.ActionEventID, id: item.ActionEventID, time: item.TimeStamp, type: item.ActionType, state: item.ActionEventState, eventId: row.execution }));
      //setActionHistory(tmpData);
    }
    return (
      <div style={{ marginLeft: "40px", border: "1px gray solid", fontSize: "10px" }}>
        <Table
          columns={actionColumns}
          dataSource={actionHistory?.get(row.execution)} />
      </div>
    )
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);  
    end2endTestAuth();
    // redirect to raw log
    if(params.get("alertId") && params.get("eventId"))
    {
      fetchAlertHistoryRawLogById(accounts, instance, params.get("alertId"), params.get("eventId")).then(
        res=>{
          if(res !== "")
          {
            window.location.href = res;
          }
          else
          {
            message.error("No report was found for alert event at " + params.get("eventId") + ".");
            setTimeout(function () {
              window.location.href = "/";
          }, 5000);           
          }
        }
      )
    }
    else
    {
      // fetch recent alert execution records if alertId is not specified
      if (!params.get("alertId")) {
        FetchData();
      }
    }
  }, [filters])


  async function onTimeStampChange(value) {

    if (value === 'undefined' || !value || !/[^\s]/.test(value)) {
    }
    //else if (alertId !== value || state !== previousstate || timeRange !== previoustimespan ) 
    else {
      setLoading(true);
      setTimestampSeleted(value);

      let response = await fetchEnd2EndTestAlertHistory(accounts, instance, value);
      let historyResult = response.data;
      let tmpData = historyResult.map(item => ({ 
        key:item.AlertEventID, 
        id: item.AlertId, 
        timestamp: moment(item.TimeStamp).format("YYYY-MM-DD HH:mm:ss"), 
        name: item.AlertName, 
        execution: item.AlertEventID, 
        state: item.AlertEventState, 
        actions: item.Actions,
        message: item.Message,
        kusto: item.KustoUrl }));
    
      var queryStr = `?enqueuedtimestamp=${value}`;
        window.history.replaceState(null, "End2End Test History", `/end2endtesthistory${queryStr}`);
        document.title = "End2End Test History";
      
      setAlertHistory(tmpData);
      setLoading(false);
    }
  }

  const onChangeTimeRange = e => {
    setFilters([filters[1], e.target.value, "", "" ]);
  };

  const sendEnd2EndTestRun = async () => {  
    let result = await fetchEnd2EndTestEnquer(accounts, instance);
    if (result.status === 200) {
      message.success(`End-to-End Test enqueued at ${moment().format('YYYY-MM-DD HH:mm:ss')}`);
    }
    else {
      message.error("End-to-End Test failed to enqueue." + result.data);
    }
  }

  return (
    <div style={{ padding: "10px" }}>
      <div style={{ display: "flex", width: "100%" }} >
        <Card title={alertTitle? alertTitle : "End-to-End Test Execution Records"} bordered={true} style={{ width: "100%", border: "red 0px solid", padding:"10px" }}>
        <Button type="primary" onClick={sendEnd2EndTestRun} style={{position : "absolute", right : "30px", top: "20px"}}>
          + Create End-to-End Test
        </Button>
        <div style={{ width: "100%", float: "left", border: "red 0px solid", padding:"0px" }}>
          <b style={{ width: "100%", border: "red 0px solid", padding: "0px px 0px 0px"}}>Enqueued TimeStamp Range </b>
            <Radio.Group onChange={onChangeTimeRange} defaultValue="recent" value={filters[1]}>
              <Radio value="recent">Recent</Radio>
              <Radio value="30d">1 Month</Radio>
              <Radio value="14d">2 Weeks</Radio>
              <Radio value="7d">1 Week</Radio>
              <Radio value="3d">3 Days</Radio>
              <Radio value="1d">1 Day</Radio>                      
              <Radio value="6h">6 Hours</Radio>
              <Radio value="1h">1 Hour</Radio>
              <Radio value="30m">30 Mins</Radio>
            </Radio.Group>
          </div>
          <div style={{ width: "100%", float: "left", border: "red 0px solid", padding:"20px 0px 0px 0px" }}>
            <Form.Item style={{ minWidth: "35%", float: "left"}} label="Select Enqueued TimeStamp: " rules={[{ required: true, message: "You must provide an enqueued timeStamp" }]}>
              <Select options={timestamps} defaultValue={timestampSeleted} key={timestampSeleted} optionFilterProp="label" showSearch={true} onChange={onTimeStampChange}>
              </Select>
            </Form.Item>   
          </div>
        <Table
          columns={columns}
          dataSource={alertHistory}
          loading={loading}
          expandable={{
            expandedRowRender: record => renderExtraTable(record)
          }}
          onExpand = {async (expanded, record) =>{
            if(expanded === true)
            {
              let historyResult = await fetchActionHistoryById(accounts, instance, record.execution);
              let tmpData = historyResult.map(item => ({ 
                key: item.ActionEventID, 
                id: item.ActionEventID, 
                time: moment(item.TimeStamp).format("YYYY-MM-DD HH:mm:ss"), 
                type: item.ActionType, 
                state: item.ActionEventState, 
                eventId: record.execution,
                alertId: item.AlertID,
                message: item.Message,
                log: "eventId=" + record.execution +"&alertId="+ item.AlertID,
                urlParameters: "eventId=" + record.execution + "-" + item.ActionEventID +".html&alertId="+ item.AlertID,
               }));
              var actionsCache = actionHistory;
              if(!actionsCache)
              {
                actionsCache = new Map( [[record.execution, tmpData]]);
              }
              else
              {
                //actionsCache?.set(record.execution, tmpData)
                actionsCache = new Map(actionsCache);
                actionsCache?.set(record.execution, tmpData)
                //actionsCache = new Map( [[record.execution, tmpData]]);
              }
              //actionsCache.push({[record.execution]: tmpData});
              //setActionHistory( new Map( [[record.execution, tmpData]])); 
              setActionHistory( actionsCache); 
            }
          }}
          />
        </Card>
      </div>
    </div>

  )
}
    
export default End2EndTestExecution;